import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListParams, ListResponse, useDebouncedValue } from '../services/util';

export type ServiceTypes<T> = (params: ListParams) => Promise<ListResponse<T>>;

export const usePagination = <T>(
    list: ServiceTypes<T>,
    dependencies?: any[]
) => {
    const history = useHistory();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [data, setData] = useState<T[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const search = useDebouncedValue(searchText, 200);
    const [loading, setLoading] = useState<boolean>(false);

    const searchQuery = (value: string) => {
        setSearchText(value);
    };

    const getData = useCallback(
        async (cancel?: boolean) => {
            try {
                setLoading(true);
                const resp = await list({
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                    search,
                });
                if (cancel) return;
                setTotalCount(resp.totalCount);
                setData(resp.data);
            } catch (err) {
                console.error('Fetching list error:', err);
            }
            setLoading(false);
        },
        [list, search, page, rowsPerPage]
    );

    useEffect(() => {
        let cancel = false;
        getData(cancel);
        return () => {
            setLoading(false);
            cancel = true;
        };
    }, [search, rowsPerPage, page, history.location, getData, dependencies]);

    useEffect(() => {
        if (search !== searchText) setLoading(true);
    }, [search, searchText]);

    return {
        rowsPerPage,
        page,
        data,
        totalCount,
        searchText,
        loading,
        search,
        setPage,
        setData,
        setRowsPerPage,
        searchQuery,
        getData,
        pagination: {
            page,
            setPage,
            setRowsPerPage,
            count: totalCount,
            rowsPerPage,
        },
    };
};
