import { OrderMailingClass, Resource } from './Base';
import { Contact } from './Contacts';
import { Service as ResourceService } from './Resources';

export const OrderObjects = {
    LETTER: 'letter',
    POSTCARD: 'postcard',
    CHEQUE: 'cheque',
} as const;

export enum OrderIMBStatus {
    // If we encounter any scan code (other than a stop-the-clock scancode)
    ENTERED_MAIL_STREAM = 'entered_mail_stream',
    // If we encounter a stop-the-clock scancode
    OUT_FOR_DELIVERY = 'out_for_delivery',
    // If we encounter any scancode with Mail Level "Cancellation"
    RETURNED_TO_SENDER = 'returned_to_sender',
}

export enum OrderStatus {
    READY = 'ready',
    PRINTING = 'printing',
    PROCESSED_FOR_DELIVERY = 'processed_for_delivery',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
}

export interface Order extends Resource {
    object: typeof OrderObjects[keyof typeof OrderObjects];

    from?: Contact;
    to: Contact;
    status: OrderStatus;
    imbStatus?: OrderIMBStatus;
    sendDate: Date;
    url?: string;
    express?: boolean;
    mailingClass?: OrderMailingClass;
}

export class Service<T extends Resource> extends ResourceService<T> {
    async cancel(id: string) {
        return await this.base.fetchAPI<Order>(`/${this.route}/${id}`, {
            method: 'DELETE',
        });
    }
    async progress(id: string) {
        return await this.base.fetchAPI<T>(
            `/${this.route}/${id}/progressions`,
            {
                method: 'POST',
            }
        );
    }
}
