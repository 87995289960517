export enum ContactRoutes {
    HOME = '/dashboard/contacts',
    CREATE = '/dashboard/contacts/create',
    VIEW = '/dashboard/contacts/:id',
}

export enum TemplateRoutes {
    HOME = '/dashboard/templates',
    CREATE = '/dashboard/templates/create',
    VIEW = '/dashboard/templates/:templateID',
    EDIT = '/dashboard/templates/:templateID/edit',
}

export enum BankAccountRoutes {
    HOME = '/dashboard/bank_accounts',
    CREATE = '/dashboard/bank_accounts/create',
    VIEW = '/dashboard/bank_accounts/:accountID',
}

export enum LetterRoutes {
    HOME = '/dashboard/letters',
    CREATE = '/dashboard/letters/create',
    VIEW = '/dashboard/letters/:orderID',
}

export enum PostcardRoutes {
    HOME = '/dashboard/postcards',
    CREATE = '/dashboard/postcards/create',
    VIEW = '/dashboard/postcards/:orderID',
}

export enum ChequeRoutes {
    HOME = '/dashboard/cheques',
    CREATE = '/dashboard/cheques/create',
    VIEW = '/dashboard/postcards/:orderID',
}

export enum ReturnEnvelopeRoutes {
    HOME = '/dashboard/return_envelopes',
    CREATE = '/dashboard/return_envelopes/create',
    VIEW = '/dashboard/return_envelopes/:id',
    VIEW_ORDER = '/dashboard/return_envelopes/:id/orders/:orderId',
}

export enum APILogRoutes {
    HOME = '/dashboard/api_logs',
    VIEW = '/dashboard/api_logs/:id',
}

export enum WebhookRoutes {
    HOME = '/dashboard/webhooks',
    CREATE = '/dashboard/webhooks/create',
    VIEW = '/dashboard/webhooks/:id',
}
