import React, {
    createContext,
    useState,
    useCallback,
    useContext,
    PropsWithChildren,
} from 'react';

import { useHistory } from 'react-router-dom';

import { useService as useOrgService } from '../services/Organization';

import NotApprovedForLiveDialog from '../components/NotApprovedForLiveDialog';

import { Context as ModeContext } from './Mode';

export const Context = createContext<{
    checkLiveApproved: (text: string) => void;
}>({
    checkLiveApproved: () => {},
});

export const Store = (props: PropsWithChildren<{}>) => {
    const history = useHistory();

    const [notApprovedOpen, setNotApprovedOpen] = useState(false);
    const [text, setText] = useState('');

    const { live } = useContext(ModeContext);

    const service = useOrgService();

    const checkLiveApproved = useCallback(
        async (text: string) => {
            if (!live) {
                return;
            }

            const org = await service.get();

            if (!org || org.liveApproved) {
                return;
            }

            // Send the user back to the overview because they might be in a bad state.
            history.push('/dashboard');

            setText(text);
            setNotApprovedOpen(true);
        },
        [service, live]
    );

    return (
        <Context.Provider value={{ checkLiveApproved }}>
            <NotApprovedForLiveDialog
                open={notApprovedOpen}
                onClose={() => {
                    setNotApprovedOpen(false);
                }}
                text={text}
            />
            {props.children}
        </Context.Provider>
    );
};
