export const GTM_ID = process.env.REACT_APP_GTM_ID as string;
export const POSTCARDS_ENABLED =
    process.env.REACT_APP_POSTCARDS_ENABLED === 'true';
export const SIGNUP_WEBHOOK_URL = process.env
    .REACT_APP_SIGNUP_WEBHOOK_URL as string;
export const LIVE_APPROVAL_WEBHOOK_URL = process.env
    .REACT_APP_LIVE_APPROVAL_WEBHOOK_URL as string;
export const APP_MODE = process.env.REACT_APP_MODE as string;
export const RECAPTCHA_SITE_KEY = process.env
    .REACT_APP_RECAPTCHA_SITE_KEY as string;
