import React, { useState, useContext, FormEvent } from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import { useService as useInvitesService } from '../services/Invites';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import GoBackButton from '../components/GoBackButton';
import Field from '../components/Field';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

const InviteUser = (props: {}) => {
    const history = useHistory();

    const service = useInvitesService();

    const { dispatch } = useContext(NotificationContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            (async () => {
                try {
                    setLoading(true);

                    await service.create({
                        name,
                        email,
                    });

                    dispatch({
                        type: MessageType.SUCCESS,
                        message: 'Invited user.',
                    });

                    history.push('/dashboard/users');
                } catch (err) {
                    console.error(err);
                }

                setLoading(false);
            })();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Invite a User
                        </Typography>
                    </Box>
                </Grid>
                <Grid item container direction="column" spacing={2}>
                    <Box my={2}>
                        <Grid item>
                            <Box my={2}>
                                <Alert variant="outlined" color="info">
                                    The user will receive an email to complete
                                    their signup.
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box my={4}>
                                <form onSubmit={onSubmit}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <Field
                                                variant="outlined"
                                                fullWidth
                                                value={name}
                                                setValue={setName}
                                                label="Name"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                variant="outlined"
                                                fullWidth
                                                value={email}
                                                setValue={setEmail}
                                                label="Email Address"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={loading}
                                                        size="large"
                                                        fullWidth
                                                    >
                                                        Invite
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <GoBackButton
                                                        color="primary"
                                                        variant="outlined"
                                                        disabled={loading}
                                                        size="large"
                                                        fullWidth
                                                    >
                                                        Cancel
                                                    </GoBackButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default InviteUser;
