import React, { useContext, createContext, useState, useCallback } from 'react';

interface State {
    description: string;
    setDescription: (s: string) => void;
    name: string;
    setName: (n: string) => void;
    companyName: string;
    setCompanyName: (cn: string) => void;
    jobTitle: string;
    setJobTitle: (jt: string) => void;
    addressLine1: string;
    setAddressLine1: (al: string) => void;
    addressLine2: string;
    setAddressLine2: (al: string) => void;
    city: string;
    setCity: (c: string) => void;
    provinceOrState: string;
    setProvinceOrState: (ps: string) => void;
    postalOrZip: string;
    setPostalOrZip: (pz: string) => void;
    countryCode: string;
    setCountryCode: (cc: string) => void;
    phoneNumber: string;
    setPhoneNumber: (pn: string) => void;
    email: string;
    setEmail: (e: string) => void;
    skipVerification: boolean;
    setSkipVerification: (sv: boolean) => void;
    loading: boolean;
    setLoading: (f: boolean) => void;
    resetState: () => void;
}

const CreateContactContext = createContext<State | undefined>(undefined);

export const CreateContactProvider: React.FC = ({ children }) => {
    const [description, setDescription] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [addressLine1, setAddressLine1] = useState<string>('');
    const [addressLine2, setAddressLine2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [provinceOrState, setProvinceOrState] = useState<string>('');
    const [postalOrZip, setPostalOrZip] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('US');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [skipVerification, setSkipVerification] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const resetState = useCallback(() => {
        setDescription('');
        setName('');
        setCompanyName('');
        setJobTitle('');
        setAddressLine1('');
        setAddressLine2('');
        setCity('');
        setProvinceOrState('');
        setPostalOrZip('');
        setCountryCode('');
        setPhoneNumber('');
        setEmail('');
        setSkipVerification(false);
        setLoading(false);
        // Don't care about updating the reset function
        // eslint-disable-next-line
    }, []);

    const value = {
        description,
        setDescription,
        name,
        setName,
        companyName,
        setCompanyName,
        jobTitle,
        setJobTitle,
        addressLine1,
        setAddressLine1,
        addressLine2,
        setAddressLine2,
        city,
        setCity,
        provinceOrState,
        setProvinceOrState,
        postalOrZip,
        setPostalOrZip,
        countryCode,
        setCountryCode,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        skipVerification,
        setSkipVerification,
        loading,
        setLoading,
        resetState,
    };
    return (
        <CreateContactContext.Provider value={value}>
            {children}
        </CreateContactContext.Provider>
    );
};

export const useCreateContactContext = () => {
    const context = useContext(CreateContactContext);
    if (context === undefined) {
        throw new Error(
            'useCreateContactContext must be used within a CreateContactProvider'
        );
    }
    return context;
};
