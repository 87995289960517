import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableBodyLoader from './TableBodyLoader';
import ResetTablePagination, {
    ResetTablePaginationProps,
} from './ResetTablePagination';
import PaperWrapper from './PaperWrapper';
import EmptyTableDisplay from './EmptyTableDisplay';

export interface TableDisplayProps {
    show: boolean;
    columns: Array<string | undefined>;
    pagination?: ResetTablePaginationProps;
    showEmptyTable?: boolean;
    noDataMessage?: string;
    wrapped?: boolean;
}

const TableDisplay: React.FC<TableDisplayProps> = ({
    columns,
    show,
    pagination,
    showEmptyTable = false,
    noDataMessage,
    wrapped,
    children,
}) => {
    const showEmptyComponent = (() => {
        if (
            !show &&
            React.Children.count(children) <= 0 &&
            showEmptyTable === false
        )
            return true;
        return false;
    })();

    const tableComponent = (
        <TableContainer data-testid="table-display">
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((name) => {
                            if (!name) return null;
                            // HACK: Idk why someone would use the same name twice
                            // in a table so this should be good
                            return <TableCell key={name}>{name}</TableCell>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBodyLoader
                    show={show}
                    columns={columns.length}
                    data-testid="table-body"
                >
                    {showEmptyComponent ? (
                        <EmptyTableDisplay
                            colSpan={columns.length}
                            noDataMessage={noDataMessage}
                        />
                    ) : (
                        children
                    )}
                </TableBodyLoader>
                {pagination && (
                    <TableFooter>
                        <TableRow>
                            <ResetTablePagination
                                wrapped={wrapped}
                                {...pagination}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
    if (wrapped) return <PaperWrapper>{tableComponent}</PaperWrapper>;
    return tableComponent;
};

export default TableDisplay;
