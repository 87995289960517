import React, { PropsWithoutRef, useCallback } from 'react';

import {
    Contact,
    label as contactLabel,
    useService as useContactsService,
} from '../services/Contacts';

import SearchSelect, { SearchSelectProps } from './SearchSelect';

export type SelectContactProps = PropsWithoutRef<{
    label: string;
    contact: Contact | null;
    setContact: (contact: Contact) => void;
    required?: boolean;
    size?: SearchSelectProps['size'];
    disabled?: boolean;
    resourceName?: string;
    textFieldTestId?: SearchSelectProps['textFieldTestId'];
}>;

const SelectContact = (props: SelectContactProps) => {
    const service = useContactsService();

    const search = useCallback(
        async (text?: string) => {
            const res = await service.list({
                skip: 0,
                limit: 10,
                search: text,
            });

            return res.data;
        },
        [service]
    );

    return (
        <SearchSelect
            value={props.contact}
            setValue={(value) => props.setContact(value)}
            getOptionLabel={(value) => contactLabel(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            search={search}
            {...props}
        />
    );
};

export default SelectContact;
