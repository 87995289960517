import React, { useState, useEffect, useContext } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { createStore, SVGElementType } from 'polotno/model/store';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { replaceColors, urlToString } from 'polotno/utils/svg';

import {
    SidePanel,
    TextSection,
    BackgroundSection,
    ElementsSection,
    UploadSection,
} from 'polotno/side-panel';

import { setUploadFunc } from 'polotno/config';

import { Workspace } from 'polotno/canvas/workspace';

import { fetchAPI } from '../services/util';

import {
    Template,
    useService as useTemplatesService,
} from '../services/Templates';

import { Context as AuthContext } from '../context/Auth';
import { Context as ModeContext } from '../context/Mode';
import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';
import { useCreateOrderOrigin } from '../context/CreateOrderOrigin';

import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

import { MergeVariableSection } from '../components/MergeVariablePanel';
import { MERGE_VARIABLE_IMAGE } from '../components/MergeVariableImageElement';
import { EditorImagesSection } from '../components/EditorImagesPanel';

export enum EditorCollateral {
    LETTER = 'letter',
    POSTCARD_6X4 = 'postcard_6x4',
    POSTCARD_9X6 = 'postcard_9x6',
    POSTCARD_11X6 = 'postcard_11x6',
}

export enum EditorCollateralDestination {
    US_INTL = 'us_intl',
    UK = 'gb',
    CA = 'ca',
    AU = 'au',
}

export enum EditorPostcardSide {
    FRONT = 'front',
    BACK = 'back',
}

interface EditorFont {
    fontFamily: string;
    url: string;
}

interface EditorElementBase {
    x: number;
    y: number;
    rotation: number;

    opacity: number;

    blurEnabled: boolean;
    blurRadius: number;

    brightnessEnabled: boolean;
    brightness: number;

    shadowEnabled: boolean;
    shadowBlur: number;
    shadowColor: string;

    custom?: {
        skipHTML?: boolean;
    };

    id: string;
}

interface EditorTextElement extends EditorElementBase {
    type: 'text';

    text: string;
    placeholder: string;

    fontSize: number;
    fontFamily: string;
    fontStyle: 'normal' | 'italic';
    fontWeight: string;

    textDecoration: string;

    fill: string;
    align: string;

    width: number;
    height: number;

    strokeWidth: number;
    stroke: string;

    lineHeight: number;
    letterSpacing: number;
}

interface EditorImageElement extends EditorElementBase {
    type: 'image';

    src: string;

    width: number;
    height: number;

    cropX: number;
    cropY: number;
    cropWidth: number;
    cropHeight: number;

    borderColor: string;
    borderSize: number;

    flipX: boolean;
    flipY: boolean;
}

type EditorSVGElement = Omit<EditorImageElement, 'type'> & {
    type: 'svg';
    keepRatio: boolean;
    colorsReplace: Record<string, string>;
};

interface EditorMergeVariableImageElement extends EditorElementBase {
    type: typeof MERGE_VARIABLE_IMAGE;

    width: number;
    height: number;

    mergeVar: string;

    transparency: number;
    maintainAspectRatio: boolean;
}

type EditorElement =
    | EditorTextElement
    | EditorImageElement
    | EditorSVGElement
    | EditorMergeVariableImageElement;

interface EditorPage {
    id: string;
    background: string;
    children: EditorElement[];
}

interface EditorData {
    width: number;
    height: number;
    fonts: EditorFont[];
    pages: EditorPage[];
}

interface RenderData {
    isPostcardBack: boolean;
    urlPrimaryUS: string;
    urlSecondaryUS: string;
    xPrimaryValueUS: number;
    yPrimaryValueUS: number;
    widthPrimaryValueUS: number;
    heightPrimaryValueUS: number;
    xSecondaryValueUS: number;
    ySecondaryValueUS: number;
    widthSecondaryValueUS: number;
    heightSecondaryValueUS: number;
    urlPrimaryCA: string;
    urlSecondaryCA: string;
    xPrimaryValueCA: number;
    yPrimaryValueCA: number;
    widthPrimaryValueCA: number;
    heightPrimaryValueCA: number;
    xSecondaryValueCA: number;
    ySecondaryValueCA: number;
    widthSecondaryValueCA: number;
    heightSecondaryValueCA: number;
    urlPrimaryUK: string;
    xPrimaryValueUK: number;
    yPrimaryValueUK: number;
    widthPrimaryValueUK: number;
    heightPrimaryValueUK: number;
}

const PIXELS_PER_INCH = 96;
const MAX_DATA_URL_SIZE = 5 * 1024;

const createAuthenticatedImageUpload =
    (token: string = '') =>
    async (localFile: File) => {
        const uploadFile = async () => {
            const data = new FormData();

            data.set('file', localFile);
            const res = await fetchAPI<{
                url: string;
            }>('/images', {
                method: 'POST',
                body: data,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return res.url;
        };

        const createDataURL = async () => {
            const dataURLPromise = new Promise<string>((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    if (!reader.result) {
                        reject(
                            new Error('Nothing could be read from the file.')
                        );
                        return;
                    }

                    // Should not be possible
                    if (typeof reader.result !== 'string') {
                        reject(
                            new Error('There were problems loading this file.')
                        );
                        return;
                    }

                    resolve(reader.result);
                };

                const errorHandler = () => {
                    reject(reader.error);
                };
                reader.onerror = errorHandler;
                reader.onabort = errorHandler;

                reader.readAsDataURL(localFile);
            });

            return await dataURLPromise;
        };

        const maxAttempts = 3;

        for (let i = 0; i < maxAttempts; ++i) {
            try {
                return await uploadFile();
            } catch (e) {
                console.error(e);
            }
        }

        return await createDataURL();
    };

const SelectDest = ({
    value,
    setValue,
}: {
    value: EditorCollateralDestination;
    setValue: (v: EditorCollateralDestination) => void;
}) => {
    return (
        <FormControl component="fieldset">
            <Typography>Select Collateral Destination</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(_, v) => setValue(v as EditorCollateralDestination)}
            >
                <FormControlLabel
                    value={EditorCollateralDestination.US_INTL}
                    control={<Radio color="primary" />}
                    label="US & International"
                />
                <FormControlLabel
                    value={EditorCollateralDestination.UK}
                    control={<Radio color="primary" />}
                    label="United Kingdom"
                />
                <FormControlLabel
                    value={EditorCollateralDestination.CA}
                    control={<Radio color="primary" />}
                    label="Canada"
                />
                <FormControlLabel
                    value={EditorCollateralDestination.AU}
                    control={<Radio color="primary" />}
                    label="Australia"
                />
            </RadioGroup>
        </FormControl>
    );
};

const SelectCollateral = ({
    value,
    setValue,
}: {
    value: EditorCollateral;
    setValue: (v: EditorCollateral) => void;
}) => {
    return (
        <FormControl component="fieldset">
            <Typography>Select Collateral Type</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(_, v) => setValue(v as EditorCollateral)}
            >
                <FormControlLabel
                    value={EditorCollateral.LETTER}
                    control={<Radio color="primary" />}
                    label="Letter"
                />
                <FormControlLabel
                    value={EditorCollateral.POSTCARD_6X4}
                    control={<Radio color="primary" />}
                    label="Postcard (6x4)"
                />
                <FormControlLabel
                    value={EditorCollateral.POSTCARD_9X6}
                    control={<Radio color="primary" />}
                    label="Postcard (9x6)"
                />
                <FormControlLabel
                    value={EditorCollateral.POSTCARD_11X6}
                    control={<Radio color="primary" />}
                    label="Postcard (11x6)"
                />
            </RadioGroup>
        </FormControl>
    );
};

const SelectSide = ({
    value,
    setValue,
}: {
    value: EditorPostcardSide;
    setValue: (v: EditorPostcardSide) => void;
}) => {
    return (
        <FormControl component="fieldset">
            <Typography>Select Postcard Side</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(_, v) => setValue(v as EditorPostcardSide)}
            >
                <FormControlLabel
                    value={EditorPostcardSide.FRONT}
                    control={<Radio color="primary" />}
                    label="Front"
                />
                <FormControlLabel
                    value={EditorPostcardSide.BACK}
                    control={<Radio color="primary" />}
                    label="Back"
                />
            </RadioGroup>
        </FormControl>
    );
};

const EditTemplate = () => {
    const { state: authState } = useContext(AuthContext);
    const { live } = useContext(ModeContext);
    const { createOrderOrigin } = useCreateOrderOrigin();

    const uploadFunction = createAuthenticatedImageUpload(
        live ? authState.tokens?.test : authState.tokens?.test
    );
    setUploadFunc(uploadFunction);

    const { templateID } = useParams<{ templateID: string }>();

    const history = useHistory();

    const service = useTemplatesService();

    const { dispatch } = useContext(NotificationContext);

    const [store, setStore] = useState<
        ReturnType<typeof createStore> | undefined
    >();
    const [template, setTemplate] = useState<Template>();

    const [collateralDest, setCollateralDest] = useState(
        EditorCollateralDestination.US_INTL
    );

    const [initCollateral, setInitCollateral] = useState(
        EditorCollateral.LETTER
    );

    const [postcardSide, setPostcardSide] = useState(EditorPostcardSide.FRONT);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Initialize the polotno store on mount
        setStore(
            createStore({
                key: process.env.REACT_APP_POLOTNO_KEY!,
                showCredit: false,
            })
        );

        (async () => {
            try {
                setTemplate(await service.get(templateID));
            } catch (err) {
                console.error(err);
            }
        })();
        // Ignore 'service' from dependency array
        // Don't want the useEffect firing when service changes
        // eslint-disable-next-line
    }, [templateID]);

    useEffect(() => {
        if (!template || !store) {
            return;
        }

        if (template.metadata?.editorData) {
            store.loadJSON(template.metadata.editorData);
        }
    }, [template, store]);

    const initEditor = async () => {
        if (!template || !store) {
            return;
        }

        setLoading(true);

        // TODO Show guidelines on top of the letter or postcard
        switch (initCollateral) {
            case EditorCollateral.LETTER:
                if (collateralDest === EditorCollateralDestination.UK) {
                    store.setSize(
                        8.3 * PIXELS_PER_INCH,
                        11.7 * PIXELS_PER_INCH
                    );
                } else if (collateralDest === EditorCollateralDestination.AU) {
                    store.setSize(
                        8.25 * PIXELS_PER_INCH,
                        11.75 * PIXELS_PER_INCH
                    );
                } else {
                    store.setSize(8.5 * PIXELS_PER_INCH, 11 * PIXELS_PER_INCH);
                }
                break;

            case EditorCollateral.POSTCARD_6X4:
                store.setSize(6.25 * PIXELS_PER_INCH, 4.25 * PIXELS_PER_INCH);
                break;

            case EditorCollateral.POSTCARD_9X6:
                store.setSize(9.25 * PIXELS_PER_INCH, 6.25 * PIXELS_PER_INCH);
                break;

            case EditorCollateral.POSTCARD_11X6:
                store.setSize(11.25 * PIXELS_PER_INCH, 6.25 * PIXELS_PER_INCH);
                break;
        }

        const page = store.addPage();

        if (initCollateral === EditorCollateral.LETTER) {
            let element: any;

            switch (collateralDest) {
                case EditorCollateralDestination.US_INTL:
                    element = page.addElement({
                        type: 'image',
                        src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_letter_address_stamp_new.png',
                        locked: true,
                        x: 0.5 * PIXELS_PER_INCH,
                        y: 0.2 * PIXELS_PER_INCH,
                        width: 3.25 * PIXELS_PER_INCH,
                        height: 2.6 * PIXELS_PER_INCH,
                    });

                    break;

                case EditorCollateralDestination.CA:
                    element = page.addElement({
                        type: 'image',
                        src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_letter_address_stamp.png',
                        locked: true,
                        x: 1.375 * PIXELS_PER_INCH,
                        y: 0.75 * PIXELS_PER_INCH,
                        width: 2.5 * PIXELS_PER_INCH,
                        height: 2.5 * PIXELS_PER_INCH,
                    });

                    break;

                case EditorCollateralDestination.UK:
                    element = page.addElement({
                        type: 'image',
                        src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_letter_address_stamp.png',
                        locked: true,
                        x: 0.787 * PIXELS_PER_INCH,
                        y: 1.54 * PIXELS_PER_INCH,
                        width: 3.54 * PIXELS_PER_INCH,
                        height: 1.77 * PIXELS_PER_INCH,
                    });

                    break;

                case EditorCollateralDestination.AU:
                    element = page.addElement({
                        type: 'image',
                        src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/au_letter_address_stamp.png',
                        locked: true,
                        x: 1.26 * PIXELS_PER_INCH,
                        y: 2.2 * PIXELS_PER_INCH,
                        width: 3.74 * PIXELS_PER_INCH,
                        height: 1.1 * PIXELS_PER_INCH,
                    });

                    break;
            }

            if (element) {
                element.set({ custom: { skipHTML: true } });
            }
        } else {
            let elementPrimary: any;
            let elementSecondary: any;
            let w: number = 0;
            let h: number = 0;

            const offset = 0.125;
            const offsetSecondary = 0.225;

            const render = ({
                isPostcardBack,
                urlPrimaryUS,
                urlSecondaryUS,
                xPrimaryValueUS,
                yPrimaryValueUS,
                widthPrimaryValueUS,
                heightPrimaryValueUS,
                xSecondaryValueUS,
                ySecondaryValueUS,
                widthSecondaryValueUS,
                heightSecondaryValueUS,
                urlPrimaryCA,
                urlSecondaryCA,
                xPrimaryValueCA,
                yPrimaryValueCA,
                widthPrimaryValueCA,
                heightPrimaryValueCA,
                xSecondaryValueCA,
                ySecondaryValueCA,
                widthSecondaryValueCA,
                heightSecondaryValueCA,
                urlPrimaryUK,
                xPrimaryValueUK,
                yPrimaryValueUK,
                widthPrimaryValueUK,
                heightPrimaryValueUK,
            }: RenderData) => {
                if (!isPostcardBack) {
                    return;
                }

                switch (collateralDest) {
                    case EditorCollateralDestination.US_INTL:
                        elementPrimary = page.addElement({
                            type: 'image',
                            src: urlPrimaryUS,
                            locked: true,
                            x: (xPrimaryValueUS - offset) * PIXELS_PER_INCH,
                            y: (yPrimaryValueUS - offset) * PIXELS_PER_INCH,
                            width: widthPrimaryValueUS * PIXELS_PER_INCH,
                            height: heightPrimaryValueUS * PIXELS_PER_INCH,
                        });

                        elementSecondary = page.addElement({
                            type: 'image',
                            src: urlSecondaryUS,
                            locked: true,
                            x: (xSecondaryValueUS - offset) * PIXELS_PER_INCH,
                            y: (ySecondaryValueUS - offset) * PIXELS_PER_INCH,
                            width: widthSecondaryValueUS * PIXELS_PER_INCH,
                            height: heightSecondaryValueUS * PIXELS_PER_INCH,
                        });
                        break;
                    case EditorCollateralDestination.CA:
                        elementPrimary = page.addElement({
                            type: 'image',
                            src: urlPrimaryCA,
                            locked: true,
                            x:
                                (xPrimaryValueCA - offsetSecondary) *
                                PIXELS_PER_INCH,
                            y:
                                (yPrimaryValueCA - offsetSecondary) *
                                PIXELS_PER_INCH,
                            width: widthPrimaryValueCA * PIXELS_PER_INCH,
                            height: heightPrimaryValueCA * PIXELS_PER_INCH,
                        });

                        elementSecondary = page.addElement({
                            type: 'image',
                            src: urlSecondaryCA,
                            locked: true,
                            x: (xSecondaryValueCA - offset) * PIXELS_PER_INCH,
                            y: (ySecondaryValueCA - offset) * PIXELS_PER_INCH,
                            width: widthSecondaryValueCA * PIXELS_PER_INCH,
                            height: heightSecondaryValueCA * PIXELS_PER_INCH,
                        });
                        break;
                    case EditorCollateralDestination.UK:
                        elementPrimary = page.addElement({
                            type: 'image',
                            src: urlPrimaryUK,
                            locked: true,
                            x: (xPrimaryValueUK + offset) * PIXELS_PER_INCH,
                            y: (yPrimaryValueUK + offset) * PIXELS_PER_INCH,
                            width: widthPrimaryValueUK * PIXELS_PER_INCH,
                            height: heightPrimaryValueUK * PIXELS_PER_INCH,
                        });
                        break;
                }

                elementPrimary &&
                    elementPrimary.set({ custom: { skipHTML: true } });
                elementSecondary &&
                    elementSecondary.set({ custom: { skipHTML: true } });
            };

            switch (initCollateral) {
                case EditorCollateral.POSTCARD_6X4:
                    w = 6 * PIXELS_PER_INCH;
                    h = 4 * PIXELS_PER_INCH;

                    render({
                        isPostcardBack:
                            postcardSide === EditorPostcardSide.BACK,
                        urlPrimaryUS:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_6x4_address_indicia_stamp.png',
                        urlSecondaryUS:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_barcode_stamp.png',
                        xPrimaryValueUS: 6.25 - 2.4,
                        yPrimaryValueUS: 2 * offset,
                        widthPrimaryValueUS: 2.4,
                        heightPrimaryValueUS: 4,
                        xSecondaryValueUS: 6.25 - 4.75,
                        ySecondaryValueUS: 4.25 - 0.625,
                        widthSecondaryValueUS: 4.75,
                        heightSecondaryValueUS: 0.625,
                        urlPrimaryCA:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_return_address_stamp.png',
                        urlSecondaryCA:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_6x4_receiver_address_stamp.png',
                        xPrimaryValueCA: 2 * offsetSecondary,
                        yPrimaryValueCA: 2 * offsetSecondary,
                        widthPrimaryValueCA: 2.063,
                        heightPrimaryValueCA: 1.57,
                        xSecondaryValueCA: 6.25 - 2.4,
                        ySecondaryValueCA: 2 * offset,
                        widthSecondaryValueCA: 2.4,
                        heightSecondaryValueCA: 4,
                        urlPrimaryUK:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_postcard_6x4_ink_free_zone.png',
                        xPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                        yPrimaryValueUK: 0,
                        widthPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                        heightPrimaryValueUK: h / PIXELS_PER_INCH,
                    });

                    break;

                case EditorCollateral.POSTCARD_9X6:
                    w = 9 * PIXELS_PER_INCH;
                    h = 6 * PIXELS_PER_INCH;

                    render({
                        isPostcardBack:
                            postcardSide === EditorPostcardSide.BACK,
                        urlPrimaryUS:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_9x6_11x6_address_indicia_stamp.png',
                        urlSecondaryUS:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_barcode_stamp.png',
                        xPrimaryValueUS: 9.25 - 3.6,
                        yPrimaryValueUS: 2 * offset,
                        widthPrimaryValueUS: 3.6,
                        heightPrimaryValueUS: 6,
                        xSecondaryValueUS: 9.25 - 4.75,
                        ySecondaryValueUS: 6.25 - 0.625,
                        widthSecondaryValueUS: 4.75,
                        heightSecondaryValueUS: 0.625,
                        urlPrimaryCA:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_return_address_stamp.png',
                        urlSecondaryCA:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_9x6_11x6_receiver_address_stamp.png',
                        xPrimaryValueCA: 2 * offsetSecondary,
                        yPrimaryValueCA: 2 * offsetSecondary,
                        widthPrimaryValueCA: 2.063,
                        heightPrimaryValueCA: 1.57,
                        xSecondaryValueCA: 9.25 - 3.6,
                        ySecondaryValueCA: 2 * offset,
                        widthSecondaryValueCA: 3.6,
                        heightSecondaryValueCA: 6,
                        urlPrimaryUK:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_postcard_9x6_ink_free_zone.png',
                        xPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                        yPrimaryValueUK: 0,
                        widthPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                        heightPrimaryValueUK: h / PIXELS_PER_INCH,
                    });

                    break;

                case EditorCollateral.POSTCARD_11X6:
                    w = 11 * PIXELS_PER_INCH;
                    h = 6 * PIXELS_PER_INCH;

                    render({
                        isPostcardBack:
                            postcardSide === EditorPostcardSide.BACK,
                        urlPrimaryUS:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_9x6_11x6_address_indicia_stamp.png',
                        urlSecondaryUS:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_barcode_stamp.png',
                        xPrimaryValueUS: 11.25 - 3.6,
                        yPrimaryValueUS: 2 * offset,
                        widthPrimaryValueUS: 3.6,
                        heightPrimaryValueUS: 6,
                        xSecondaryValueUS: 11.25 - 4.75,
                        ySecondaryValueUS: 6.25 - 0.625,
                        widthSecondaryValueUS: 4.75,
                        heightSecondaryValueUS: 0.625,
                        urlPrimaryCA:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_return_address_stamp.png',
                        urlSecondaryCA:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_9x6_11x6_receiver_address_stamp.png',
                        xPrimaryValueCA: 2 * offsetSecondary,
                        yPrimaryValueCA: 2 * offsetSecondary,
                        widthPrimaryValueCA: 2.063,
                        heightPrimaryValueCA: 1.57,
                        xSecondaryValueCA: 11.25 - 3.6,
                        ySecondaryValueCA: 2 * offset,
                        widthSecondaryValueCA: 3.6,
                        heightSecondaryValueCA: 6,
                        urlPrimaryUK:
                            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_postcard_11x6_ink_free_zone.png',
                        xPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                        yPrimaryValueUK: 0,
                        widthPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                        heightPrimaryValueUK: h / PIXELS_PER_INCH,
                    });

                    break;
            }

            // The postcard address guideline code above is a little too complicated for what it
            // accomplishes so I'm going for a simpler SVG-based approach here for UK postcards.

            const borderSVG = (color: string) => {
                return `
                <svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg">
                    <rect width="${w}" height="${h}" style="fill-opacity: 0; stroke: ${color}; stroke-width: 4;" stroke-dasharray="4" stroke-opacity="0.3" />
                </svg>
                `;
            };

            const outerBorderElement = page.addElement({
                type: 'svg',
                src: `data:image/svg+xml;utf8,${borderSVG('green')}`,
                locked: true,
                x: 0.125 * PIXELS_PER_INCH,
                y: 0.125 * PIXELS_PER_INCH,
                width: w,
                height: h,
            });

            outerBorderElement.set({ custom: { skipHTML: true } });

            const innerBorderElement = page.addElement({
                type: 'svg',
                src: `data:image/svg+xml;utf8,${borderSVG('red')}`,
                locked: true,
                x: 0.25 * PIXELS_PER_INCH,
                y: 0.25 * PIXELS_PER_INCH,
                width: w - 0.25 * PIXELS_PER_INCH,
                height: h - 0.25 * PIXELS_PER_INCH,
            });

            innerBorderElement.set({ custom: { skipHTML: true } });
        }

        setTemplate(
            await service.update(template.id, {
                metadata: {
                    ...template.metadata,
                    editorData: store.toJSON(),
                    editorCollateralDest: collateralDest,
                    editorCollateral: initCollateral,
                    editorPostcardSide:
                        initCollateral !== EditorCollateral.LETTER
                            ? postcardSide
                            : undefined,
                },
            })
        );

        setLoading(false);
    };

    const saveTemplate = async () => {
        if (!template || !store) {
            return;
        }

        const editorData: EditorData = store.toJSON();

        const fontFamilies = new Set<string>();

        const innerHTML = [];

        for (const page of editorData.pages) {
            const pageHTML = [];

            for (const elem of page.children) {
                if (elem.custom?.skipHTML) {
                    continue;
                }

                switch (elem.type) {
                    case 'text':
                        fontFamilies.add(elem.fontFamily);

                        pageHTML.push(`
                            <div style="
                                position: absolute;

                                left: ${elem.x}px;
                                top: ${elem.y}px;

                                transform: rotate(${elem.rotation}deg);
                                transform-origin: top left;

                                width: ${elem.width}px;
                                height: ${elem.height}px;

                                font-family: '${elem.fontFamily}';
                                font-size: ${elem.fontSize}px;
                                font-style: ${elem.fontStyle};
                                font-weight: ${elem.fontWeight};

                                color: ${elem.fill};
                                opacity: ${elem.opacity};

                                text-align: ${elem.align};

                                line-height: ${elem.lineHeight};
                                letter-spacing: ${elem.letterSpacing};
                                text-decoration: ${
                                    elem.textDecoration || 'none'
                                };
                                white-space: pre-wrap;
                            ">${elem.text.replace(/[\n\r]/gi, '<br>')}</div>`);
                        break;

                    case 'svg':
                        const svgString = await urlToString(elem.src);

                        const replaceMap = new Map(
                            Object.entries(elem.colorsReplace || {})
                        );

                        const newSrc = replaceColors(
                            elem.keepRatio
                                ? svgString
                                : svgString.replaceAll(
                                      '<svg',
                                      '<svg preserveAspectRatio="none"'
                                  ),
                            replaceMap
                        );

                        const src =
                            newSrc.startsWith('data:') &&
                            newSrc.length > MAX_DATA_URL_SIZE
                                ? await (async () => {
                                      const newSVGString = await urlToString(
                                          newSrc
                                      );
                                      const localFile = new File(
                                          [newSVGString],
                                          'image.svg',
                                          {
                                              type: 'image/svg+xml',
                                          }
                                      );

                                      return await uploadFunction(localFile);
                                  })()
                                : newSrc;

                        // Also replace src in metadata before upload
                        if (
                            elem.src.startsWith('data:') &&
                            elem.src.length > MAX_DATA_URL_SIZE
                        ) {
                            const storePage = store.pages.find(
                                (page_) => page_.id === page.id
                            );
                            const storeElem: SVGElementType =
                                storePage!.children.find(
                                    (elem_) => elem_.id === elem.id
                                );

                            storeElem.set({
                                src: await (async () => {
                                    const localFile = new File(
                                        [svgString],
                                        'image.svg',
                                        {
                                            type: 'image/svg+xml',
                                        }
                                    );

                                    return await uploadFunction(localFile);
                                })(),
                            });
                        }

                        // For now (Polotno ^0.27.0), no cropping allowed.
                        pageHTML.push(`
                            <div style="
                                position: absolute;

                                margin: 0;
                                padding: 0;

                                left: ${elem.x}px;
                                top: ${elem.y}px;

                                width: ${elem.width}px;
                                height: ${elem.height}px;

                                overflow: hidden;

                                transform: rotate(${elem.rotation}deg) scaleX(${
                            elem.flipX ? -1 : 1
                        }) scaleY(${elem.flipY ? -1 : 1});

                                transform-origin: top left;
                            ">

                                <img src="${src}" style="
                                    position: absolute;

                                    margin: 0;
                                    padding: 0;

                                    left: 0;
                                    top: 0;

                                    width: ${elem.width}px;
                                    height: ${elem.height}px;

                                    opacity: ${elem.opacity};

                                    filter:
                                        blur(${
                                            elem.blurEnabled
                                                ? elem.blurRadius
                                                : 0
                                        }px)
                                        brightness(${
                                            elem.brightnessEnabled
                                                ? 1 + elem.brightness
                                                : 1
                                        })
                                        ${
                                            elem.shadowEnabled
                                                ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                                : ''
                                        };
                                " />
                                ${
                                    elem.borderSize
                                        ? `<div style="
                                                position: absolute;

                                                width: ${elem.width}px;
                                                height: ${elem.height}px;

                                                border: ${elem.borderSize}px solid ${elem.borderColor}
                                            ">
                                </div>`
                                        : ''
                                }
                            </div>`);
                        break;

                    case 'image':
                        pageHTML.push(`
                            <div style="
                                position: absolute;

                                margin: 0;
                                padding: 0;

                                left: ${elem.x}px;
                                top: ${elem.y}px;

                                width: ${elem.width}px;
                                height: ${elem.height}px;

                                clip: rect(0px, ${elem.width}, ${
                            elem.height
                        }, 0px);


                                overflow: visible;

                                transform: rotate(${elem.rotation}deg) scaleX(${
                            elem.flipX ? -1 : 1
                        }) scaleY(${elem.flipY ? -1 : 1});

                                transform-origin: top left;
                            ">
                                <img src="${elem.src}"
                                    style="
                                        width: ${elem.width / elem.cropWidth}px;
                                        height: ${
                                            elem.height / elem.cropHeight
                                        }px;
                                        position: absolute;
                                        margin: 0;
                                        padding: 0;
                                        left: 0;
                                        top: 0;
                                        margin-top: ${
                                            -elem.cropY *
                                            (elem.height / elem.cropHeight)
                                        }px;
                                        margin-left: ${
                                            -elem.cropX *
                                            (elem.width / elem.cropWidth)
                                        }px;

                                        filter:
                                            blur(${
                                                elem.blurEnabled
                                                    ? elem.blurRadius
                                                    : 0
                                            }px)
                                            brightness(${
                                                elem.brightnessEnabled
                                                    ? 1 + elem.brightness
                                                    : 1
                                            })
                                            ${
                                                elem.shadowEnabled
                                                    ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                                    : ''
                                            };

                                        opacity: ${elem.opacity};
                                    "
                                />
                                ${
                                    elem.borderSize
                                        ? `<div style="
                                                position: absolute;

                                                width: ${elem.width}px;
                                                height: ${elem.height}px;

                                                border: ${elem.borderSize}px solid ${elem.borderColor}
                                            ">
                                </div>`
                                        : ''
                                }
                            </div>`);
                        break;
                    case MERGE_VARIABLE_IMAGE: {
                        pageHTML.push(`
                <div style="
                    position: absolute;

                    margin: 0;
                    padding: 0;

                    left: ${elem.x}px;
                    top: ${elem.y}px;

                    width: ${elem.width}px;
                    height: ${elem.height}px;

                    overflow: visible;

                    transform: rotate(${elem.rotation}deg);

                    transform-origin: top left;
                    ">

                    <img src="{{${elem.mergeVar}}}"
                        style="
                        position: absolute;

                        margin: 0;
                        padding: 0;

                        left: 0;
                        top: 0;

                        margin-left: ${elem.width}px;
                        margin-top: ${elem.height}px;

                        ${
                            elem.maintainAspectRatio
                                ? 'object-fit: contain;'
                                : ''
                        }
                        width: ${elem.width}px;
                        height: ${elem.height}px;

                        opacity: ${elem.opacity};

                        filter: blur(${
                            elem.blurEnabled ? elem.blurRadius : 0
                        }px) brightness(${
                            elem.brightnessEnabled ? 1 + elem.brightness : 1
                        })
                        ${
                            elem.shadowEnabled
                                ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                : ''
                        };"
                    />
                </div>`);
                    }
                }
            }

            let backgroundStyle = '';

            if (page.background.includes('http')) {
                backgroundStyle = `style="background: url(${page.background}); background-size: auto;"`;
            } else {
                backgroundStyle = `style="background-color: ${page.background}"`;
            }

            innerHTML.push(`
                <div class="page" ${backgroundStyle}>
                    ${pageHTML.join('\n')}
                </div>
            `);
        }

        const fontLinks = Array.from(fontFamilies).map(
            (f) =>
                `<link
                    rel="stylesheet"
                    type="text/css"
                    href="https://fonts.googleapis.com/css?family=${encodeURIComponent(
                        f
                    )}" />`
        );

        const html = `
        <html>
            <head>
                <style>
                    * {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                    }

                    body {
                        width: ${store.width}px;
                        height: ${store.height}px;
                    }

                    .page {
                        position: relative;
                        width: ${store.width}px;
                        height: ${store.height}px;
                        overflow: hidden;

                        ${
                            template.metadata?.editorCollateral ===
                            EditorCollateral.LETTER
                                ? 'page-break-after: always'
                                : ''
                        }
                    }
                </style>

                ${fontLinks.join('\n')}
            </head>
            <body>
                ${innerHTML.join('\n')}
            </body>
        </html>`;

        setLoading(true);

        await service.update(templateID, {
            html,
            metadata: {
                ...template.metadata,
                // Update the editorData for changes
                editorData: store.toJSON(),
            },
        });

        dispatch({
            type: MessageType.SUCCESS,
            message: 'Saved template.',
        });

        setLoading(false);
        if (createOrderOrigin) {
            history.push(createOrderOrigin);
        }
    };

    // TODO Display loader
    return template && store ? (
        <>
            <TopNav />

            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography variant="h5">
                                Edit Template - {template.description}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                            history.push(
                                                `/dashboard/templates/${template.id}`
                                            )
                                        }
                                    >
                                        Back
                                    </Button>
                                </Grid>

                                {template.metadata?.editorData && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={saveTemplate}
                                            disabled={loading || !template}
                                        >
                                            Save Template
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    {template.metadata?.editorData ? (
                        <Box display="flex">
                            <Box display="flex" width="300px" height="700px">
                                <SidePanel
                                    store={store}
                                    sections={[
                                        TextSection,
                                        ElementsSection,
                                        EditorImagesSection,
                                        UploadSection,
                                        MergeVariableSection,
                                        BackgroundSection,
                                    ]}
                                />
                            </Box>

                            <Box
                                display="flex"
                                margin="auto"
                                height="700px"
                                flex="1"
                                flexDirection="column"
                                position="relative"
                            >
                                <Toolbar store={store} hideLock />
                                <Workspace
                                    store={store}
                                    pageControlsEnabled={
                                        template.metadata?.editorCollateral ===
                                        EditorCollateral.LETTER
                                    }
                                />
                                <ZoomButtons store={store} />
                            </Box>
                        </Box>
                    ) : (
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <SelectDest
                                    value={collateralDest}
                                    setValue={setCollateralDest}
                                />
                            </Grid>

                            <Grid item>
                                <SelectCollateral
                                    value={initCollateral}
                                    setValue={setInitCollateral}
                                />
                            </Grid>

                            {initCollateral !== EditorCollateral.LETTER && (
                                <Grid item>
                                    <SelectSide
                                        value={postcardSide}
                                        setValue={setPostcardSide}
                                    />
                                </Grid>
                            )}

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={initEditor}
                                    disabled={loading}
                                >
                                    Start Editing
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </GridPaper>
        </>
    ) : null;
};

export default EditTemplate;
