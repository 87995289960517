import React from 'react';

import { getCodes, getName } from 'country-list';


import Box from '@material-ui/core/Box';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormField } from '../pages/Login';

const countryCodes = getCodes();

const CountrySelect = (props: {
    size: 'small' | 'medium';
    field: typeof TextField | typeof FormField;
    countryCode: string;
    setCountryCode: (s: string) => void;
}) => {
    return (
        <Autocomplete
            options={countryCodes}
            autoHighlight
            getOptionLabel={(code) => getName(code) || ''}
            renderOption={(option) => (
                <Box component="li">
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                        alt=""
                    />{' '}
                    {getName(option)}
                </Box>
            )}
            renderInput={(params) => (
                <props.field
                    {...params}
                    required
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        // Disable autofill
                        autoComplete: 'new-password',
                        id: 'country',
                    }}
                    InputLabelProps={{
                        htmlFor: 'country',
                    }}
                    label="Country"
                />
            )}
            value={props.countryCode}
            onChange={(e, v) => props.setCountryCode(v !== null ? v : '')}
            size={props.size}
            fullWidth
        />
    );
};

export default CountrySelect;
