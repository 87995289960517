import React, { useContext, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { ChartOptions } from 'chart.js';
import { Bar } from '@reactchartjs/react-chart.js';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { POSTCARDS_ENABLED } from '../config';

import { Context as ModeContext } from '../context/Mode';

import {
    Analytics as AnalyticsObject,
    OrdersByStatus,
    useService as useAnalyticsService,
} from '../services/Analytics';

import { statusLabel } from '../services/Letters';

import { useDebouncedValue } from '../services/util';

import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';

import readyLogo from '../img/icons/ready.png';
import printingLogo from '../img/icons/printing.png';
import processedLogo from '../img/icons/processed.png';
import completedLogo from '../img/icons/completed.png';
import cancelledLogo from '../img/icons/cancelled.png';
import totalLogo from '../img/icons/total.png';
import { OrderStatus } from '../services/Orders';

enum Type {
    LETTER = 'letter',
    POSTCARD = 'postcard',
    CHEQUE = 'cheque',
}

const LABELS = [
    {
        label: OrderStatus.READY,
        icon: readyLogo,
    },
    {
        label: OrderStatus.PRINTING,
        icon: printingLogo,
    },
    {
        label: OrderStatus.PROCESSED_FOR_DELIVERY,
        icon: processedLogo,
    },
    {
        label: OrderStatus.COMPLETED,
        icon: completedLogo,
    },
    {
        label: OrderStatus.CANCELLED,
        icon: cancelledLogo,
    },
];

const InfoCards = ({ byStatus }: { byStatus?: OrdersByStatus[] }) => {
    const totalLetters = byStatus && byStatus.reduce((a, b) => a + b.count, 0);

    const statusToCount: Record<string, number> = {};

    for (const value of byStatus || []) {
        statusToCount[value.status] = value.count;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} lg>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={9}>
                                <Typography variant="h6">Total</Typography>
                                {totalLetters !== undefined ? (
                                    <Typography color="textSecondary">
                                        {totalLetters}
                                    </Typography>
                                ) : (
                                    <Skeleton />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <img src={totalLogo} alt="Total" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {LABELS.map((item) => (
                <Grid item xs={6} lg key={item.label}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={9}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {statusLabel(item.label).split(' ')[0]}
                                    </Typography>
                                    {byStatus ? (
                                        <Typography color="textSecondary">
                                            {statusToCount[item.label] || 0}
                                        </Typography>
                                    ) : (
                                        <Skeleton />
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <img src={item.icon} alt={item.label} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

const useButtonStyle = makeStyles((theme) => ({
    root: {
        margin: '0 8px',
        color: '#D2D2D2',
        border: '1px solid transparent',
        borderRadius: '5px !important',
        textTransform: 'capitalize',
    },
    selected: {
        backgroundColor: 'transparent !important',
        borderLeft: '1px solid #6B6B6B !important',
        borderColor: '#6B6B6B',
        color: '#6B6B6B !important',
    },
}));

const useGridStyles = makeStyles((theme) => ({
    root: {
        padding: '0 13px !important',
        borderRight: '1px solid #D2D2D2',
        '&:last-child': {
            borderRight: 'none',
        },
    },
}));

const Analytics = () => {
    const theme = useTheme();

    const history = useHistory();

    const service = useAnalyticsService();

    const { live } = useContext(ModeContext);

    const [type, setType] = useState(Type.LETTER);
    const [period, setPeriod] = useState('month');
    const [searchText, setSearchText] = useState('');

    const search = useDebouncedValue(searchText, 200);

    const [analytics, setAnalytics] = useState<AnalyticsObject>();

    const loading = !analytics || search !== searchText;

    const toggleButton = useButtonStyle();

    useEffect(() => {
        (async () => {
            try {
                setAnalytics(undefined);

                const startDate = new Date();
                const endDate = new Date();

                if (period === 'month') {
                    startDate.setMonth(startDate.getMonth() - 1);
                } else {
                    startDate.setMonth(startDate.getMonth() - 3);
                }

                setAnalytics(
                    await service.get({
                        startDate,
                        endDate,
                        search,
                    })
                );
            } catch (err) {
                console.error(err);
            }
        })();
    }, [history.location, service, search, period]);

    const byStatus =
        analytics &&
        {
            [Type.LETTER]: analytics.lettersByStatus,
            [Type.POSTCARD]: analytics.postcardsByStatus,
            [Type.CHEQUE]: analytics.chequesByStatus,
        }[type];

    const data = byStatus && {
        labels: LABELS.map((item) => statusLabel(item.label)),
        datasets: [
            {
                label: type,
                data: LABELS.map(
                    (item) =>
                        byStatus.find((v) => v.status === item.label)?.count ||
                        0
                ),
                backgroundColor: theme.palette.primary.main,
            },
        ],
    };

    const options: ChartOptions = {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value: number) =>
                            value % 1 === 0 ? value : undefined,
                    },
                },
            ],
        },
    };

    const gridClass = useGridStyles();

    const searchQuery = (value: string) => {
        setSearchText(value);
    };

    // TODO Make the CircularProgress dynamic with a gray background
    return (
        <>
            <TopNav
                showSearch
                searchText={searchText}
                searchQuery={searchQuery}
            />
            <GridPaper direction="column" spacing={2}>
                <Grid container item justify="space-between">
                    <Grid item>
                        <Typography variant="h5">Analytics</Typography>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item classes={{ root: gridClass.root }}>
                                <ToggleButtonGroup
                                    value={type}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setType(value);
                                        }
                                    }}
                                    exclusive
                                >
                                    <ToggleButton
                                        size="small"
                                        value={Type.LETTER}
                                        classes={{
                                            root: toggleButton.root,
                                            selected: toggleButton.selected,
                                        }}
                                    >
                                        Letters
                                    </ToggleButton>
                                    {POSTCARDS_ENABLED && (
                                        <ToggleButton
                                            size="small"
                                            value={Type.POSTCARD}
                                            classes={{
                                                root: toggleButton.root,
                                                selected: toggleButton.selected,
                                            }}
                                        >
                                            Postcards
                                        </ToggleButton>
                                    )}
                                    <ToggleButton
                                        size="small"
                                        value={Type.CHEQUE}
                                        classes={{
                                            root: toggleButton.root,
                                            selected: toggleButton.selected,
                                        }}
                                    >
                                        Cheques
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item classes={{ root: gridClass.root }}>
                                <ToggleButtonGroup
                                    value={period}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setPeriod(value);
                                        }
                                    }}
                                    exclusive
                                >
                                    <ToggleButton
                                        size="small"
                                        color="primary"
                                        value="month"
                                        classes={{
                                            root: toggleButton.root,
                                            selected: toggleButton.selected,
                                        }}
                                    >
                                        Past Month
                                    </ToggleButton>
                                    <ToggleButton
                                        size="small"
                                        value="three_months"
                                        classes={{
                                            root: toggleButton.root,
                                            selected: toggleButton.selected,
                                        }}
                                    >
                                        Past 3 Months
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!live && (
                    <Grid item>
                        <Alert variant="outlined" color="info">
                            You are in test mode, so the analytics you see will
                            only pertain to test orders.
                        </Alert>
                    </Grid>
                )}
                <Grid item>
                    <InfoCards byStatus={!loading ? byStatus : undefined} />
                </Grid>
                <Grid item>
                    <Box mt={5}>
                        {loading || !data ? (
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{ minHeight: '50vh' }}
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Bar
                                type="line"
                                data={data}
                                options={options}
                                height={80}
                            />
                        )}
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default Analytics;
