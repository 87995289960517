import React, {
    useState,
    useContext,
    useMemo,
    useCallback,
    FormEvent,
} from 'react';

import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Context as ModeContext } from '../context/Mode';

import {
    CreateParams,
    Postcard,
    PostcardSize,
    useService as usePostcardsService,
} from '../services/Postcards';

import { Contact } from '../services/Contacts';
import { findVariables } from '../services/Templates';
import { flatten, unflatten } from '../services/util';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import SelectTemplate from '../components/SelectTemplate';
import GoBackButton from '../components/GoBackButton';
import FileUpload from '../components/FileUpload';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import ContactInput from '../components/ContactInput';
import ContactOrCSV from '../components/ContactOrCSV';
import BatchSend, {
    BatchSendStatus,
    BatchSendState,
    BatchSendProps,
} from '../components/BatchSend';
import MergeVariablesInput from '../components/MergeVariablesInput';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import MailingClassSelector from '../components/MailingClassSelector';
import { useCreatePostcard } from '../context/CreatePostcard';
import { useResetCreateOrderState } from '../hooks/useResetCreateOrderState';

const CreatePostcard = () => {
    const history = useHistory();

    const service = usePostcardsService();

    const { live } = useContext(ModeContext);
    const { dispatch } = useContext(NotificationContext);
    const {
        backTemplate,
        description,
        express,
        file,
        fromContact,
        frontTemplate,
        loading,
        mailingClass,
        mergeVars,
        size,
        setSize,
        setBackTemplate,
        setDescription,
        setExpress,
        setFile,
        setFromContact,
        setFrontTemplate,
        setLoading,
        setMailingClass,
        setMergeVars,
        setToContacts,
        setUploadedCSV,
        toContacts,
        uploadedCSV,
        resetState,
    } = useCreatePostcard();

    useResetCreateOrderState(resetState);

    // We use a set to get rid of duplicate merge variables
    const templateVars = useMemo<string[]>(
        () =>
            Array.from(
                new Set(
                    [frontTemplate, backTemplate]
                        .filter((t) => !!t)
                        .map((t) => findVariables(t!.html))
                        .reduce((prev, cur) => prev.concat(cur), [])
                )
            ),
        [frontTemplate, backTemplate]
    );

    const [batchSendState, setBatchSendState] = useState<
        BatchSendState<Postcard>
    >({
        processedCount: 0,
        status: BatchSendStatus.READY,
        results: [],
    });

    const defaultVars = useMemo(
        () =>
            flatten({
                to: toContacts.length > 0 ? toContacts[0] : {},
                from: fromContact,
            }),
        [toContacts, fromContact]
    );

    const createBatch = useCallback(
        async (
            params: Parameters<BatchSendProps<Postcard>['createBatch']>[0]
        ) => {
            const mergeVariables = mergeVars && unflatten(mergeVars);

            return await service.createBatch({
                frontTemplate: frontTemplate ? frontTemplate.id : undefined,
                backTemplate: backTemplate ? backTemplate.id : undefined,
                pdf: file ?? undefined,
                size,
                data: params.toContacts.map((c) => ({
                    description,
                    from: fromContact?.id,
                    to: c.id,
                    mergeVariables: {
                        ...mergeVariables,
                        ...c.metadata,
                        to: {
                            ...mergeVariables?.to,
                            ...c,
                        },
                    },
                })),
                express,
                handleProgress: params.handleProgress,
            });
        },
        [
            description,
            service,
            frontTemplate,
            backTemplate,
            file,
            fromContact,
            size,
            mergeVars,
            express,
        ]
    );

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            toContacts.length === 0 ||
            ((!frontTemplate || !backTemplate) && !file)
        ) {
            // This should be impossible given that all of the fields above are required
            return;
        }

        try {
            setLoading(true);

            const send = async (toContact: Contact) => {
                let mergeVariables = mergeVars && unflatten(mergeVars);
                const postcard: CreateParams = {
                    description,
                    size,
                    to: toContact.id,
                    from: fromContact?.id,
                    express,
                    mailingClass: express ? undefined : mailingClass,
                };

                if (toContact.metadata) {
                    mergeVariables = {
                        ...mergeVariables,
                        ...toContact.metadata,
                    };
                }

                if (uploadedCSV) {
                    // Delete the 'to' object from the mergeVariables so
                    // that its sourced from our contact data.
                    delete mergeVariables?.to;
                }

                if (frontTemplate && backTemplate) {
                    return await service.create({
                        ...postcard,
                        frontTemplate: frontTemplate.id,
                        backTemplate: backTemplate.id,
                        mergeVariables,
                    });
                }

                return await service.createUploadPDF({
                    ...postcard,
                    pdf: file!,
                });
            };

            if (toContacts.length === 1) {
                await send(toContacts[0]);

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Created postcard.',
                });

                history.push('/dashboard/postcards');
            } else {
                setBatchSendState((state) => ({
                    ...state,
                    status: BatchSendStatus.IN_PROGRESS,
                }));
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Create a Postcard
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={3.5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {live ? (
                                    <Alert variant="outlined" color="warning">
                                        You are in live mode so this postcard
                                        will be printed and delivered.
                                    </Alert>
                                ) : (
                                    <Alert variant="outlined" color="info">
                                        You are in test mode so this postcard
                                        will not actually get sent out.
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={onSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box height={13}></Box>
                                            <TextField
                                                variant="outlined"
                                                label="Description"
                                                fullWidth
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={6}
                                            alignItems="center"
                                        >
                                            <FormControl component="fieldset">
                                                <Typography>
                                                    Size in Inches (Width x
                                                    Height)
                                                </Typography>
                                                <RadioGroup
                                                    row
                                                    value={size}
                                                    onChange={(e, v) =>
                                                        setSize(
                                                            v as PostcardSize
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value={
                                                            PostcardSize.SIZE_6X4
                                                        }
                                                        control={
                                                            <Radio color="primary" />
                                                        }
                                                        label="6x4"
                                                    />
                                                    <FormControlLabel
                                                        value={
                                                            PostcardSize.SIZE_9X6
                                                        }
                                                        control={
                                                            <Radio color="primary" />
                                                        }
                                                        label="9x6"
                                                    />
                                                    <FormControlLabel
                                                        value={
                                                            PostcardSize.SIZE_11X6
                                                        }
                                                        control={
                                                            <Radio color="primary" />
                                                        }
                                                        label="11x6"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContactOrCSV
                                                label="To Contact"
                                                contacts={toContacts}
                                                setContacts={setToContacts}
                                                setUploadedCSV={setUploadedCSV}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContactInput
                                                label="From Contact"
                                                contact={fromContact}
                                                setContact={setFromContact}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <SelectTemplate
                                                        label="Front Template"
                                                        template={frontTemplate}
                                                        setTemplate={
                                                            setFrontTemplate
                                                        }
                                                        required={!file}
                                                        disabled={
                                                            file ? true : false
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <SelectTemplate
                                                        label="Back Template"
                                                        template={backTemplate}
                                                        setTemplate={
                                                            setBackTemplate
                                                        }
                                                        required={!file}
                                                        disabled={
                                                            file ? true : false
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FileUpload
                                                accept="application/pdf"
                                                label="Upload a PDF"
                                                file={file}
                                                setFile={setFile}
                                                required={
                                                    !frontTemplate &&
                                                    !backTemplate
                                                }
                                                disabled={
                                                    !!frontTemplate ||
                                                    !!backTemplate
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Collapse
                                                in={templateVars.length > 0}
                                            >
                                                <MergeVariablesInput
                                                    templateVars={templateVars}
                                                    mergeVars={mergeVars}
                                                    setMergeVars={setMergeVars}
                                                    defaultVars={defaultVars}
                                                />
                                            </Collapse>
                                        </Grid>

                                        <MailingClassSelector
                                            xs={3}
                                            mailingClass={mailingClass}
                                            onChange={setMailingClass}
                                            disabled={express}
                                        />

                                        <ExpressDeliveryCheckbox
                                            checked={express}
                                            setChecked={setExpress}
                                        />

                                        {batchSendState.status !==
                                            BatchSendStatus.READY && (
                                            <Grid item xs={12}>
                                                <BatchSend
                                                    state={batchSendState}
                                                    setState={setBatchSendState}
                                                    toContacts={toContacts}
                                                    createBatch={createBatch}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {batchSendState.status ===
                                                    BatchSendStatus.READY && (
                                                    <Grid item xs={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            disabled={loading}
                                                            size="large"
                                                            fullWidth
                                                        >
                                                            Create
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item xs={2}>
                                                    <GoBackButton
                                                        color="primary"
                                                        variant="outlined"
                                                        disabled={
                                                            loading ||
                                                            batchSendState.status ===
                                                                BatchSendStatus.IN_PROGRESS
                                                        }
                                                        size="large"
                                                        fullWidth
                                                    >
                                                        {batchSendState.status ===
                                                            BatchSendStatus.COMPLETED ||
                                                        batchSendState.status ===
                                                            BatchSendStatus.ERROR
                                                            ? 'Back'
                                                            : 'Cancel'}
                                                    </GoBackButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreatePostcard;
