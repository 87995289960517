import Person from '@material-ui/icons/Person';
import React from 'react';
import { Contact, fullName } from '../services/Contacts';
import DetailCell from './DetailCell';
import DetailWrapper from './DetailWrapper';

interface PersonalInfoProps {
    contact: Contact;
    title?: string;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ contact, title }) => {
    return (
        <DetailWrapper
            icon={Person}
            title={title ? title : 'Personal'}
            direction="column"
            spacing={1}
        >
            <DetailCell
                right="Name"
                left={fullName(contact)}
                testIdPrefix="name"
            />
            <DetailCell
                right="Email"
                left={contact.email}
                testIdPrefix="email"
            />
            <DetailCell
                right="Phone Number"
                left={contact.phoneNumber}
                testIdPrefix="phone-number"
            />
            <DetailCell
                right="Company Name"
                left={contact.companyName}
                testIdPrefix="company"
            />
            <DetailCell
                right="Job Title"
                left={contact.jobTitle}
                testIdPrefix="job-title"
            />
        </DetailWrapper>
    );
};

export default PersonalInfo;
