import React, { useCallback } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
    displayAccountNumber,
    useService as useBankAccountsService,
} from '../services/BankAccounts';

import { formatTableDate, ListParams } from '../services/util';

import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

import CreateBankAccount from './CreateBankAccount';
import ViewBankAccount from './ViewBankAccount';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import { BankAccountRoutes } from '../routes';

const BankAccounts = () => {
    const history = useHistory();

    const service = useBankAccountsService();

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: accounts,
        loading,
        searchQuery,
        searchText,
        pagination,
    } = usePagination(paginationFunc);

    return (
        <Switch>
            <Route exact path={BankAccountRoutes.HOME}>
                <TopNav
                    showSearch
                    searchText={searchText}
                    searchQuery={searchQuery}
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader
                        title="Bank Accounts"
                        liveWarning
                        alertText={
                            <span>
                                You are in test mode, so you will only see test
                                mode bank accounts. You <em>cannot</em>, create
                                live orders with these.
                            </span>
                        }
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    history.push(BankAccountRoutes.CREATE);
                                }}
                            >
                                Create Bank Account
                            </Button>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            columns={[
                                'Description',
                                'Bank Name',
                                'Account Number',
                                'Created At',
                            ]}
                            show={loading}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {accounts.map((account) => {
                                return (
                                    <TableRow
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            history.push(
                                                `${BankAccountRoutes.HOME}/${account.id}`
                                            );
                                        }}
                                        key={account.id}
                                    >
                                        <TableCell>
                                            {account.description}
                                        </TableCell>
                                        <TableCell>
                                            {account.bankName}
                                        </TableCell>
                                        <TableCell>
                                            <Box fontFamily="monospace">
                                                {displayAccountNumber(account)}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {formatTableDate(account.createdAt)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route
                path={BankAccountRoutes.CREATE}
                component={CreateBankAccount}
            />
            <Route path={BankAccountRoutes.VIEW} component={ViewBankAccount} />
        </Switch>
    );
};

export default BankAccounts;
