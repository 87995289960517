import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import BulkCancelOrdersTable from './BulkCancelOrdersTable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useBulkCancelOrdersContext } from '../context/BulkCancelOrders';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        overflowY: 'auto',
        borderTop: `2px solid ${theme.palette.grey[100]}`,
        '& tr:nth-of-type(odd)': {
            backgroundColor: 'white',
        },
        '& tr:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[100],
        },
    },
}));

const BulkCancelOrdersAccordion = () => {
    const classes = useStyles();
    const { count, object, expandAccordion, handleExpandAccordion, fetching } =
        useBulkCancelOrdersContext();

    return (
        <Accordion expanded={expandAccordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={handleExpandAccordion}
                data-testid='cancel-accordion-summary'
            >
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography>
                        Found {count} {object}s
                    </Typography>
                    {fetching && (
                        <Box display="flex" alignItems="center">
                            <CircularProgress size="1rem" />
                        </Box>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.container}>
                <BulkCancelOrdersTable />
            </AccordionDetails>
        </Accordion>
    );
};

export default BulkCancelOrdersAccordion;
