import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import {
    Theme,
    ThemeProvider,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { POSTCARDS_ENABLED } from '../config';

import { Context as ModeContext } from '../context/Mode';

import ListItemLink from './ListItemLink';
import logo from '../logo.png';
import {
    AnalyticsIcon,
    HomeIcon,
    ContactIcon,
    TemplateIcon,
    BankIcon,
    LettersIcon,
    PostcardIcon,
    UserIcon,
    PaymentIcon,
    InvoiceIcon,
    UpgradeIcon,
    FileIcon,
    SettingsIcon,
    WebhookIcon,
    MailIcon,
    APILogIcon,
} from './Icons';
import {
    APILogRoutes,
    BankAccountRoutes,
    ChequeRoutes,
    ContactRoutes,
    LetterRoutes,
    PostcardRoutes,
    ReturnEnvelopeRoutes,
    TemplateRoutes,
} from '../routes';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-10px',
    },
    navRoot: {
        paddingTop: 0,
        paddingBottom: 0,
        height: '100%',
    },
    listItemRoot: {
        bottom: '0',
        position: 'absolute',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
}));

const Sidenav = () => {
    const classes = useStyles();

    const { live, setLive } = useContext(ModeContext);

    return (
        <ThemeProvider
            theme={(theme: Theme) =>
                createMuiTheme({
                    ...theme,
                    typography: {
                        ...theme.typography,
                        body2: {
                            ...theme.typography.body2,
                            fontFamily: 'Poppins',
                            fontSize: '0.765rem',
                        },
                    },
                    overrides: {
                        MuiListItemIcon: {
                            root: {
                                minWidth: '24px',
                            },
                        },
                    },
                })
            }
        >
            <Paper
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '255px',
                    overflowY: 'auto',
                    padding: '10px 0 10px 0',
                    zIndex: 999,
                    lineHeight: 1,
                }}
            >
                <List
                    component="nav"
                    dense={POSTCARDS_ENABLED}
                    classes={{ root: classes.navRoot }}
                >
                    <ListItem>
                        <Box pr={2.5}>
                            <Link to="/dashboard">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    style={{ width: '132px' }}
                                />
                            </Link>
                        </Box>
                    </ListItem>

                    <Box ml={-2.5} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Divider style={{ backgroundColor: '#eee' }} />
                    </Box>

                    <Box ml={1} mt={3} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Dashboard
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Overview"
                        icon={HomeIcon}
                        to="/dashboard"
                    />

                    <ListItemLink
                        primary="Analytics"
                        icon={AnalyticsIcon}
                        to="/dashboard/analytics"
                    />

                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Resources
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Contacts"
                        icon={ContactIcon}
                        to={ContactRoutes.HOME}
                    />

                    <ListItemLink
                        primary="Templates"
                        icon={TemplateIcon}
                        to={TemplateRoutes.HOME}
                    />

                    <ListItemLink
                        primary="Bank Accounts"
                        icon={BankIcon}
                        to={BankAccountRoutes.HOME}
                    />

                    <ListItemLink
                        primary="Return Envelopes"
                        icon={MailIcon}
                        to={ReturnEnvelopeRoutes.HOME}
                    />

                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Orders
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Letters"
                        icon={LettersIcon}
                        to={LetterRoutes.HOME}
                    />

                    {POSTCARDS_ENABLED && (
                        <ListItemLink
                            primary="Postcards"
                            icon={PostcardIcon}
                            to={PostcardRoutes.HOME}
                        />
                    )}

                    <ListItemLink
                        primary="Cheques"
                        icon={FileIcon}
                        to={ChequeRoutes.HOME}
                    />

                    <ListItem>
                        <Switch
                            checked={live}
                            onChange={(v) => {
                                setLive(v.target.checked);
                            }}
                            color="primary"
                        />
                        <ListItemText>Live Mode</ListItemText>
                    </ListItem>

                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Settings
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Settings"
                        icon={SettingsIcon}
                        to="/dashboard/settings"
                    />

                    <ListItemLink
                        primary="Users"
                        icon={UserIcon}
                        to="/dashboard/users"
                    />

                    <ListItemLink
                        primary="Webhooks"
                        icon={WebhookIcon}
                        to="/dashboard/webhooks"
                    />

                    <ListItemLink
                        primary="API Logs"
                        icon={APILogIcon}
                        to={APILogRoutes.HOME}
                    />

                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Billing
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Payment"
                        icon={PaymentIcon}
                        to="/dashboard/payment"
                    />

                    <ListItemLink
                        primary="Invoices"
                        icon={InvoiceIcon}
                        to="/dashboard/invoices"
                    />

                    <ListItemLink
                        primary="Upgrade"
                        icon={UpgradeIcon}
                        to="/dashboard/upgrade"
                    />
                </List>
            </Paper>
        </ThemeProvider>
    );
};

export default Sidenav;
