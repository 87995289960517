import React, { PropsWithoutRef, ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from './Button';

export type ConfirmActionDialogProps = PropsWithoutRef<{
    open: boolean;
    onClose: () => void;
    title: string;
    text: ReactNode;
    actionLabel: string;
    cancelLabel?: string;
    confirm: () => void;
    image?: string;
}>;

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        borderRadius: '6px',
        '&>.MuiDialog-scrollPaper': {
            '&>.MuiDialog-paper': {
                borderRadius: '8px',
                padding: '20px 70px',
            },
        },
    },
}));

const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({
    actionLabel,
    confirm,
    onClose,
    open,
    text,
    title,
    cancelLabel,
    image,
}) => {
    const classes = useStyles();

    const handleConfirm = () => {
        confirm();
        handleClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            classes={classes}
            open={open}
            onClose={handleClose}
            data-testid="confirm-action-dialog"
        >
            <DialogContent>
                {image && (
                    <img
                        src={image}
                        alt={title}
                        style={{ margin: '10px auto', display: 'block' }}
                    />
                )}
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                            size="large"
                            fullWidth
                            data-testid="cancel-action"
                        >
                            {cancelLabel || 'Cancel'}
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            size="large"
                            fullWidth
                            style={{ padding: '8px 18px' }}
                            data-testid="confirm-action"
                        >
                            {actionLabel}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmActionDialog;
