import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import BaseEnumSelect, { BaseEnumSelectProps } from './BaseEnumSelect';

type EnumSelectProps<T> = Omit<BaseEnumSelectProps<T>, 'onChange' | 'value'> & {
    onChange: (value: T | '') => void;
    undefinedLabel?: string;
    value: T | '';
};
const UNSELECTED_VALUE = '';

const EnumSelect = <T extends string>({
    onChange,
    undefinedLabel,
    ...props
}: EnumSelectProps<T>) => {
    const handleChange = (e: T | '') => {
        onChange(e);
    };

    return (
        <BaseEnumSelect onChange={handleChange} {...props}>
            {undefinedLabel && (
                <MenuItem
                    value={UNSELECTED_VALUE}
                    style={{
                        fontStyle: 'italic',
                    }}
                >
                    {undefinedLabel}
                </MenuItem>
            )}
        </BaseEnumSelect>
    );
};

export default EnumSelect;
