import React, { ElementType, PropsWithoutRef } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import { Contact } from '../services/Contacts';

import HeaderIcon from './HeaderIcon';

type DisplayContact = Omit<
    Contact,
    'addressStatus' | 'id' | 'live' | 'metadata' | 'createdAt' | 'updatedAt'
> & {
    id?: string;
};

const addressLines = (contact: DisplayContact) => {
    const lines = [];

    if (contact.firstName) {
        lines.push(
            `${contact.firstName}${
                contact.lastName ? ' ' + contact.lastName : ''
            }`.toLocaleUpperCase()
        );
    } else {
        lines.push(contact.companyName?.toLocaleUpperCase() || 'THE OCCUPANT');
    }

    lines.push(contact.addressLine1);

    if (contact.addressLine2) {
        lines.push(contact.addressLine2);
    }

    if (contact.city || contact.provinceOrState || contact.postalOrZip) {
        lines.push(
            `${contact.city || ''} ${contact.provinceOrState || ''} ${
                contact.postalOrZip || ''
            }`.trim()
        );
    }

    if (contact.country) {
        lines.push(contact.country);
    }

    return lines;
};

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '0px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    rounded: {
        borderRadius: 10,
    },
}));

const AddressPreview = (
    props: PropsWithoutRef<{
        title?: string;
        link?: boolean;
        contact?: DisplayContact;
        icon?: ElementType;
    }>
) => {
    const classes = useStyles();

    return (
        <Paper
            variant="outlined"
            classes={{ rounded: props.title ? classes.rounded : undefined }}
            style={!props.contact ? { height: '100%' } : undefined}
            data-testid="address-preview"
        >
            {props.title ? (
                <>
                    <Box p={2} display="flex">
                        {props.icon ? (
                            <span
                                style={{ marginTop: '4px', maxHeight: '25px' }}
                            >
                                {' '}
                                <HeaderIcon icon={props.icon} />{' '}
                            </span>
                        ) : null}
                        <Typography variant="h6">
                            {props.title && <>{props.title}</>}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            ) : null}
            <Box p={2.5}>
                {props.contact ? (
                    <>
                        <Box>
                            {addressLines(props.contact).map((line, index) => {
                                return (
                                    <Box key={index}>
                                        <Typography
                                            color={
                                                index === 0
                                                    ? 'textPrimary'
                                                    : 'textSecondary'
                                            }
                                        >
                                            {line}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                        {props.link && props.contact.id && (
                            <Box mt={1}>
                                <Link
                                    variant="body1"
                                    component={RouterLink}
                                    to={`/dashboard/contacts/${props.contact.id}`}
                                >
                                    View Details
                                </Link>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box>
                        <Typography>No Address Specified.</Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default AddressPreview;
