import React from 'react';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Folder from '@material-ui/icons/Folder';

import HeaderIcon from './HeaderIcon';
import ObjectTable from './ObjectTable';

export const PREFIX_TO_OBJECT = {
    contact_: 'contact',
    template_: 'template',
    letter_: 'letter',
    postcard_: 'postcard',
    bank_: 'bank_account',
    cheque_: 'cheque',
    api_log_: 'api_log',
    webhook_: 'webhook',
};

const RawData = (props: { obj: any }) => {
    return (
        <Accordion
            variant="outlined"
            style={{ borderRadius: 10 }}
            data-testid="raw-data"
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <HeaderIcon icon={Folder} />
                <Typography variant="h6">Raw Data</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{
                    padding: '20px 20px 30px',
                    borderTop: '1px solid #EFEFEF',
                }}
            >
                <ObjectTable obj={props.obj} />
            </AccordionDetails>
        </Accordion>
    );
};

export default RawData;
