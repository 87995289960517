import React, { PropsWithoutRef } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import TablePagination from '@material-ui/core/TablePagination';

export type ResetTablePaginationProps = {
    page: number;
    setPage: (value: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (value: number) => void;
    count: number;
    wrapped?: boolean;
};

type StyleProps = { wrapped?: boolean };

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
        // When the component is wrapped in a paper element
        // hide the bottom border so you don't get the double border from
        // the wrapping component and the table
        borderBottom: ({ wrapped }) => (wrapped ? 'none' : 'inherit'),
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
    },
    spacer: {
        flex: 'initial',
    },
    caption: {
        fontFamily: 'Roboto',
    },
}));

// Small wrapper over TablePagination that resets
// the page when rows per page is changed.
const ResetTablePagination = (
    props: PropsWithoutRef<ResetTablePaginationProps>
) => {
    const classes = useStyles({ wrapped: props.wrapped });

    return (
        <TablePagination
            data-testid="table-pagination"
            rowsPerPage={props.rowsPerPage}
            onChangeRowsPerPage={(e) => {
                const newRowsPerPage = parseInt(e.target.value);

                props.setPage(
                    Math.floor(
                        (props.rowsPerPage * props.page) / newRowsPerPage
                    )
                );
                props.setRowsPerPage(newRowsPerPage);
            }}
            page={props.page}
            onChangePage={(e, page) => {
                props.setPage(page);
            }}
            count={props.count}
            classes={classes}
            backIconButtonText="Previous"
            nextIconButtonText="Next"
        />
    );
};

export default ResetTablePagination;
