import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Contact } from '../services/Contacts';

type StatusTile = {
    color: string;
    icon: ReactNode;
    title: string;
};

const statusColorIconTitle: Record<string, StatusTile> = {
    verified: {
        color: 'success.main',
        icon: <DoneIcon data-testid="verified" />,
        title: 'Verified',
    },

    corrected: {
        color: 'warning.main',
        icon: <DoneIcon data-testid="corrected" />,
        title: 'Corrected',
    },

    failed: {
        color: 'error.main',
        icon: <CloseIcon data-testid="failed" />,
        title: 'Failed',
    },
};

const AddressStatusIndicator = (props: {
    status: Contact['addressStatus'];
}) => {
    return (
        <Tooltip title={statusColorIconTitle[props.status].title}>
            <Box color={statusColorIconTitle[props.status].color}>
                {statusColorIconTitle[props.status].icon}
            </Box>
        </Tooltip>
    );
};

export default AddressStatusIndicator;
