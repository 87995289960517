import { useMemo } from 'react';

import { User } from '../context/Auth';

import { Service as BaseService, useService as useBaseService } from './Base';

interface CreateParams {
    name: string;
    email: string;
}

interface CompleteParams {
    name: string;
    password: string;
    phoneNumber?: string;
}

export class Service {
    service: BaseService;

    constructor(service: BaseService) {
        this.service = service;
    }

    async create(params: CreateParams) {
        return await this.service.fetchAPI<User>('/invites', {
            method: 'POST',
            body: params,
        });
    }

    async get(id: string) {
        return await this.service.fetchAPI<User>(`/invites/${id}`);
    }

    async complete(id: string, params: CompleteParams) {
        return await this.service.fetchAPI<User>(`/invites/${id}`, {
            method: 'POST',
            body: params,
        });
    }

    async delete(id: string) {
        return await this.service.fetchAPI<User>(`/invites/${id}`, {
            method: 'DELETE',
        });
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
