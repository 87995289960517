import React, { createContext, useContext, useState, useCallback } from 'react';
import { OrderMailingClass } from '../services/Base';
import { Contact } from '../services/Contacts';
import { PostcardSize } from '../services/Postcards';
import { Template } from '../services/Templates';

interface State {
    description: string;
    setDescription: (s: string) => void;
    toContacts: Contact[];
    setToContacts: (c: Contact[]) => void;
    fromContact: Contact | null;
    setFromContact: (c: Contact | null) => void;
    frontTemplate: Template | null;
    setFrontTemplate: (t: Template | null) => void;
    backTemplate: Template | null;
    setBackTemplate: (t: Template | null) => void;
    file: File | null;
    setFile: (f: File | null) => void;
    mergeVars: Record<string, string>;
    setMergeVars: (v: Record<string, string>) => void;
    uploadedCSV: boolean;
    setUploadedCSV: (f: boolean) => void;
    mailingClass: OrderMailingClass;
    setMailingClass: (m: OrderMailingClass) => void;
    size: PostcardSize;
    setSize: (s: PostcardSize) => void;
    express: boolean;
    setExpress: (f: boolean) => void;
    loading: boolean;
    setLoading: (f: boolean) => void;
    resetState: () => void;
}

const Context = createContext<State | undefined>(undefined);

export const CreatePostcardProvider: React.FC = ({ children }) => {
    const [description, setDescription] = useState<string>('');
    const [toContacts, setToContacts] = useState<Contact[]>([]);
    const [fromContact, setFromContact] = useState<Contact | null>(null);
    const [mailingClass, setMailingClass] = useState<OrderMailingClass>(
        OrderMailingClass.FIRST_CLASS
    );
    const [size, setSize] = useState<PostcardSize>(PostcardSize.SIZE_6X4);
    const [frontTemplate, setFrontTemplate] = useState<Template | null>(null);
    const [backTemplate, setBackTemplate] = useState<Template | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [express, setExpress] = useState<boolean>(false);
    const [mergeVars, setMergeVars] = useState<Record<string, string>>({});
    const [uploadedCSV, setUploadedCSV] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const resetState = useCallback(() => {
        setDescription('');
        setToContacts([]);
        setFromContact(null);
        setMailingClass(OrderMailingClass.FIRST_CLASS);
        setSize(PostcardSize.SIZE_6X4);
        setFrontTemplate(null);
        setBackTemplate(null);
        setFile(null);
        setExpress(false);
        setMergeVars({});
        setUploadedCSV(false);
        setLoading(false);
    }, []);

    const value = {
        description,
        setDescription,
        toContacts,
        setToContacts,
        fromContact,
        setFromContact,
        mailingClass,
        setMailingClass,
        frontTemplate,
        setFrontTemplate,
        backTemplate,
        setBackTemplate,
        file,
        setFile,
        size,
        setSize,
        express,
        setExpress,
        mergeVars,
        setMergeVars,
        uploadedCSV,
        setUploadedCSV,
        loading,
        setLoading,
        resetState,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCreatePostcard = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'useCreatePostcard can only be used within a CreatePostcardProvider'
        );
    }
    return context;
};
