import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useReFetchContext } from '../context/ReFetchContext';
import { usePagination } from '../hooks/usePagination';
import { ReturnEnvelope, useService } from '../services/ReturnEnvelopes';
import { formatTableDate, ListParams } from '../services/util';
import TableDisplay from './TableDisplay';
import OrderStatusCell from './ReturnEnvelopeOrderStatusCell';
import { useHistory } from 'react-router-dom';
import { ReturnEnvelopeRoutes } from '../routes';

interface ReturnEnvelopeOrdersProps {
    envelope: ReturnEnvelope;
}

const ReturnEnvelopeOrders: React.FC<ReturnEnvelopeOrdersProps> = ({
    envelope,
}) => {
    const history = useHistory();
    const service = useService();
    const { reFetch } = useReFetchContext();

    const fetchOrders = useCallback(
        (params: ListParams) => {
            return service.getOrders(params, envelope.id);
        },
        [service, envelope]
    );

    const dependencies = useMemo(() => [reFetch], [reFetch]);

    const { loading, data, pagination } = usePagination(
        fetchOrders,
        dependencies
    );

    const handleRowClick = (id: string) => {
        history.push(
            `${ReturnEnvelopeRoutes.HOME}/${envelope.id}/orders/${id}`
        );
    };

    return (
        <Grid
            item
            container
            direction="column"
            spacing={1}
            data-testid="envelope-orders"
        >
            <Grid item>
                <Typography variant="h5">Orders</Typography>
            </Grid>
            <Grid item>
                <TableDisplay
                    columns={[
                        'ID',
                        'Description',
                        'Quantity Ordered',
                        'Quantity Filled',
                        'Status',
                        'Created At',
                    ]}
                    show={loading}
                    showEmptyTable
                    pagination={pagination}
                    wrapped
                >
                    {data.map((order) => {
                        return (
                            <TableRow
                                key={order.id}
                                hover
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleRowClick(order.id)}
                            >
                                <TableCell>{order.id}</TableCell>
                                <TableCell>{order.description}</TableCell>
                                <TableCell data-testid="quantity-ordered">
                                    {order.quantityOrdered}
                                </TableCell>
                                <TableCell data-testid="quantity-filled">
                                    {order.quantityFilled || 0}
                                </TableCell>
                                <OrderStatusCell status={order.status} />
                                <TableCell>
                                    {formatTableDate(order.createdAt)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableDisplay>
            </Grid>
        </Grid>
    );
};

export default ReturnEnvelopeOrders;
