import React, { useContext } from 'react';
import { Context } from '../context/Auth';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import GridContainerLoader from './GridContainerLoader';
import APIKey from './APIKey';

interface APIKeysProps {
    loading: boolean;
}

const APIKeys: React.FC<APIKeysProps> = ({ loading }) => {
    const { state } = useContext(Context);
    
    const getKey = (prefix: string) =>
        state.user?.apiKeys.find((k) => k.value.startsWith(prefix))!.value;

    const testKey = getKey('test_');

    const liveKey = getKey('live_');

    return (
        <Grid>
            <GridContainerLoader
                direction="column"
                spacing={2}
                show={loading}
                items={4}
            >
                <Grid item>
                    <Typography variant="h6">API Keys</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <APIKey title="Test" value={testKey} />
                        <APIKey title="Live" value={liveKey} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Alert variant="outlined" color="warning">
                        <Typography>
                            The live API key can be used to create <em>real</em>{' '}
                            orders, so be careful with it.
                        </Typography>
                    </Alert>
                </Grid>
            </GridContainerLoader>
        </Grid>
    );
};

export default APIKeys;
