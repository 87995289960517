import React, { PropsWithoutRef } from 'react';

import { useHistory } from 'react-router-dom';

import ConfirmActionDialog from './ConfirmActionDialog';

const MissingPaymentMethodDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        onClose: () => void;
        text: string;
    }>
) => {
    const history = useHistory();

    const confirm = () => {
        history.push('/dashboard/payment/create');
    };

    return (
        <ConfirmActionDialog
            title="Missing Payment Method"
            actionLabel="Add Payment Method"
            confirm={confirm}
            {...props}
        />
    );
};

export default MissingPaymentMethodDialog;
