import React from 'react';
import { useHistory } from 'react-router-dom';

import Button, { ButtonProps } from './Button';

const GoBackButton = (props: ButtonProps & { onClick?: () => void }) => {
    const history = useHistory();

    return (
        <Button
            {...props}
            onClick={(e) => {
                history.goBack();
                if (props.onClick) {
                    props.onClick();
                }
            }}
        />
    );
};

export default GoBackButton;
