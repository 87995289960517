import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

type TooltipWrapperProps = TooltipProps & {
    disabled?: boolean;
};

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
    children,
    disabled,
    ...props
}) => {
    if (disabled) {
        return <Tooltip {...props}>{children}</Tooltip>;
    } else return <>{children}</>;
};

export default TooltipWrapper;
