import React, { useCallback, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import TopNav from '../components/TopNav';
import { usePagination } from '../hooks/usePagination';
import { useService } from '../services/APILogs';
import { formatTableDate, ListParams } from '../services/util';
import TableRowLink from '../components/TableRowLink';
import { APILogRoutes } from '../routes';
import StatusCodeCell from '../components/StatusCodeCell';
import { Route, Switch } from 'react-router-dom';
import ViewAPILog from './ViewAPILog';
import DeleteButton from '../components/DeleteButton';
import { Context } from '../context/Notification';
import { useModal } from '../hooks/useModal';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';

const APILogs: React.FC = () => {
    const service = useService();
    const [deleting, setDeleting] = useState(false);
    const { dispatchSuccess } = useContext(Context);
    const { isModalOpen, toggleModal } = useModal();

    const fetch = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const { data, pagination, loading, searchQuery, searchText, setData } =
        usePagination(fetch);

    const handleDelete = async () => {
        setDeleting(true);
        try {
            const { deletedCount } = await service.deleteAll();
            // empty the logs
            setData([]);
            dispatchSuccess(
                `Deleted ${deletedCount} ${
                    deletedCount > 1 ? 'API logs' : 'API log'
                }`
            );
        } catch (err) {
            console.error(err);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Switch>
            <Route exact path={APILogRoutes.HOME}>
                <ConfirmDeleteDialog
                    confirm={handleDelete}
                    onClose={toggleModal}
                    open={isModalOpen}
                    title="Delete API Logs"
                    text={
                        <span>
                            Are you sure you want to delete <strong>ALL</strong>{' '}
                            of your API logs?
                        </span>
                    }
                />
                <TopNav
                    showSearch
                    searchQuery={searchQuery}
                    searchText={searchText}
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader
                        title="API Logs"
                        alertText="You are in test mode.  You will only see API logs for calls made in test mode."
                    >
                        <Grid item>
                            <DeleteButton
                                onClick={toggleModal}
                                disabled={deleting || data.length === 0}
                            >
                                Delete All
                            </DeleteButton>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            pagination={pagination}
                            columns={[
                                'Status Code',
                                'HTTP Method',
                                'Response Time (MS)',
                                'Path',
                                'Created At',
                            ]}
                            show={loading || deleting}
                            noDataMessage="There are no API logs to be shown"
                        >
                            {data.map((log) => {
                                return (
                                    <TableRowLink
                                        key={log.id}
                                        route={`${APILogRoutes.HOME}/${log.id}`}
                                    >
                                        <StatusCodeCell
                                            code={log.responseStatusCode}
                                        />
                                        <TableCell>{log.httpMethod}</TableCell>
                                        <TableCell>
                                            {log.responseTimeMS}
                                        </TableCell>
                                        <TableCell>{log.path}</TableCell>
                                        <TableCell>
                                            {formatTableDate(log.createdAt)}
                                        </TableCell>
                                    </TableRowLink>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={APILogRoutes.VIEW} component={ViewAPILog} />
        </Switch>
    );
};

export default APILogs;
