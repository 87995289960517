import React, { ElementType, useMemo, forwardRef } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRight: '2px solid transparent',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    selected: {
        backgroundColor: 'transparent !important',
        borderRightColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
}));

const useIconStyles = makeStyles((theme) => ({
    root: {
        fontSize: '24px',
        margin: '0 auto',
    },
}));

const ListItemLink = (props: {
    icon: ElementType;
    primary?: string;
    to: string;
    topNav?: boolean;
}) => {
    const { pathname } = useLocation();

    const classes = useStyles();
    const iconClass = useIconStyles();

    const { primary, to, topNav } = props;

    const CustomLink = useMemo(
        () =>
            forwardRef((linkProps, ref) => (
                <Link ref={ref as any} to={to} {...linkProps} />
            )),
        [to]
    );

    const selected = topNav
        ? false
        : to === '/dashboard'
        ? pathname === to
        : pathname.startsWith(to);

    return (
        <ListItem
            button
            disableRipple
            selected={selected}
            component={CustomLink}
            classes={classes}
            style={{
                padding: topNav ? '10px 28px' : '0 16px 0 28px',
                margin: topNav ? '0' : '2px 0',
            }}
        >
            <ListItemIcon style={{ marginBottom: topNav ? '-5px' : '-10px' }}>
                <SvgIcon
                    classes={iconClass}
                    style={{ margin: topNav ? '0' : '0 auto' }}
                    color={selected ? 'primary' : 'inherit'}
                >
                    <props.icon
                        secondColor={selected ? '#A2C8FF' : 'inherit'}
                        color={topNav ? '#575e71' : null}
                    />
                </SvgIcon>
            </ListItemIcon>
            <ListItemText
                primary={<Typography variant="body2" style={{
                    // TODO Refactor
                    fontFamily: topNav ? 'Poppins' : undefined
                }}>{primary}</Typography>}
            />
        </ListItem>
    );
};

export default ListItemLink;
