import { useState } from 'react';

export const useModal = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const toggleModal = () => {
        setIsModalOpen((prev) => !prev);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    return { isModalOpen, toggleModal, openModal, closeModal, setIsModalOpen };
};
