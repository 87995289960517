import { useLocation } from 'react-router-dom';
import logo from '../logo.png';
import loginGraphic from '../img/login-image.png';

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Maintenance = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const message = queryParams.get('msg') || '';

    return (
        <>
            <Grid
                container
                alignItems="center"
                style={{ minHeight: '100vh', backgroundColor: 'white' }}
            >
                <Grid item xs={12} sm={6} style={{ height: '100vh' }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <img
                            src={loginGraphic}
                            alt="Login Graphic"
                            style={{
                                display: 'block',
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Typography variant="h4">
                                    PostGrid is Under Maintenance
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography>{message}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Maintenance;
