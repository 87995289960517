import React, {
    PropsWithoutRef,
    useState,
    useEffect,
    useContext,
    ReactNode,
} from 'react';

import { useHistory } from 'react-router-dom';

import { useModal } from '../hooks/useModal';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';

import CheckCircle from '@material-ui/icons/CheckCircle';

import {
    useService as useBillingService,
    Price,
    PaymentMethod,
} from '../services/Billing';

import { useOrganization } from '../services/Organization';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import MissingPaymentMethodDialog from '../components/MissingPaymentMethodDialog';
import Button, { ButtonProps } from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import upgradeGraphic from '../img/upgrade-graphic.png';

const amountString = (price: Price) => {
    let divisor = 100;

    if (price.recurring.interval === 'year') {
        divisor *= 12;
    }

    return (price.unit_amount / divisor).toLocaleString('en-US', {
        style: 'currency',
        currency: price.currency,
    });
};

const PriceCard = (
    props: PropsWithoutRef<{
        price?: Price;
        onCheckout?: ButtonProps['onClick'];
        isCurrentPrice?: boolean;
    }>
) => {
    // If no price is supplied, we treat this as an enterprise price card
    const { price, onCheckout, isCurrentPrice } = props;

    const Feature = ({ label }: { label: string }) => {
        return (
            <ListItem disableGutters>
                <ListItemIcon>
                    <CheckCircle
                        style={{
                            color: '#407BFF',
                        }}
                    />
                </ListItemIcon>
                <ListItemText style={{ textTransform: 'capitalize' }}>
                    {label}
                </ListItemText>
            </ListItem>
        );
    };

    return (
        <GridPaper
            style={{
                border: `1px solid ${isCurrentPrice ? '#407bff' : '#e3e3e3'}`,
                borderRadius: '16px',
                padding: '48px 32px',
                width: 338,
                boxShadow: 'none',
                minHeight: isCurrentPrice ? 592 : 554,
                background: isCurrentPrice ? '#f9fbff' : 'none',
            }}
            direction="column"
        >
            <Grid item>
                <Typography
                    style={{
                        fontSize: '29px',
                    }}
                >
                    {price
                        ? price.nickname.replace(/\(.*\)/, '')
                        : 'Custom Plan'}
                </Typography>
            </Grid>
            <Grid item>
                <Box mt={1} />
            </Grid>
            <Grid item>
                {price ? (
                    <>
                        <Typography
                            style={{
                                fontSize: '42px',
                            }}
                            display="inline"
                        >
                            {amountString(price)}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '25px',
                            }}
                            display="inline"
                        >
                            /month
                        </Typography>
                    </>
                ) : (
                    <Typography style={{ fontSize: '42px' }}>
                        Custom Price
                    </Typography>
                )}
            </Grid>

            <Grid item>
                <Box mt={3} />
            </Grid>

            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        price
                            ? onCheckout
                            : () => {
                                  window.open(
                                      'https://www.postgrid.com/talk-to-sales/',
                                      '_blank'
                                  );
                              }
                    }
                    size="large"
                    disabled={isCurrentPrice}
                >
                    {price ? 'Checkout' : 'Talk to Sales'}
                </Button>
            </Grid>

            <Grid item>
                <Box mt={5} mb={3}>
                    <Divider />
                </Box>
            </Grid>

            <Grid item>
                <Typography style={{ fontSize: '19px' }}>Features</Typography>
            </Grid>

            <Grid item>
                <List dense>
                    <Feature
                        label={
                            price
                                ? `${parseInt(
                                      price.metadata.monthly_limit
                                  ).toLocaleString()} Monthly Mailings`
                                : 'Flexible Mailing Limits'
                        }
                    />
                    <Feature
                        label={
                            price
                                ? price.metadata.sla
                                    ? price.metadata.sla
                                    : '2 Day SLA'
                                : 'Same Day SLA'
                        }
                    />
                    <Feature
                        label={`${
                            price ? price.metadata.pdn_type : 'Priority'
                        } Print Delivery Network`}
                    />
                    {!price && <Feature label="Dedicated Support" />}
                </List>
            </Grid>
        </GridPaper>
    );
};

const EnterprisePriceCard = () => {
    return (
        <Card
            variant="outlined"
            style={{ padding: '5px 15px', borderRadius: '10px' }}
        >
            <Grid container direction="column" justify="space-between">
                <Grid item>
                    <CardContent>
                        <Grid container justify="space-between">
                            <Grid item xs={7}>
                                <Typography variant="h5">
                                    Custom Plan
                                </Typography>

                                <Typography variant="subtitle1">
                                    Custom Price
                                </Typography>
                                <Box my={1}></Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        window.open(
                                            'https://www.postgrid.com/talk-to-sales/',
                                            '_blank'
                                        );
                                    }}
                                    size="large"
                                >
                                    Talk to Sales
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <img
                                    src={upgradeGraphic}
                                    alt="Upgrade graphic"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item></Grid>
            </Grid>
        </Card>
    );
};

const CheckoutConfirmationDialog = (
    // We cannot use price being undefined as the open state because
    // then when we close the dialog, the price might be undefined for
    // some time, resulting in a weird dialog being rendered for some frames.
    props: PropsWithoutRef<{
        methods: PaymentMethod[];
        price?: Price;
        open: boolean;
        onClose: (e: {}) => void;
        onConfirm: (method: PaymentMethod) => void;
    }>
) => {
    const [method, setMethod] = useState<PaymentMethod>();

    useEffect(() => {
        if (props.methods.length > 0) {
            setMethod(props.methods[0]);
        }
    }, [props.methods]);

    const findMethod = (id: string) => {
        return props.methods.find((m) => m.id === id) as PaymentMethod;
    };

    const methodLabel = (method: PaymentMethod) => {
        return `Card ending in ${method.card.last4}`;
    };

    const TextOrSelect = (p: {}) => {
        if (props.methods.length === 1) {
            return (
                <DialogContentText>
                    {`We will charge the card ending in ${props.methods[0].card.last4}.`}
                </DialogContentText>
            );
        }

        return (
            <Select
                variant="outlined"
                fullWidth
                value={method ? method.id : ''}
                onChange={(e: any, child: ReactNode) => {
                    setMethod(findMethod(e.target.value));
                }}
                renderValue={(v: any) => {
                    if (!v) {
                        return 'Select a Payment Method';
                    }

                    return (
                        <Typography>{methodLabel(findMethod(v))}</Typography>
                    );
                }}
                displayEmpty
                required
            >
                {props.methods.map((method) => (
                    <MenuItem key={method.id} value={method.id}>
                        {methodLabel(method)}
                    </MenuItem>
                ))}
            </Select>
        );
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.onConfirm(method as PaymentMethod);
                }}
            >
                <DialogTitle>Confirm Subscription</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are subscribing to the {props.price?.nickname} plan
                        which is {props.price ? amountString(props.price) : ''}.
                    </DialogContentText>
                    <TextOrSelect />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                            props.onClose(e);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const Upgrade = () => {
    const history = useHistory();

    const service = useBillingService();

    const org = useOrganization([history.location]);

    const { dispatch } = useContext(NotificationContext);

    const [methods, setMethods] = useState<PaymentMethod[]>([]);
    const [prices, setPrices] = useState<Price[]>([]);
    const [currentPriceID, setCurrentPriceID] = useState<string>();
    const [interval, setInterval] =
        useState<Price['recurring']['interval']>('month');

    const {
        isModalOpen: missingPaymentDialogOpen,
        toggleModal: toggleMissingPaymentDialog,
    } = useModal();

    const [checkoutPrice, setCheckoutPrice] = useState<Price>();
    const {
        isModalOpen: checkoutOpen,
        toggleModal: toggleCheckoutModal,
        closeModal: closeCheckoutModal,
    } = useModal();

    const [loading, setLoading] = useState(true);

    const checkout = (price: Price) => {
        if (methods.length === 0) {
            toggleMissingPaymentDialog();
            return;
        }

        setCheckoutPrice(price);
        toggleCheckoutModal();
    };

    const confirm = (method: PaymentMethod) => {
        // Close immediately to prevent double confirmation
        closeCheckoutModal();

        (async () => {
            try {
                setLoading(true);

                const sub = await service.createOrUpdateSubscription({
                    stripeSubscriptionPaymentMethod: method.id,
                    stripePrice: checkoutPrice!.id,
                });

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Successfully subscribed.',
                });

                setCurrentPriceID(sub.plan.id);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    };

    useEffect(() => {
        setCurrentPriceID(org?.stripeSubscriptionPrice);
    }, [org]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const [prices, methods, sub] = await Promise.all([
                    service.listPrices(),
                    service.listPaymentMethods(),
                    service.tryGetSubscription(),
                ]);

                prices.data = prices.data.sort(
                    (a, b) => a.unit_amount - b.unit_amount
                );

                setPrices(prices.data);
                setMethods(methods.data);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [service, history.location]);

    const Prices = (props: { prices: Price[] }) => {
        return (
            <>
                {props.prices.map((price) => (
                    <Grid item key={price.id}>
                        <PriceCard
                            price={price}
                            isCurrentPrice={price.id === currentPriceID}
                            onCheckout={() => checkout(price)}
                        />
                    </Grid>
                ))}
                <Grid item>
                    <PriceCard />
                </Grid>
            </>
        );
    };

    return (
        <>
            <MissingPaymentMethodDialog
                open={missingPaymentDialogOpen}
                onClose={toggleMissingPaymentDialog}
                text="You need to attach a payment method to subscribe."
            />

            <CheckoutConfirmationDialog
                methods={methods}
                price={checkoutPrice}
                open={checkoutOpen}
                onClose={toggleCheckoutModal}
                onConfirm={(method: PaymentMethod) => {
                    confirm(method);
                }}
            />
            <TopNav />

            <GridPaper container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Upgrade</Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        alignItems="center"
                        direction="column"
                        spacing={2}
                    >
                        {!loading && (
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography>Monthly</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            color="primary"
                                            checked={interval === 'year'}
                                            onChange={(e) =>
                                                setInterval(
                                                    e.target.checked
                                                        ? 'year'
                                                        : 'month'
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>Yearly</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                {loading ? (
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            style={{ minHeight: '30vh' }}
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        <Prices
                                            prices={prices.filter(
                                                (p) =>
                                                    p.recurring &&
                                                    p.recurring.interval ===
                                                        interval
                                            )}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default Upgrade;
