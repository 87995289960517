import React from 'react';

import { Theme } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/styles';

interface StatusCodeCellProps {
    code?: number;
    div?: boolean;
}

const useStyles = makeStyles<Theme, StatusCodeCellProps>((theme) => ({
    root: {
        margin: ({ div }) => (div ? 'inherit' : theme.spacing(2)),
    },
    bubble: {
        color: ({ code }) => getStatusCodeColor(theme, code),
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        backgroundColor: ({ code }) => getStatusBackgroundColor(code),
        borderRadius: 5,
        width: '100%',
    },
}));

const getStatusCodeColor = (theme: Theme, code?: number) => {
    if (!code) return theme.palette.text.primary;
    if (code >= 200 && code < 300) return theme.palette.success.main;
    if (code >= 400 && code < 600) return theme.palette.error.main;
    return theme.palette.text.primary;
};

// HACK: Opacity from palette colors is not in v 4.11 of Mui
// When updated, implement alpha based on success and error main colors
const getStatusBackgroundColor = (code?: number) => {
    if (!code) return 'transparent';
    if (code >= 200 && code < 300) return '#e3ffe4';
    if (code >= 400 && code < 600) return '#ffdedb';
    return 'transparent';
};

const StatusCodeCell: React.FC<StatusCodeCellProps> = ({ code, div }) => {
    const classes = useStyles({ code, div });
    if (div)
        return (
            <div className={classes.root}>
                <span className={classes.bubble}>{code}</span>
            </div>
        );
    return (
        <TableCell className={classes.root}>
            <span className={classes.bubble}>{code}</span>
        </TableCell>
    );
};

export default StatusCodeCell;
