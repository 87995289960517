import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
    default as MUIButton,
    ButtonProps as MUIButtonProps,
} from '@material-ui/core/Button';

export type ButtonProps = MUIButtonProps;

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '5px',
        outline: 'none',
        boxShadow: 'none',
    },
    label: {
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: theme.typography.body1.fontFamily,
    },
}));

const Button = (props: MUIButtonProps) => {
    const classes = useStyles();
    return (
        <MUIButton
            variant={props.variant ? props.variant : 'contained'}
            color={props.color ? props.color : 'primary'}
            {...props}
            classes={{
                root: classes.root,
                label: classes.label,
            }}
        />
    );
};

export default Button;
