import React, { ReactElement } from 'react';
import { useBulkCancelOrdersContext } from '../context/BulkCancelOrders';
import { OrderStatus } from './OrderStatusCell';
import { OrderStatus as Status } from '../services/Orders';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Button from './Button';
import BulkCancelOrdersAccordion from './BulkCancelOrdersAccordion';
import DeleteButton from './DeleteButton';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import DateRangeSelector from './DateRangeSelector';

export interface BulkCancelOrdersDialogProps {
    open: boolean;
    onClose: () => void;
}

const BoxGrid: React.FC = ({ children }) => {
    return (
        <Box style={{ gridRowStart: 1, gridColumnStart: 1 }}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width="100%"
                height="100%"
            >
                {children}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr',
    },
}));

const BulkCancelOrdersDialog = ({
    onClose,
    open,
}: BulkCancelOrdersDialogProps): ReactElement => {
    const classes = useStyles();
    const {
        resetState,
        searchText,
        handleSearchText,
        count,
        cancelling,
        cancellingProgress,
        didCancel,
        object,
        cancellable,
        openModal,
        handleDateChange,
    } = useBulkCancelOrdersContext();

    const showCancelled = cancelling || didCancel;
    const showAccordion = !showCancelled;

    const handleClose = () => {
        if (cancelling) {
            return;
        }
        resetState();
        onClose();
    };

    const handleText = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        handleSearchText(e);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flex={1}>
                    <DialogTitle style={{ textTransform: 'capitalize' }}>
                        Cancel {object}s
                    </DialogTitle>
                </Box>
                <Box display="flex" flex={1}>
                    <DialogContent>
                        <DialogContentText component="div">
                            Set a search criteria to find {object}s. All
                            selected {object}s found will be cancelled. Please
                            note that only {object}s with a status of{' '}
                            <OrderStatus status={Status.READY} /> are eligible
                            to be cancelled.
                        </DialogContentText>
                    </DialogContent>
                </Box>
                <Box display="flex" flex={1}>
                    <DialogContent>
                        <Box
                            display="grid"
                            gridTemplateColumns="2fr 1fr"
                            gridGap="3rem"
                        >
                            <Box display="grid" gridColumn="1 / span 2">
                                <TextField
                                    variant="outlined"
                                    placeholder={`Search for ${object}s`}
                                    value={searchText}
                                    onChange={handleText}
                                    disabled={cancelling}
                                    fullWidth
                                    inputProps={{
                                        'data-testid':
                                            'cancel-orders-textfield',
                                    }}
                                />
                            </Box>

                            <Box display="grid" gridColumn="3">
                                <DateRangeSelector
                                    onChange={handleDateChange}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
                <Box
                    flexGrow={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    position="relative"
                >
                    <Box className={classes.grid}>
                        <BoxGrid>
                            <Grow in={showCancelled}>
                                <DialogContent
                                    className={classes.container}
                                    data-testid="cancel-orders-cancelled"
                                >
                                    <Paper variant="outlined">
                                        <Box p={2}>
                                            <LinearProgressWithLabel
                                                variant="determinate"
                                                value={cancellingProgress}
                                            />
                                        </Box>
                                    </Paper>
                                </DialogContent>
                            </Grow>
                        </BoxGrid>

                        <BoxGrid>
                            <Grow in={showAccordion}>
                                <div
                                    className={classes.container}
                                    data-testid="cancel-orders-accordion"
                                >
                                    <DialogContent>
                                        <BulkCancelOrdersAccordion />
                                    </DialogContent>
                                    <DialogContent>
                                        <DeleteButton
                                            variant="outlined"
                                            disabled={count <= 0 || cancellable}
                                            onClick={openModal}
                                            fullWidth
                                            data-testid="cancel-orders-button"
                                        >
                                            Cancel {object}s
                                        </DeleteButton>
                                    </DialogContent>
                                </div>
                            </Grow>
                        </BoxGrid>
                    </Box>
                </Box>
                <Box display="flex" flex={1} justifyContent="flex-end">
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            disabled={cancelling}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    );
};

export default BulkCancelOrdersDialog;
