import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import TooltipCheckbox from './TooltipCheckbox';
import ExpressExtraServiceToolTip from './ExpressExtraServiceToolTip';

type ExpressDeliveryCheckboxProps = GridProps & {
    checked?: boolean;
    setChecked: (val: boolean) => void;
    disabled?: boolean;
};

const ExpressDeliveryCheckbox: React.FC<ExpressDeliveryCheckboxProps> = ({
    setChecked,
    checked,
    disabled,
    ...props
}) => {
    return (
        <Grid
            item
            {...props}
            style={{
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <TooltipCheckbox
                disabled={disabled}
                tooltip={{
                    title: <ExpressExtraServiceToolTip />,
                }}
                label="Express Delivery"
                setChecked={setChecked}
                checked={checked}
            />
        </Grid>
    );
};

export default ExpressDeliveryCheckbox;
