import { useEffect } from 'react';
import { BankAccountRoutes, ContactRoutes, TemplateRoutes } from '../routes';
import { useHistory } from './useHistory';

export const useResetCreateOrderState = (
    resetState: () => void,
    includeBankAccounts?: boolean
) => {
    const history = useHistory();
    useEffect(() => {
        return () => {
            // Reset the state when not going to a targeted
            // route
            switch (history.location.pathname) {
                case ContactRoutes.CREATE: {
                    break;
                }
                case TemplateRoutes.CREATE: {
                    break;
                }
                case BankAccountRoutes.CREATE: {
                    if (includeBankAccounts) {
                        break;
                    } else {
                        resetState();
                        break;
                    }
                }
                default: {
                    resetState();
                    break;
                }
            }
        };
    }, [history.location.pathname, resetState, includeBankAccounts]);
};
