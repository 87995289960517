import React, { useCallback } from 'react';

import { usePagination } from '../hooks/usePagination';

import { formatTableDate, ListParams } from '../services/util';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import DownloadIcon from '@material-ui/icons/CloudDownload';

import { useService as useBillingService } from '../services/Billing';

import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import TableDisplay from '../components/TableDisplay';

const Invoices = () => {
    const service = useBillingService();

    const paginationFunc = useCallback(
        (params: ListParams) => {
            return service.list(params);
        },
        [service]
    );

    const {
        data: invoices,
        loading,
        pagination,
    } = usePagination(paginationFunc);

    return (
        <>
            <TopNav />
            <GridPaper container direction="column" spacing={2}>
                <Grid item>
                    <Grid item>
                        <Typography variant="h5">Invoices</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableDisplay
                        columns={[
                            'Invoice ID',
                            'Date Created',
                            'Customer Name',
                            'Amount',
                            'Status',
                            'Download',
                        ]}
                        show={loading}
                        pagination={pagination}
                        showEmptyTable
                    >
                        {invoices.map((invoice, i: number) => {
                            return (
                                <TableRow key={invoice.id}>
                                    <TableCell>{invoice.id}</TableCell>
                                    <TableCell>
                                        {formatTableDate(
                                            new Date(invoice.created * 1000)
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {invoice.customer_name}
                                    </TableCell>
                                    <TableCell>
                                        {(
                                            invoice.amount_paid / 100
                                        ).toLocaleString(undefined, {
                                            style: 'currency',
                                            currency: invoice.currency,
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <Grid item>
                                            <Box my={0.5} color="success.main">
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {invoice.status}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={(e) => {
                                                window.open(
                                                    invoice.invoice_pdf,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableDisplay>
                </Grid>
            </GridPaper>
        </>
    );
};

export default Invoices;
