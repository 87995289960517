import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BlogIcon = () => (
    <>
        <g
            id="Group_1580"
            data-name="Group 1580"
            transform="translate(6.65 6.635)"
        >
            <g id="Group_1579" data-name="Group 1579">
                <path
                    id="Path_1336"
                    data-name="Path 1336"
                    d="M179.2,176h-2.081a.68.68,0,1,0,0,1.357H179.2a.7.7,0,0,0,.724-.679A.687.687,0,0,0,179.2,176Z"
                    transform="translate(-176.4 -176)"
                    fill="#575e71"
                />
            </g>
        </g>
        <g
            id="Group_1582"
            data-name="Group 1582"
            transform="translate(6.65 10.857)"
        >
            <g id="Group_1581" data-name="Group 1581">
                <path
                    id="Path_1337"
                    data-name="Path 1337"
                    d="M181.361,288h-4.237a.761.761,0,0,0-.724.754.75.75,0,0,0,.724.754h4.237a.755.755,0,0,0,0-1.508Z"
                    transform="translate(-176.4 -288)"
                    fill="#575e71"
                />
            </g>
        </g>
        <g id="Group_1584" data-name="Group 1584">
            <g id="Group_1583" data-name="Group 1583">
                <path
                    id="Path_1338"
                    data-name="Path 1338"
                    d="M14.235,0H4.75A4.772,4.772,0,0,0,0,4.765V14.25A4.769,4.769,0,0,0,4.75,19h9.485A4.775,4.775,0,0,0,19,14.235V4.765A4.775,4.775,0,0,0,14.235,0Zm1.176,11.671a3.759,3.759,0,0,1-3.77,3.71H7.329a3.685,3.685,0,0,1-3.71-3.694V7.313a3.685,3.685,0,0,1,3.71-3.694H9.952a3.785,3.785,0,0,1,2.9,2.021,2.768,2.768,0,0,1,.332,1.267c.06.467.09.814.3,1.01.3.271,1.4.09,1.629.256l.166.136.106.211.03.151v3Z"
                    fill="#575e71"
                />
            </g>
        </g>
    </>
);

const Support = () => (
    <>
        <g id="handshake" transform="translate(0 -13.875)">
            <path
                id="Path_606"
                data-name="Path 606"
                d="M70.987,270.555a.816.816,0,0,0-1.053-.082l-.008-.005-.7.7a.8.8,0,0,0-.238.574.815.815,0,0,0,.812.812.853.853,0,0,0,.646-.31l.541-.541a.811.811,0,0,0,0-1.148Z"
                transform="translate(-65.692 -244.187)"
                fill="#575e71"
            />
            <path
                id="Path_607"
                data-name="Path 607"
                d="M30.2,220.774a.813.813,0,0,0-1.148,0l-.612.613a.812.812,0,0,0,1.148,1.148l.613-.613A.812.812,0,0,0,30.2,220.774Z"
                transform="translate(-26.851 -196.785)"
                fill="#575e71"
            />
            <path
                id="Path_608"
                data-name="Path 608"
                d="M117.2,314.025a.807.807,0,0,0-.574.238l-.613.613-.009.009a.812.812,0,0,0,1.157,1.139l.613-.613A.814.814,0,0,0,117.2,314.025Z"
                transform="translate(-110.239 -285.805)"
                fill="#575e71"
            />
            <path
                id="Path_609"
                data-name="Path 609"
                d="M12.4,19.272a3.251,3.251,0,0,1-4.025-.451L6.959,17.4l1.773-1.773a4.993,4.993,0,1,0-7.053,7.04,2.249,2.249,0,0,1,3.336,2.112A2.256,2.256,0,0,1,6.96,26.852a2.259,2.259,0,0,1,2.163,1.658,2.254,2.254,0,0,1,2.258.689l.016-.019a.813.813,0,0,0,1.042-1.223v0L9.213,24.728l1.014-1.014,3.191,3.191a.812.812,0,0,0,1.148-1.148l-3.19-3.191,1.014-1.014,3.191,3.191a.812.812,0,0,0,1.148-1.148Z"
                transform="translate(0 -0.065)"
                fill="#575e71"
            />
            <path
                id="Path_610"
                data-name="Path 610"
                d="M197.1,15.332a4.989,4.989,0,0,0-7.046,0l-2,2,.406.406a1.813,1.813,0,0,0,2.561,0l.507-.507c.27.273,5.218,5.213,5.357,5.362A5.011,5.011,0,0,0,197.1,15.332Z"
                transform="translate(-179.065)"
                fill="#575e71"
            />
            <path
                id="Path_611"
                data-name="Path 611"
                d="M175.073,347.454a.813.813,0,0,0-1.148,0l-.613.613a.812.812,0,0,0,1.148,1.148l.613-.613A.817.817,0,0,0,175.073,347.454Z"
                transform="translate(-164.804 -317.41)"
                fill="#575e71"
            />
        </g>
    </>
);

const Subscribe = () => (
    <>
        <path
            id="subscribe_1_"
            data-name="subscribe (1)"
            d="M12.425,9.067A1.267,1.267,0,0,0,10,8.947l-.071.2V5.041a1.266,1.266,0,1,0-2.532,0v9.238a3.248,3.248,0,0,0,3.244,3.244h3.64a3.248,3.248,0,0,0,3.244-3.244V9.365a1.266,1.266,0,0,0-2.5-.3l-.035.146-.035-.146a1.266,1.266,0,0,0-2.461,0l-.035.146ZM2.905,1.307l.39-.571a1.688,1.688,0,0,1,2.79,0l.39.571a5.077,5.077,0,0,0-3.569,0Zm1.785.844A3.913,3.913,0,0,0,.781,6.06V8.172A12.109,12.109,0,0,1,0,12.594H6.228V5.041a2.432,2.432,0,0,1,.99-1.959,3.891,3.891,0,0,0-2.528-.93Zm1.538,11.99-.051.047a2.183,2.183,0,0,1-2.975,0l-.457-.427H6.228Z"
            transform="translate(0 0.001)"
            fill="#575e71"
            fillRule="evenodd"
        />
    </>
);

const LogoutIcon = () => (
    <>
        <SvgIcon>
            <g
                id="logout_1_"
                data-name="logout (1)"
                transform="translate(-0.014)"
            >
                <g
                    id="Group_1529"
                    data-name="Group 1529"
                    transform="translate(0.014)"
                >
                    <g id="Group_1528" data-name="Group 1528">
                        <path
                            id="Path_1308"
                            data-name="Path 1308"
                            d="M305.847,151.952a.73.73,0,0,0-.157-.235l-2.166-2.166a.722.722,0,1,0-1.021,1.021l.934.934H299.4a.722.722,0,0,0,0,1.444h4.034l-.934.934a.722.722,0,1,0,1.021,1.021l2.166-2.166a.715.715,0,0,0,.157-.235A.723.723,0,0,0,305.847,151.952Z"
                            transform="translate(-288.569 -144.283)"
                            fill="#575e71"
                        />
                        <path
                            id="Path_1309"
                            data-name="Path 1309"
                            d="M12.292,10.111a.722.722,0,0,0-.722.722v3.611H8.681V2.889A.723.723,0,0,0,8.166,2.2L5.657,1.444H11.57V5.056a.722.722,0,1,0,1.444,0V.722A.722.722,0,0,0,12.292,0H.736A.644.644,0,0,0,.662.014.713.713,0,0,0,.356.121C.34.131.32.131.305.143S.3.155.291.16A.716.716,0,0,0,.1.388a.59.59,0,0,0-.02.061A.685.685,0,0,0,.022.617a.45.45,0,0,0,0,.063c0,.014-.01.027-.01.042V15.167a.722.722,0,0,0,.581.708l7.222,1.444a.672.672,0,0,0,.142.014.723.723,0,0,0,.722-.722v-.722h3.611a.722.722,0,0,0,.722-.722V10.833A.722.722,0,0,0,12.292,10.111Z"
                            transform="translate(-0.014)"
                            fill="#575e71"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    </>
);

const SettingsIcon = (props: { color?: string }) => (
    <>
        <path
            id="settings"
            d="M16.068,6.654l-1.332-.169a6.694,6.694,0,0,0-.4-.973l.822-1.059a1.053,1.053,0,0,0-.086-1.4L13.953,1.935a1.056,1.056,0,0,0-1.406-.09l-1.058.822a6.67,6.67,0,0,0-.974-.4L10.346.935A1.061,1.061,0,0,0,9.294,0H7.707A1.061,1.061,0,0,0,6.654.933L6.485,2.265a6.593,6.593,0,0,0-.974.4L4.453,1.845a1.054,1.054,0,0,0-1.4.086L1.935,3.047a1.056,1.056,0,0,0-.09,1.407l.822,1.058a6.616,6.616,0,0,0-.4.973l-1.33.169A1.061,1.061,0,0,0,0,7.707V9.294a1.061,1.061,0,0,0,.933,1.053l1.332.169a6.694,6.694,0,0,0,.4.973l-.822,1.059a1.053,1.053,0,0,0,.086,1.4l1.116,1.116a1.057,1.057,0,0,0,1.406.089l1.058-.822a6.54,6.54,0,0,0,.973.4l.169,1.329A1.061,1.061,0,0,0,7.707,17H9.294a1.061,1.061,0,0,0,1.053-.933l.169-1.332a6.694,6.694,0,0,0,.973-.4l1.059.822a1.053,1.053,0,0,0,1.4-.086l1.116-1.116a1.055,1.055,0,0,0,.09-1.406l-.822-1.058a6.54,6.54,0,0,0,.4-.973l1.329-.169A1.061,1.061,0,0,0,17,9.294V7.707a1.06,1.06,0,0,0-.932-1.053ZM8.5,12.042A3.542,3.542,0,1,1,12.042,8.5,3.546,3.546,0,0,1,8.5,12.042Z"
            fill={props.color ? props.color : 'inherit'}
        />
    </>
);

const PrevIcon = () => (
    <>
        <g
            id="Group_536"
            data-name="Group 536"
            transform="translate(13572.734 2391.865)"
        >
            <path
                id="Path_602"
                data-name="Path 602"
                d="M-13559.25-2388.667h-12.083"
                fill="none"
                stroke="#145ac2"
                stroke-width="1"
            />
            <path
                id="Path_603"
                data-name="Path 603"
                d="M-13568.937-2391.5l-3.062,2.875,3.063,2.813"
                fill="none"
                stroke="#145ac2"
                stroke-width="1"
            />
        </g>
    </>
);

const NextIcon = () => (
    <>
        <g
            id="Group_537"
            data-name="Group 537"
            transform="translate(-13559.25 -2385.444) rotate(180)"
        >
            <path
                id="Path_602"
                data-name="Path 602"
                d="M-13559.25-2388.667h-12.083"
                fill="none"
                stroke="#145ac2"
                stroke-width="1"
            />
            <path
                id="Path_603"
                data-name="Path 603"
                d="M-13568.937-2391.5l-3.062,2.875,3.063,2.813"
                fill="none"
                stroke="#145ac2"
                stroke-width="1"
            />
        </g>
    </>
);

const UploadIcon = (props: { color: string }) => (
    <svg
        id="upload_2_"
        data-name="upload (2)"
        xmlns="http://www.w3.org/2000/svg"
        width="28.669"
        height="26.5"
        viewBox="0 0 28.669 26.5"
    >
        <g id="Group_628" data-name="Group 628">
            <path
                id="Path_654"
                data-name="Path 654"
                d="M23.316,25.321A8.3,8.3,0,0,0,7.929,22.562a4.757,4.757,0,0,0-.873-.083,4.552,4.552,0,0,0-4.545,4.545,4.969,4.969,0,0,0,.094.943A6.325,6.325,0,0,0,0,33.068a6.762,6.762,0,0,0,1.716,4.475A6.123,6.123,0,0,0,5.96,39.677h5.117a.8.8,0,1,0,0-1.592H6.031a5.02,5.02,0,0,1-4.439-5.023,4.725,4.725,0,0,1,2.34-4.068.8.8,0,0,0,.348-.967A2.888,2.888,0,0,1,4.1,27.013,2.959,2.959,0,0,1,7.057,24.06a2.9,2.9,0,0,1,1.008.177.8.8,0,0,0,1-.407A6.712,6.712,0,0,1,21.813,26.04a.8.8,0,0,0,.66.707,5.737,5.737,0,0,1-.542,11.331H17.586a.8.8,0,1,0,0,1.592h4.433a7.053,7.053,0,0,0,4.728-2.335,7.338,7.338,0,0,0-3.431-12.015Z"
                transform="translate(0 -18.4)"
                fill={props.color}
            />
            <path
                id="Path_655"
                data-name="Path 655"
                d="M168.01,190.977a.794.794,0,0,0,0-1.126l-4.215-4.215a.805.805,0,0,0-.56-.236.781.781,0,0,0-.56.236l-4.215,4.215a.8.8,0,0,0,.56,1.362.777.777,0,0,0,.56-.236l2.859-2.859v13.141a.8.8,0,1,0,1.592,0V188.118l2.859,2.859A.786.786,0,0,0,168.01,190.977Z"
                transform="translate(-148.897 -175.555)"
                fill={props.color}
            />
        </g>
    </svg>
);

const SendIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32.323"
        height="31.072"
        viewBox="0 0 32.323 31.072"
    >
        <g id="send" transform="translate(0 6.456) rotate(-30) scale(0.9)">
            <g id="Group_962" data-name="Group 962" transform="translate(0 0)">
                <path
                    id="Path_1071"
                    data-name="Path 1071"
                    d="M23.429,8.526,3.329.186A2.406,2.406,0,0,0,.076,3l1.789,7.016h8.76a.73.73,0,1,1,0,1.46H1.865L.076,18.494A2.406,2.406,0,0,0,3.329,21.31l20.1-8.34a2.406,2.406,0,0,0,0-4.444Z"
                    transform="translate(0 0)"
                    fill="#a3acb9"
                />
            </g>
        </g>
    </svg>
);

const ReceiverIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.457"
        height="24.321"
        viewBox="0 0 16.457 24.321"
    >
        <g id="recipt" transform="translate(0 0)">
            <path
                id="Path_1077"
                data-name="Path 1077"
                d="M35.385,3.07,37.229,1.7l10.125.469,1.25-.312.938.969-.156,18.688L47.7,22.758l-2.156-1.094-1.656,1.219L43.1,22.07l-2.531.313-.906.125s-2.281-.406-2.437-.437-1.094.938-1.187.688a21.117,21.117,0,0,0-1.312-1.75Z"
                transform="translate(-34.061 0)"
                fill="#a3acb9"
            />
            <path
                id="Path_1072"
                data-name="Path 1072"
                d="M61.235,52.045h4.557a.866.866,0,0,0,0-1.732H61.235a.866.866,0,0,0,0,1.732Z"
                transform="translate(-57.332 -44.505)"
                fill="#fff"
            />
            <path
                id="Path_1073"
                data-name="Path 1073"
                d="M69.886,81.994H61.235a.866.866,0,0,0,0,1.732h8.651a.866.866,0,0,0,0-1.732Z"
                transform="translate(-57.332 -72.529)"
                fill="#fff"
            />
            <path
                id="Path_1074"
                data-name="Path 1074"
                d="M61.235,115.414H67.4a.866.866,0,0,0,0-1.732H61.235a.866.866,0,0,0,0,1.732Z"
                transform="translate(-57.332 -100.558)"
                fill="#fff"
            />
            <path
                id="Path_1075"
                data-name="Path 1075"
                d="M69.886,145.367H61.235a.866.866,0,1,0,0,1.732h8.651a.866.866,0,1,0,0-1.732Z"
                transform="translate(-57.332 -128.586)"
                fill="#fff"
            />
            <path
                id="Path_1076"
                data-name="Path 1076"
                d="M50.264,2.095,48.423.254a.866.866,0,0,0-1.224,0L45.971,1.482,44.742.254a.866.866,0,0,0-1.225,0L42.289,1.482,41.061.254a.866.866,0,0,0-1.225,0L38.608,1.482,37.38.254a.866.866,0,0,0-1.224,0L34.315,2.095a.866.866,0,0,0-.254.612V21.615a.866.866,0,0,0,.254.612l1.841,1.84a.866.866,0,0,0,1.224,0l1.229-1.229,1.229,1.229a.866.866,0,0,0,1.224,0l1.228-1.228,1.228,1.228a.866.866,0,0,0,1.224,0l1.229-1.228L47.2,24.067a.866.866,0,0,0,1.224,0l1.841-1.84a.866.866,0,0,0,.254-.612V2.707A.867.867,0,0,0,50.264,2.095ZM48.786,21.256l-.975.975L46.583,21a.866.866,0,0,0-1.224,0L44.13,22.231,42.9,21a.866.866,0,0,0-1.224,0L40.449,22.23,39.221,21A.866.866,0,0,0,38,21l-1.229,1.228-.975-.975V3.065l.975-.975L38,3.319a.866.866,0,0,0,1.225,0L40.449,2.09l1.228,1.229a.866.866,0,0,0,1.225,0L44.13,2.09l1.229,1.229a.866.866,0,0,0,1.225,0L47.812,2.09l.975.975V21.256Z"
                transform="translate(-34.061 0)"
                fill="#a3acb9"
            />
        </g>
    </svg>
);

const HomeIcon = (props: { secondColor?: string }) => {
    return (
        <>
            <g id="home" transform="translate(0 -0.001)">
                <path
                    id="Path_354"
                    data-name="Path 354"
                    d="M343.925,21.332H341.79a.458.458,0,0,0-.458.458v2.062a.456.456,0,0,0,.136.325l2.135,2.111a.457.457,0,0,0,.779-.325V21.79A.458.458,0,0,0,343.925,21.332Zm0,0"
                    transform="translate(-331.57 -20.721)"
                    fill="inherit"
                />
                <path
                    id="Path_355"
                    data-name="Path 355"
                    d="M14.643,7.17a.763.763,0,0,1-.763.763H12.812L7.321,2.594,1.83,7.933H.763A.764.764,0,0,1,.226,6.627L7,.129a.457.457,0,0,1,.635,0l6.772,6.492a.762.762,0,0,1,.232.549Zm0,0"
                    transform="translate(0 0)"
                    fill="inherit"
                />
                <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M-265.09,431.873h-2.44a1.222,1.222,0,0,1-1.22-1.22v-5.491l5.491-5.338,5.491,5.338v5.491a1.222,1.222,0,0,1-1.22,1.22h-2.44v-4.881h-3.661v4.881Z"
                    transform="translate(270.58 -417.228)"
                    fill={props.secondColor}
                />
            </g>
        </>
    );
};

const AnalyticsIcon = () => {
    return (
        <>
            <path
                id="Path_358"
                data-name="Path 358"
                d="M15.69,7.845v.488a7.357,7.357,0,0,1-12.559,5.2A7.357,7.357,0,0,1,8.333.977h.488V0H8.333A8.333,8.333,0,0,0,2.441,14.226,8.333,8.333,0,0,0,16.667,8.333V7.845Z"
                fill="inherit"
            />
            <path
                id="Path_359"
                data-name="Path 359"
                d="M307.318,9.315a8.346,8.346,0,0,0-6.009-6l-.609-.155V9.924h6.774Z"
                transform="translate(-290.912 -3.055)"
                fill="inherit"
            />
            <path
                id="Path_360"
                data-name="Path 360"
                d="M70.734,64.5a6.234,6.234,0,1,0,6.234,6.234v-.488H71.222V64.5Z"
                transform="translate(-62.4 -62.4)"
                fill="inherit"
            />
        </>
    );
};

const ContactIcon = () => {
    return (
        <path
            id="contact-book_1_"
            data-name="contact-book (1)"
            d="M12.664,2.744H3.058a.688.688,0,0,1-.686-.686.738.738,0,0,1,.686-.686h9.606A.688.688,0,0,0,13.35.686.738.738,0,0,0,12.664,0H3.058A2.064,2.064,0,0,0,1,2.058v9.606a2.064,2.064,0,0,0,2.058,2.058h9.606a.688.688,0,0,0,.686-.686V3.431A.738.738,0,0,0,12.664,2.744ZM7.175,4.8A1.372,1.372,0,1,1,5.8,6.175,1.376,1.376,0,0,1,7.175,4.8ZM4.431,11.664a2.744,2.744,0,1,1,5.489,0Z"
            transform="translate(-1)"
        />
    );
};

const CallIcon = () => {
    return (
        <g id="Group_1578" data-name="Group 1578">
            <path
                id="Path_1335"
                data-name="Path 1335"
                d="M14.716,10.5a9.084,9.084,0,0,1-2.853-.454,1.3,1.3,0,0,0-1.268.267L8.8,11.673A9.942,9.942,0,0,1,4.326,7.2L5.644,5.452a1.293,1.293,0,0,0,.318-1.31,9.1,9.1,0,0,1-.456-2.858A1.285,1.285,0,0,0,4.222,0H1.284A1.285,1.285,0,0,0,0,1.284,14.733,14.733,0,0,0,14.716,16,1.285,1.285,0,0,0,16,14.716v-2.93A1.285,1.285,0,0,0,14.716,10.5Z"
                fill="#575e71"
            />
        </g>
    );
};

const TemplateIcon = (props: { secondColor?: string }) => {
    return (
        <>
            <path
                id="Path_377"
                data-name="Path 377"
                d="M331,376h1.719v1.719H331Z"
                transform="translate(-321.518 -365.229)"
                fill={props.secondColor}
            />
            <path
                id="Path_378"
                data-name="Path 378"
                d="M121,376h3.438v1.719H121Z"
                transform="translate(-117.534 -365.229)"
                fill={props.secondColor}
            />
            <path
                id="Path_379"
                data-name="Path 379"
                d="M0,159.052a1.315,1.315,0,0,0,1.289,1.318H13.378a1.315,1.315,0,0,0,1.289-1.318V150H0Zm12.06-.43a.429.429,0,0,1-.43.43H9.052a.429.429,0,0,1-.43-.43v-2.578a.429.429,0,0,1,.43-.43H11.63a.429.429,0,0,1,.43.43Zm-9.453-6.875a.429.429,0,0,1,.43-.43H11.63a.429.429,0,0,1,.43.43v2.578a.429.429,0,0,1-.43.43H3.036a.429.429,0,0,1-.43-.43Zm0,4.3a.429.429,0,0,1,.43-.43h4.3a.429.429,0,0,1,.43.43v2.578a.429.429,0,0,1-.43.43h-4.3a.429.429,0,0,1-.43-.43Z"
                transform="translate(0 -145.703)"
                fill={props.secondColor}
            />
            <path
                id="Path_380"
                data-name="Path 380"
                d="M121,226h7.734v1.719H121Z"
                transform="translate(-117.534 -219.526)"
                fill={props.secondColor}
            />
            <path
                id="Path_381"
                data-name="Path 381"
                d="M13.378,0H1.289A1.29,1.29,0,0,0,0,1.289V3.438H14.667V1.289A1.29,1.29,0,0,0,13.378,0ZM3.036,2.148a.43.43,0,1,1,.43-.43A.43.43,0,0,1,3.036,2.148Zm1.719,0a.43.43,0,1,1,.43-.43A.43.43,0,0,1,4.755,2.148Zm1.719,0a.43.43,0,1,1,.43-.43A.43.43,0,0,1,6.474,2.148Z"
                fill="inherit"
            />
        </>
    );
};

const BankIcon = (props: { secondColor?: string }) => {
    return (
        <>
            <g
                id="Deposit_at_bank"
                data-name="Deposit at bank"
                transform="translate(-4 -4.559)"
            >
                <g
                    id="Group_277"
                    data-name="Group 277"
                    transform="translate(4 4.559)"
                >
                    <path
                        id="Path_367"
                        data-name="Path 367"
                        d="M4,43H18.9v1.513H4Z"
                        transform="translate(-4 -29.98)"
                        fill="inherit"
                    />
                    <path
                        id="Path_368"
                        data-name="Path 368"
                        d="M20,31.539V32.3h3.026v-.756h-.757V27.756h.757V27H20v.756h.756v3.782Z"
                        transform="translate(-14.064 -20.24)"
                        fill={props.secondColor ? props.secondColor : '#B5B7BE'}
                    />
                    <path
                        id="Path_369"
                        data-name="Path 369"
                        d="M33,31.539V32.3h3.026v-.756h-.756V27.756h.756V27H33v.756h.756v3.782Z"
                        transform="translate(-22.262 -20.24)"
                        fill={props.secondColor ? props.secondColor : '#B5B7BE'}
                    />
                    <path
                        id="Path_370"
                        data-name="Path 370"
                        d="M7,31.539V32.3h3.026v-.756H9.269V27.756h.756V27H7v.756h.756v3.782Z"
                        transform="translate(-5.865 -20.24)"
                        fill={props.secondColor ? props.secondColor : '#B5B7BE'}
                    />
                    <path
                        id="Path_371"
                        data-name="Path 371"
                        d="M11.449,15.165v3.209h2.98a.473.473,0,0,1,0,.924H8.469a.473.473,0,0,1,0-.924h2.98V15.165L4,19.3v1.386H18.9V19.3Z"
                        transform="translate(-4 -15.165)"
                        fill="inherit"
                    />
                </g>
            </g>
        </>
    );
};

const LettersIcon = (props: { secondColor?: string }) => {
    return (
        <>
            <g id="mail_1_" data-name="mail (1)" transform="translate(0 0)">
                <path
                    id="Path_373"
                    data-name="Path 373"
                    d="M9.5,177.63,8.42,176.9,9.5,175.82Z"
                    transform="translate(-8.179 -170.783)"
                    fill="inherit"
                />
                <path
                    id="Path_374"
                    data-name="Path 374"
                    d="M75.99.492v6.94l3.648,2.485a2.91,2.91,0,0,0,3.3-.036L86.3,7.495v-7A.492.492,0,0,0,85.811,0H76.482a.492.492,0,0,0-.492.492Zm8.488,6.272H77.827a.438.438,0,0,1-.441-.409.43.43,0,0,1,.429-.451h6.651a.438.438,0,0,1,.441.409A.43.43,0,0,1,84.478,6.764Zm0-2.255H77.827a.438.438,0,0,1-.441-.409.43.43,0,0,1,.429-.45h6.65a.438.438,0,0,1,.441.409.43.43,0,0,1-.429.451Zm0-2.255H77.827a.438.438,0,0,1-.441-.409.43.43,0,0,1,.429-.451h6.651a.438.438,0,0,1,.441.409A.43.43,0,0,1,84.478,2.255Z"
                    transform="translate(-73.813 0)"
                    fill={props.secondColor ? props.secondColor : '#b5b7be'}
                />
                <path
                    id="Path_375"
                    data-name="Path 375"
                    d="M467.093,176.9l-1.083.767v-1.85Z"
                    transform="translate(-452.661 -170.783)"
                    fill="inherit"
                />
                <path
                    id="Path_376"
                    data-name="Path 376"
                    d="M14.667,243.988v7.023a.637.637,0,0,1-.637.637H.637A.637.637,0,0,1,0,251.011V243.97l4.647,3.165-2.678,2.678a.438.438,0,0,0-.025.6.43.43,0,0,0,.624.018l2.8-2.8a3.753,3.753,0,0,0,4.152,0l2.8,2.8a.43.43,0,0,0,.608-.608l-2.7-2.7Z"
                    transform="translate(0 -236.981)"
                    fill="inherit"
                />
            </g>
        </>
    );
};

const PostcardIcon = () => {
    return (
        <>
            <g id="XMLID_597_" transform="translate(-29, 0)">
                <path
                    id="XMLID_626_"
                    d="M40.956,2.644h.378V0H38.689V.378a.756.756,0,1,1-1.511,0V0H34.156V.378a.756.756,0,1,1-1.511,0V0H30V2.644h.378a.756.756,0,0,1,0,1.511H30V8.689h.378a.756.756,0,0,1,0,1.511H30v2.644h2.644v-.378a.756.756,0,1,1,1.511,0v.378h3.022v-.378a.756.756,0,1,1,1.511,0v.378h2.644V10.2h-.378a.756.756,0,1,1,0-1.511h.378V4.156h-.378a.756.756,0,1,1,0-1.511ZM38.689,10.2H32.644V2.644h6.044Z"
                    fill="inherit"
                />
                <path
                    id="XMLID_622_"
                    d="M169.533,285.035l-1.322-1.322-1.544,1.544H165v.756h4.533Z"
                    transform="translate(-131.6 -276.568)"
                    fill="inherit"
                />
                <path
                    id="XMLID_601_"
                    d="M167.644,138.244l.567-.567L169.533,139v-4H165v2.488l.944-.944Z"
                    transform="translate(-131.6 -131.6)"
                    fill="inherit"
                />
                <path
                    id="XMLID_598_"
                    d="M167.11,239.879l-1.166-1.166-.944.944v.977h1.355Z"
                    transform="translate(-131.6 -232.701)"
                    fill="inherit"
                />
            </g>
        </>
    );
};

const FileIcon = () => {
    return (
        <>
            <g
                id="google-docs_1_"
                data-name="google-docs (1)"
                transform="translate(-59, 0)"
            >
                <path
                    id="Path_382"
                    data-name="Path 382"
                    d="M62.231,14h8.205a1.232,1.232,0,0,0,1.231-1.231V4.1H68.795a1.232,1.232,0,0,1-1.231-1.231V0H62.231A1.232,1.232,0,0,0,61,1.231V12.773A1.232,1.232,0,0,0,62.231,14Zm1.641-8.232h4.923a.41.41,0,0,1,0,.821H63.872a.41.41,0,0,1,0-.821Zm0,1.641h4.923a.41.41,0,0,1,0,.821H63.872a.41.41,0,0,1,0-.821Zm0,1.641h4.923a.41.41,0,0,1,0,.821H63.872a.41.41,0,0,1,0-.821Zm0,1.641h3.282a.41.41,0,1,1,0,.821H63.872a.41.41,0,0,1,0-.821Z"
                    fill="inherit"
                />
                <path
                    id="Path_383"
                    data-name="Path 383"
                    d="M331.41,11.831h2.631L331,8.789V11.42A.411.411,0,0,0,331.41,11.831Z"
                    transform="translate(-262.615 -8.549)"
                    fill="inherit"
                />
            </g>
        </>
    );
};

const UserIcon = () => {
    return (
        <>
            <g id="man-user" transform="translate(-39.9)">
                <path
                    id="Path_389"
                    data-name="Path 389"
                    d="M107.756,7.825c1.779,0,3.221-1.752,3.221-3.913S110.5,0,107.756,0s-3.221,1.752-3.221,3.913S105.977,7.825,107.756,7.825Z"
                    transform="translate(-59.776)"
                    fill="inherit"
                />
                <path
                    id="Path_390"
                    data-name="Path 390"
                    d="M41.9,300.44c0-.132,0-.037,0,0Z"
                    transform="translate(0 -286.641)"
                />
                <path
                    id="Path_391"
                    data-name="Path 391"
                    d="M308.085,301.606c0-.036,0-.25,0,0Z"
                    transform="translate(-254.021 -287.704)"
                />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    d="M54.069,183.256c-.06-3.764-.551-4.837-4.313-5.516a2.643,2.643,0,0,1-3.528,0c-3.721.672-4.242,1.728-4.311,5.394-.006.3-.008.315-.009.28,0,.065,0,.186,0,.4,0,0,.9,1.806,6.084,1.806s6.084-1.806,6.084-1.806c0-.135,0-.229,0-.293A2.422,2.422,0,0,1,54.069,183.256Z"
                    transform="translate(-0.012 -169.615)"
                    fill="inherit"
                />
            </g>
        </>
    );
};

const PaymentIcon = (props: { secondColor?: string }) => {
    return (
        <>
            <g
                id="credit-card_4_"
                data-name="credit-card (4)"
                transform="translate(0 -32.008)"
            >
                <g
                    id="Group_279"
                    data-name="Group 279"
                    transform="translate(0 35.079)"
                >
                    <g
                        id="Group_278"
                        data-name="Group 278"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_386"
                            data-name="Path 386"
                            d="M7.166,129.016v-1.024H1.024A1.025,1.025,0,0,0,0,129.016v2.047H7.382A9.166,9.166,0,0,1,7.166,129.016Z"
                            transform="translate(0 -127.992)"
                            fill={
                                props.secondColor
                                    ? props.secondColor
                                    : '#b5b7be'
                            }
                        />
                    </g>
                </g>
                <g
                    id="Group_281"
                    data-name="Group 281"
                    transform="translate(0 40.197)"
                >
                    <g id="Group_280" data-name="Group 280">
                        <path
                            id="Path_387"
                            data-name="Path 387"
                            d="M16.338,287.992a9.208,9.208,0,0,1-3.288,2.867,1.538,1.538,0,0,1-1.526,0,9.254,9.254,0,0,1-3.289-2.87H0v5.119a1.025,1.025,0,0,0,1.024,1.024H15.356a1.024,1.024,0,0,0,1.024-1.024v-5.119ZM4.607,291.063H2.559a.512.512,0,1,1,0-1.024H4.607a.512.512,0,1,1,0,1.024Z"
                            transform="translate(0 -287.992)"
                            fill={
                                props.secondColor
                                    ? props.secondColor
                                    : '#b5b7be'
                            }
                        />
                    </g>
                </g>
                <g
                    id="Group_283"
                    data-name="Group 283"
                    transform="translate(8.19 32.008)"
                >
                    <g
                        id="Group_282"
                        data-name="Group 282"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_388"
                            data-name="Path 388"
                            d="M263.88,33.585,260.3,32.049a.514.514,0,0,0-.4,0l-3.583,1.536a.511.511,0,0,0-.309.47V36.1c0,2.816,1.041,4.462,3.84,6.075a.515.515,0,0,0,.51,0c2.8-1.608,3.84-3.254,3.84-6.075V34.055A.51.51,0,0,0,263.88,33.585Zm-1.849,2.326-2.047,2.559a.517.517,0,0,1-.4.191h-.021a.514.514,0,0,1-.4-.227L258.133,36.9a.512.512,0,0,1,.852-.567l.636.953,1.609-2.013a.512.512,0,0,1,.8.639Z"
                            transform="translate(-256 -32.008)"
                            fill="inherit"
                        />
                    </g>
                </g>
            </g>
        </>
    );
};

const InvoiceIcon = () => {
    return (
        <>
            <g id="Layer_42" data-name="Layer 42" transform="translate(-3 -2)">
                <path
                    id="Path_385"
                    data-name="Path 385"
                    d="M13.951,2H4.428A1.428,1.428,0,0,0,3,3.428V14.856a.476.476,0,0,0,.781.367l2.552-2.128L8.6,14.97a.952.952,0,0,0,1.219,0l2.224-1.876L14.6,15.222a.476.476,0,0,0,.781-.367V3.428A1.428,1.428,0,0,0,13.951,2Zm-1.428,8.094H5.857a.476.476,0,1,1,0-.952h6.666a.476.476,0,1,1,0,.952Zm0-2.381H5.857a.476.476,0,0,1,0-.952h6.666a.476.476,0,0,1,0,.952Zm0-2.381H5.857a.476.476,0,0,1,0-.952h6.666a.476.476,0,0,1,0,.952Z"
                    transform="translate(0)"
                    fill="inherit"
                />
            </g>
        </>
    );
};

const UpgradeIcon = () => {
    return (
        <path
            id="upgrade"
            d="M17.777,14.451H14.807a.827.827,0,0,0-.715.411,4.047,4.047,0,0,1-.765.984.326.326,0,0,0,.215.568h4.235a.492.492,0,0,0,.492-.492v-.97a.492.492,0,0,0-.492-.5Zm1.641-3.6H13a.326.326,0,0,0-.3.2.322.322,0,0,0,.072.358l1.172,1.167a.814.814,0,0,0,.581.241h4.892a.492.492,0,0,0,.492-.492v-.984a.492.492,0,0,0-.492-.492ZM16.14,18.083H11.86a.331.331,0,0,0-.331.326v1.315a.331.331,0,0,0,.331.326h4.28a.492.492,0,0,0,.492-.492v-.984A.492.492,0,0,0,16.14,18.083ZM12.178,14.6H9.991v6.042a.492.492,0,0,1-.492.492H8.184a.492.492,0,0,1-.492-.492V14.581H5.506a.662.662,0,0,1-.447-1.122l3.13-3.108a.97.97,0,0,1,.7-.291.984.984,0,0,1,.7.291l3.13,3.13A.657.657,0,0,1,12.24,14.6Z"
            transform="translate(-4.872 -10.06)"
            fill="inherit"
        />
    );
};

const WebhookIcon = (props: { secondColor?: string }) => {
    return (
        <g>
            <path
                fill={props.secondColor ? 'currentColor' : `#767676`}
                d="M11.965 14.379c-.399 0-.797-.078-1.168-.227l-.004-.004.184-.242c1.16.446 2.484.063 3.199-.926a2.538 2.538 0 00-.184-3.222c-.824-.903-2.183-1.145-3.289-.578l-.148-.254c.082-.04.16-.078.242-.114 1.527-.62 3.289.07 3.933 1.543.645 1.473-.07 3.172-1.597 3.797a3.049 3.049 0 01-1.168.227zm0 0"
            ></path>
            <path
                fill="none"
                stroke={props.secondColor ? props.secondColor : 'currentColor'}
                strokeWidth="3"
                d="M3444.987 3021.008l23.917 45.916"
                transform="matrix(.10714 0 0 .10345 -360.89 -308.276)"
            ></path>
            <path
                fill={props.secondColor ? 'currentColor' : `#767676`}
                d="M14.164 11.484c0 1.168-.984 2.118-2.2 2.118-1.21 0-2.194-.95-2.194-2.118 0-1.171.984-2.12 2.195-2.12 1.215 0 2.2.948 2.2 2.12zm0 0M1.5 9.55c.2-.335.469-.628.79-.863v-.003h.003c.055.113.098.21.125.273-.977.75-1.293 2.047-.77 3.14.528 1.094 1.762 1.696 2.985 1.458 1.222-.239 2.117-1.258 2.164-2.461l.3.004a2.872 2.872 0 01-.023.257c-.203 1.59-1.703 2.715-3.347 2.516-1.645-.195-2.813-1.644-2.606-3.23.047-.387.176-.758.379-1.09zm0 0"
            ></path>
            <path
                fill="none"
                stroke={props.secondColor ? props.secondColor : 'currentColor'}
                strokeWidth="3"
                d="M3445.012 3020.988l23.905 45.936"
                transform="matrix(-.05357 .08959 -.09279 -.05172 477.336 -140.892)"
            ></path>
            <path
                fill={props.secondColor ? 'currentColor' : `#767676`}
                d="M3 12.836c-1.05-.586-1.41-1.883-.805-2.899.606-1.015 1.95-1.363 3-.777 1.051.586 1.41 1.883.805 2.899-.605 1.015-1.95 1.359-3 .777zm0 0M10.809 2.508c.199.336.328.707.379 1.09v.007c-.13-.015-.239-.023-.31-.03-.183-1.192-1.187-2.11-2.429-2.216-1.246-.105-2.402.625-2.8 1.766-.395 1.14.066 2.398 1.124 3.04l-.152.25c-.074-.048-.152-.095-.223-.145-1.32-.97-1.582-2.786-.582-4.06C6.816.935 8.7.68 10.02 1.646c.32.234.59.527.789.863zm0 0"
            ></path>
            <path
                fill="none"
                stroke={props.secondColor ? props.secondColor : 'currentColor'}
                strokeWidth="3"
                d="M3444.983 3020.997l23.927 45.935"
                transform="matrix(-.05357 -.08959 .09279 -.05172 -92.171 475.605)"
            ></path>
            <path
                fill={props.secondColor ? 'currentColor' : `#767676`}
                d="M7.11 2.121c1.05-.586 2.394-.238 3.003.777.606 1.012.246 2.309-.804 2.895-1.051.586-2.395.238-3-.777-.61-1.012-.247-2.309.8-2.895zm0 0"
            ></path>
        </g>
    );
};

const MailIcon = (props: { secondColor?: string }) => {
    return (
        <g
            fill="none"
            stroke={props.secondColor ? 'currentColor' : '#767676'}
            strokeWidth="0.75"
            transform="translate(-2, -3)"
        >
            <path d="M2.5 5h14.719v10.375H2.5zm0 0"></path>
            <path d="M2.594 5.125L9.938 12l6.968-6.875"></path>
        </g>
    );
};

const APILogIcon = (props: { secondColor?: string }) => {
    return (
        <g xmlns="http://www.w3.org/2000/svg" transform="translate(2.5, 0)">
            <path
                fill="none"
                stroke={props.secondColor ? 'currentColor' : '#767676'}
                strokeWidth="8"
                d="M16.997 4.02h101.006c7.18 0 12.981 5.783 12.981 12.96v113.04c0 7.177-5.8 12.96-12.98 12.96H16.996c-7.18 0-12.981-5.783-12.981-12.96V16.98c0-7.177 5.8-12.96 12.98-12.96zm0 0"
                transform="scale(.0963 .09524)"
            ></path>
            <path
                fill={props.secondColor ? 'currentColor' : `#767676`}
                d="M3.852 3.906a.86.86 0 01-.868.856.863.863 0 01-.867-.856c0-.476.39-.86.867-.86.48 0 .868.384.868.86zm0 0M4.719 3.43h5.968c.11 0 .196.086.196.187v.574a.193.193 0 01-.195.192h-5.97a.192.192 0 01-.19-.192v-.574a.19.19 0 01.19-.187zm0 0M3.852 7.047c0 .473-.387.86-.868.86a.866.866 0 01-.867-.86c0-.473.39-.856.867-.856a.86.86 0 01.868.856zm0 0M4.719 6.57h5.968c.11 0 .196.086.196.192v.57a.193.193 0 01-.195.191h-5.97a.192.192 0 01-.19-.191v-.57c0-.106.085-.192.19-.192zm0 0M3.852 10.191a.86.86 0 01-.868.856.863.863 0 01-.867-.856c0-.472.39-.859.867-.859.48 0 .868.387.868.86zm0 0M4.719 9.715h5.968c.11 0 .196.086.196.191v.57a.193.193 0 01-.195.192h-5.97a.192.192 0 01-.19-.191v-.57c0-.106.085-.192.19-.192zm0 0"
            ></path>
        </g>
    );
};

export {
    BlogIcon,
    Support,
    Subscribe,
    LogoutIcon,
    SettingsIcon,
    PrevIcon,
    NextIcon,
    FileIcon,
    UploadIcon,
    SendIcon,
    ReceiverIcon,
    HomeIcon,
    AnalyticsIcon,
    ContactIcon,
    CallIcon,
    TemplateIcon,
    BankIcon,
    LettersIcon,
    PostcardIcon,
    UserIcon,
    PaymentIcon,
    InvoiceIcon,
    UpgradeIcon,
    WebhookIcon,
    MailIcon,
    APILogIcon,
};
