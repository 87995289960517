import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ButtonProps } from '@material-ui/core/Button';
import Button from './Button';

type LinkButtonProps = {
    to: string;
} & ButtonProps;

const LinkButton: React.FC<LinkButtonProps> = ({ to, children, ...props }) => {
    return (
        <RouterLink to={to} style={{ all: 'inherit' }}>
            <Button variant="contained" color="primary" {...props}>
                {children}
            </Button>
        </RouterLink>
    );
};

export default LinkButton;
