import logo from '../logo.png';
import loginGraphic from '../img/login-image.png';

import React, { useState, useContext, FormEvent, SetStateAction } from 'react';

import { Redirect, Link as RouterLink } from 'react-router-dom';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { APIErrorType } from '../services/util';
import { useService as useBaseService } from '../services/Base';

import {
    Context as AuthContext,
    LoginPayload,
    ActionType,
} from '../context/Auth';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import Button from '../components/Button';

type FormFieldProps = TextFieldProps & {
    label: string;
};

type PasswordFieldProps = FormFieldProps & {
    showPassword: boolean;
    setShowPassword?: (value: SetStateAction<boolean>) => void;
};

export const FormField = ({ label, ...props }: FormFieldProps) => {
    return (
        <>
            <Typography color="textSecondary">{label}</Typography>
            <TextField variant="outlined" size="small" fullWidth {...props} />
        </>
    );
};

export const PasswordField = ({
    showPassword,
    setShowPassword,
    ...props
}: PasswordFieldProps) => {
    return (
        <FormField
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: setShowPassword && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword!((prev) => !prev)}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};

// TODO Mostly copied from Signup page. Maybe make a CenteredCard component for this.
const Login = (props: { allowImpersonation?: boolean }) => {
    const service = useBaseService();

    const { state: authState, dispatch: authDispatch } =
        useContext(AuthContext);

    const { dispatch: notify } = useContext(NotificationContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOTP] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [impersonatedEmail, setImpersonatedEmail] = useState<string>();

    const [otpRequired, setOTPRequired] = useState(false);
    const [loading, setLoading] = useState(false);

    if (authState.user) {
        return <Redirect to="/dashboard" />;
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            // TODO Make sessions service
            try {
                const res = await service.fetchAPI<LoginPayload>(
                    '/sessions?expand[]=user',
                    {
                        method: 'POST',
                        body: {
                            email: email.toLowerCase().trim(),
                            password,
                            oneTimePassword: otpRequired ? otp : undefined,
                            impersonatedEmail,
                        },
                        silent: true,
                    }
                );

                authDispatch({
                    type: ActionType.LOGIN,
                    payload: res,
                });
            } catch (err) {
                setOTP('');

                if (err.type === APIErrorType.ONE_TIME_PASSWORD_REQUIRED) {
                    setOTPRequired(true);
                } else {
                    setOTPRequired(false);

                    notify({
                        type: MessageType.ERROR,
                        message: err.message,
                    });
                }

                throw err;
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid container alignItems="center" style={{ minHeight: '100vh' }}>
                <Grid item xs={12} sm={6} style={{ height: '100vh' }}>
                    <img
                        src={loginGraphic}
                        alt="Login Graphic"
                        style={{
                            display: 'block',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            {!otpRequired ? (
                                <>
                                    <Grid item>
                                        <FormField
                                            required
                                            label="Email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            type="email"
                                            autoFocus
                                        />
                                    </Grid>

                                    <Grid item>
                                        <PasswordField
                                            required
                                            label="Password"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            showPassword={showPassword}
                                            setShowPassword={setShowPassword}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Typography align="right">
                                            <Link
                                                component={RouterLink}
                                                to="/forgot_password"
                                            >
                                                Forgot your password?
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item>
                                        <Typography align="left">
                                            Please check your email for a one
                                            time password.
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <FormField
                                            required
                                            label="One Time Password"
                                            value={otp}
                                            onChange={(e) =>
                                                setOTP(e.target.value)
                                            }
                                            type="text"
                                            autoFocus
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Typography align="right">
                                            <Link component={RouterLink} to="/">
                                                Didn't receive an email? Try
                                                again
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {props.allowImpersonation && (
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        type="email"
                                        label="Login As"
                                        value={impersonatedEmail}
                                        onChange={(e) => {
                                            setImpersonatedEmail(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    size="large"
                                    style={{ height: '55px' }}
                                >
                                    Sign In
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box mt={4} />
                            </Grid>

                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Don't have an account?{' '}
                                    <Link href="/signup">Sign up now</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;
