import React, { useContext } from 'react';
import TagManager from 'react-gtm-module';
import './App.css';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';

import { GTM_ID } from './config';

import { Context as AuthContext, Store as AuthStore } from './context/Auth';
import { PersistOrderStore } from './context/PersistOrderStore';
import { Store as ModeStore } from './context/Mode';
import { Store as LiveApprovedStore } from './context/LiveApproved';
import { Display as NotificationDisplay } from './context/Notification';
import { Display as BackgroundSendDisplay } from './context/BackgroundSend';
import { Display as BackgroundDownloadDisplay } from './context/BackgroundDownload';

import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import SignupCheckEmail from './pages/SignupCheckEmail';
import VerifyEmail from './pages/VerifyEmail';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Maintenance from './pages/Maintenance';

TagManager.initialize({
    gtmId: GTM_ID,
});

const theme = createMuiTheme({
    typography: {
        fontFamily:
            'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Ubuntu,sans-serif',
        h1: {
            fontFamily: 'Roboto',
        },
        h2: {
            fontFamily: 'Roboto',
        },
        h3: {
            fontFamily: 'Roboto',
        },
        h4: {
            fontFamily: 'Roboto',
        },
        h5: {
            fontFamily: 'Roboto',
        },
        h6: {
            fontFamily: 'Roboto',
            fontWeight: 400,
        },
        subtitle1: {
            fontFamily: 'Roboto',
        },
        subtitle2: {
            fontFamily: 'Poppins',
            fontSize: '15px',
            fontWeight: 500,
        },
        body1: {
            fontFamily: 'Roboto',
            color: '#000',
            fontSize: '15px',
        },
    },
    palette: {
        type: 'light',
        background: {
            default: '#F7FAFC',
        },
        primary: {
            main: '#2f71eb',
            light: '#94BFFF',
            dark: '#7C8DB5',
        },
        secondary: {
            main: '#FD6270',
            contrastText: '#fff',
        },
        info: {
            main: '#2196f3',
            light: '#E0EBFB',
        },
        grey: {
            200: '#DBE9FB',
            A700: '#A3ACB9',
            A100: '#F0F6FD',
        },
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: '35px',
            },
        },
        MuiTableCell: {
            root: {
                fontFamily: 'Roboto',
            },
        },
        MuiInputLabel: {
            root: {
                color: '#707070',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
            },
        },
    },
});

const AuthRoute = (props: RouteProps) => {
    const { state } = useContext(AuthContext);

    if (!state.user) {
        return <Redirect to="/login" />;
    }

    return <Route {...props} />;
};

function App() {
    // ModeStore depends on the user being authenticated, so we have to put it underneath the AuthRoute.
    // More specifically, ModeStore calls useOrganization, which attempts to use the organization API on
    // the Base service. That fails if the user is not logged in, causing baseService to redirect to the login
    // page, and that loops.

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <AuthStore>
                    <NotificationDisplay>
                        <BackgroundSendDisplay>
                            <BackgroundDownloadDisplay>
                                <Switch>
                                    <AuthRoute path="/dashboard">
                                        <ModeStore>
                                            <LiveApprovedStore>
                                                <PersistOrderStore>
                                                    <Dashboard />
                                                </PersistOrderStore>
                                            </LiveApprovedStore>
                                        </ModeStore>
                                    </AuthRoute>

                                    <Route
                                        path="/signup/:userID"
                                        component={Signup}
                                    />

                                    <Route path="/signup" component={Signup} />

                                    <Route
                                        path="/signup_check_email"
                                        component={SignupCheckEmail}
                                    />

                                    <Route
                                        path="/verify_email/:token"
                                        component={VerifyEmail}
                                    />

                                    <Route
                                        path="/login/impersonate"
                                        render={(props) => {
                                            return <Login allowImpersonation />;
                                        }}
                                    />

                                    <Route path="/login" component={Login} />

                                    <Route
                                        path="/forgot_password"
                                        component={ForgotPassword}
                                    />

                                    <Route
                                        path="/reset_password/:token"
                                        component={ResetPassword}
                                    />

                                    <Route
                                        path="/maintenance"
                                        component={Maintenance}
                                    />

                                    <Redirect from="/" to="/login" />
                                </Switch>
                            </BackgroundDownloadDisplay>
                        </BackgroundSendDisplay>
                    </NotificationDisplay>
                </AuthStore>
            </Router>
        </ThemeProvider>
    );
}

export default App;
