import React from 'react';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import Inbox from '@material-ui/icons/InboxTwoTone';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';

interface EmptyTableDisplayProps {
    noDataMessage?: string;
    colSpan: number;
}

const EmptyTableDisplay: React.FC<EmptyTableDisplayProps> = ({
    colSpan,
    noDataMessage,
}) => {
    return (
        <TableRow>
            <TableCell
                colSpan={colSpan}
                style={{ backgroundColor: 'rgba(220,220,220, 0.03)' }}
                data-testid="empty-table-display"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                    gridGap={8}
                >
                    <Inbox fontSize="large" color="disabled" />
                    <Typography color="textSecondary" component={'div'}>
                        <Box fontWeight={400}>{noDataMessage ?? 'No data'}</Box>
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default EmptyTableDisplay;
