import React, { useContext } from 'react';
import { Context, MessageType } from '../context/Notification';
import copy from 'copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CopyIcon from '@material-ui/icons/FileCopy';

export interface CopyRawDataProps {
    value: any;
    show?: boolean;
}

const CopyRawData: React.FC<CopyRawDataProps> = ({
    value,
    show,
}) => {
    const { dispatch } = useContext(Context);

    if (!show) {
        return null;
    }

    const handleCopy = () => {
        copy(
            typeof value === 'string' ? value : JSON.stringify(value, null, 2),
            {
                format: 'text/plain',
            }
        );

        // HACK The dispatch must be done after the event handlers
        // for this event run because otherwise the clickaway listener
        // on the snackbar triggers immediately.
        setTimeout(() => {
            dispatch({
                type: MessageType.SUCCESS,
                message: 'Copied to clipboard.',
            });
        });
    };

    return (
        <Grid item>
            <IconButton size="small" onClick={handleCopy}>
                <CopyIcon fontSize="small" />
            </IconButton>
        </Grid>
    );
};

export default CopyRawData;
