import React, { ElementType } from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';
import { SvgIconProps as MuiSvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

type SvgIconProps = MuiSvgIconProps & {
    icon: ElementType;
};

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.grey.A700,
    },
}));

const SvgIcon: React.FC<SvgIconProps> = ({ icon, ...props }) => {
    const classes = useStyles();
    return (
        <MuiSvgIcon
            component={icon}
            {...props}
            className={`${classes.root} ${props.className}`}
        />
    );
};

export default SvgIcon;
