import React, { useCallback } from 'react';

import { label } from '../services/Contacts';

import { usePagination } from '../hooks/usePagination';

import { Route, Switch, useHistory } from 'react-router-dom';

import { useService as useReturnEnvelopeService } from '../services/ReturnEnvelopes';
import { formatTableDate, ListParams } from '../services/util';

import { ReturnEnvelopeRoutes } from '../routes';

import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import GridPaper from '../components/GridPaper';
import LinkButton from '../components/LinkButton';
import PageHeader from '../components/PageHeader';
import TopNav from '../components/TopNav';
import TableDisplay from '../components/TableDisplay';
import ViewReturnEnvelope from './ViewReturnEnvelope';
import CreateReturnEnvelope from './CreateReturnEnvelope';
import ViewReturnEnvelopeOrder from './ViewReturnEnvelopeOrder';

const ReturnEnvelopes: React.FC = () => {
    const history = useHistory();
    const service = useReturnEnvelopeService();

    const paginationFunc = useCallback(
        (listParams: ListParams) => service.list(listParams),
        [service]
    );

    const {
        data: envelopes,
        loading,
        searchText,
        pagination,
        searchQuery,
    } = usePagination(paginationFunc);

    const handleRowClick = (id: string) => {
        history.push(ReturnEnvelopeRoutes.HOME + `/${id}`);
    };

    return (
        <Switch>
            <Route exact path={ReturnEnvelopeRoutes.HOME}>
                <TopNav
                    showSearch
                    searchQuery={searchQuery}
                    searchText={searchText}
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader title="Return Envelopes">
                        <Grid item>
                            <LinkButton
                                data-testid="create-envelope-link"
                                variant="contained"
                                color="primary"
                                to={ReturnEnvelopeRoutes.CREATE}
                            >
                                Create Return Envelope
                            </LinkButton>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            show={loading}
                            columns={['ID', 'To', 'Available', 'Created At']}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {envelopes.map((envelope) => {
                                return (
                                    <TableRow
                                        key={envelope.id}
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            handleRowClick(envelope.id)
                                        }
                                    >
                                        <TableCell>{envelope.id}</TableCell>
                                        <TableCell>
                                            {label(envelope.to)}
                                        </TableCell>
                                        <TableCell>
                                            {envelope.available}
                                        </TableCell>
                                        <TableCell>
                                            {formatTableDate(
                                                envelope.createdAt
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route
                path={ReturnEnvelopeRoutes.CREATE}
                component={CreateReturnEnvelope}
            />
            <Route
                path={ReturnEnvelopeRoutes.VIEW_ORDER}
                component={ViewReturnEnvelopeOrder}
            />
            <Route
                path={ReturnEnvelopeRoutes.VIEW}
                component={ViewReturnEnvelope}
            />
        </Switch>
    );
};

export default ReturnEnvelopes;
