import React from 'react';

import { useService as useChequesService } from '../services/Cheques';

import OrderDetailsPage from '../components/OrderDetailsPage';

const ViewLetter = () => {
    const service = useChequesService();

    return <OrderDetailsPage title="Cheque Details" service={service} hasPDF />;
};

export default ViewLetter;
