import Grid from '@material-ui/core/Grid';
import React from 'react';
import DetailWrapper from './DetailWrapper';
import KeyValueInput from './KeyValueInput';

interface MergeVariablesInputProps {
    templateVars: string[];
    defaultVars?: Record<string, any>;
    mergeVars?: Record<string, string>;
    setMergeVars: (value: Record<string, string>) => void;
}

const MergeVariablesInput: React.FC<MergeVariablesInputProps> = ({
    setMergeVars,
    templateVars,
    defaultVars,
    mergeVars,
}) => {
    return (
        <DetailWrapper
            title="Merge Variables"
            direction="column"
            spacing={2}
            tooltip="Use these to personalize your mailing. Some may already be populated with contact information."
        >
            <Grid item>
                <KeyValueInput
                    keys={templateVars}
                    value={mergeVars}
                    setValue={setMergeVars}
                    defaultValues={defaultVars}
                />
            </Grid>
        </DetailWrapper>
    );
};

export default MergeVariablesInput;
