import React from 'react';
import Box from '@material-ui/core/Box';
import LinearProgress, {
    LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

// @see https://v4.mui.com/components/progress/

const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number }
) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

export default LinearProgressWithLabel;
