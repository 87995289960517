import React, { useContext, useMemo, useState } from 'react';

const ReFetchContext = React.createContext<boolean>(false);
const ReFetchUpdateContext = React.createContext<Function>(() => {});

export const useReFetchContext = () => {
    return {
        reFetch: useContext(ReFetchContext),
        toggleReFetch: useContext(ReFetchUpdateContext),
    };
};

export const ReFetchProvider: React.FC = ({ children }) => {
    const [fetch, setFetch] = useState<boolean>(false);

    const providerValue = useMemo(
        () => ({
            fetch,
            setFetch,
        }),
        [fetch, setFetch]
    );

    const updateFetch = () => {
        setFetch((prev) => !prev);
    };

    return (
        <ReFetchContext.Provider value={providerValue.fetch}>
            <ReFetchUpdateContext.Provider value={updateFetch}>
                {children}
            </ReFetchUpdateContext.Provider>
        </ReFetchContext.Provider>
    );
};
