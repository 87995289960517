import React, { useState, useContext, useEffect, FormEvent } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useModal } from '../hooks/useModal';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import DescriptionIcon from '@material-ui/icons/Description';

import {
    Template,
    useService as useTemplatesService,
} from '../services/Templates';

import { useOrganization } from '../services/Organization';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import RawData from '../components/RawData';
import HTMLEditor from '../components/HTMLEditor';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import EditField from '../components/EditField';
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';

const ViewTemplate = () => {
    const history = useHistory();

    const params = useParams<{ templateID: string }>();

    const service = useTemplatesService();

    const { dispatch } = useContext(NotificationContext);

    const [template, setTemplate] = useState<Template>();

    const [description, setDescription] = useState('');
    const [html, setHTML] = useState('');

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    const changed =
        description !== template?.description || html !== template.html;

    const org = useOrganization([history.location]);

    useEffect(() => {
        (async () => {
            try {
                setTemplate(undefined);

                const template = await service.tryGet(params.templateID);

                if (!template) {
                    dispatch({
                        type: MessageType.ERROR,
                        message: 'This template no longer exists.',
                    });

                    history.goBack();
                } else {
                    setTemplate(template);
                    setDescription(template.description || '');
                    setHTML(template.html);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [service, params.templateID, dispatch, history]);

    // TODO This is nearly identical to 'Copy Template to Live Mode'
    const copyTemplate =
        template &&
        (async () => {
            const prev = template;

            try {
                setTemplate(undefined);

                const copiedTemplate = await service.create({
                    description: `${template.description} (Copy)`,
                    html: template.html,
                    metadata: template.metadata,
                });

                dispatch({
                    type: MessageType.SUCCESS,
                    message: `Copied template to ${copiedTemplate.description}.`,
                });

                history.push(`/dashboard/templates/${copiedTemplate.id}`);
            } catch (err) {
                console.error(err);
            }

            setTemplate(prev);
        });

    const copyToLiveMode =
        (org?.stripeMailingsPaymentMethod || org?.externalPaymentMethod) &&
        template &&
        !template.live &&
        (async () => {
            const prev = template;

            try {
                // Force a loader to show to prevent the user from overclicking
                setTemplate(undefined);

                await service.create(
                    {
                        description: template.description,
                        html: template.html,
                        metadata: template.metadata,
                    },
                    true
                );

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Copied template to live mode.',
                });
            } catch (err) {
                console.error(err);
            }

            setTemplate(prev);
        });

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const temp = template;
        setTemplate(undefined);

        try {
            setTemplate(
                await service.update(temp!.id, {
                    description,
                    html,
                })
            );

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Updated template.',
            });
        } catch (err) {
            console.error(err);

            setTemplate(temp);
        }
    };

    const deleteTemplate = async () => {
        setTemplate(undefined);

        await service.delete(params.templateID);

        dispatch({
            type: MessageType.SUCCESS,
            message: 'Deleted template.',
        });

        history.push('/dashboard/templates');
    };

    return (
        <>
            <TopNav />
            <GridPaper container={false}>
                <form onSubmit={onSubmit}>
                    <ConfirmDeleteDialog
                        open={deleteOpen}
                        onClose={toggleDeleteModal}
                        title="Delete Template"
                        text="Are you sure you want to delete this template?"
                        confirm={deleteTemplate}
                    />
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            container
                            item
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="h5">
                                    Template Details
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={toggleDeleteModal}
                                            disabled={!template}
                                        >
                                            Delete Template
                                        </Button>
                                    </Grid>

                                    {template && template.metadata?.editorData && (
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() =>
                                                    history.push(
                                                        `/dashboard/templates/${template.id}/edit`
                                                    )
                                                }
                                            >
                                                Open Template Editor
                                            </Button>
                                        </Grid>
                                    )}

                                    {copyTemplate && (
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={copyTemplate}
                                            >
                                                Copy Template
                                            </Button>
                                        </Grid>
                                    )}

                                    {copyToLiveMode && (
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={copyToLiveMode}
                                            >
                                                Copy to Live Mode
                                            </Button>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={!template || !changed}
                                        >
                                            Save Template
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Alert variant="outlined" color="info">
                                Modifying a template will not affect orders that
                                have already been generated with an older
                                version.
                            </Alert>
                        </Grid>

                        {template ? (
                            <Grid container item direction="column" spacing={2}>
                                <EditField
                                    item
                                    title="Description"
                                    icon={DescriptionIcon}
                                    component={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                                <EditField
                                    item
                                    title="HTML"
                                    icon={DescriptionIcon}
                                    component={HTMLEditor}
                                    fullWidth
                                    variant="outlined"
                                    value={html}
                                    onChange={(e) => {
                                        setHTML(e.target.value);
                                    }}
                                />
                                <Grid item>
                                    <RawData obj={template} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                item
                                style={{ minHeight: '20vh' }}
                                alignItems="center"
                                justify="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default ViewTemplate;
