import React, { useState, PropsWithoutRef } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Contact } from '../services/Contacts';

import ContactInput from './ContactInput';
import UploadContactsDialog from './UploadContactsDialog';

const ContactOrCSV = (
    props: PropsWithoutRef<{
        label: string;
        contacts: Contact[];
        setContacts: (c: Contact[]) => void;
        setUploadedCSV?: (c: boolean) => void;
        required?: boolean;
        sampleURL?: string;
    }>
) => {
    const {
        label,
        contacts,
        setContacts,
        setUploadedCSV,
        required,
        sampleURL,
    } = props;

    const [uploadOpen, setUploadOpen] = useState(false);
    const [selectedSingleContact, setSelectedSingleContact] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(false);

    return (
        <>
            <UploadContactsDialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                onCompleted={(c: Contact[]) => {
                    setUploadedFile(true);
                    setContacts(c);
                    setUploadedCSV && setUploadedCSV(true);
                }}
                sampleURL={sampleURL}
            />
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={7}>
                    <ContactInput
                        label={label}
                        contact={contacts.length === 1 ? contacts[0] : null}
                        setContact={(c) => {
                            if (c) {
                                setContacts([c]);
                                setSelectedSingleContact(true);
                                setUploadedCSV && setUploadedCSV(false);
                            } else {
                                setContacts([]);
                                setSelectedSingleContact(false);
                            }
                        }}
                        required={required && contacts.length === 0}
                        disabled={uploadedFile}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Typography align="center">or</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={selectedSingleContact}
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                            if (uploadedFile) {
                                setUploadedFile(false);
                                setContacts([]);
                            } else {
                                setUploadOpen(true);
                            }
                        }}
                    >
                        {uploadedFile ? 'Cancel Upload' : 'Upload Recipients'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactOrCSV;
