import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { SIGNUP_WEBHOOK_URL, LIVE_APPROVAL_WEBHOOK_URL } from '../config';

const BASE_URL = process.env.REACT_APP_API_URL;

export interface ListResponse<T> {
    skip: number;
    limit: number;
    totalCount: number;
    data: T[];
}

export interface DeleteResponse {
    id: string;
    deleted: true;
}

export enum APIErrorType {
    TOKEN_EXPIRED = 'token_expired_error',
    NOT_SUBSCRIBED = 'org_not_subscribed_error',
    ONE_TIME_PASSWORD_REQUIRED = 'one_time_password_required_error',
}

export interface APIErrorResponse {
    object: 'error';
    error: {
        type: string;
        message: string;
    };
}

// We allow an arbitrary object to be passed in for the body
export type APIRequestInit = Omit<RequestInit, 'body'> & {
    body?: RequestInit['body'] | Record<string, any>;
};

export class APIRequestError extends Error {
    public type: string;
    public status: number;

    constructor(status: number, type: string, message: string) {
        super(message);

        this.name = type;
        this.type = type;
        this.status = status;
    }
}

export interface ListParams {
    skip: number;
    limit: number;
    search?: string;
}

// TODO Refactor into common module across all of our APIs
export const flatten = (
    obj: object,
    parentKey: string = '',
    result: Record<string, any> = {}
) => {
    for (const [key, value] of Object.entries(obj)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (value instanceof Date) {
            // HACK dates are empty objects with no keys
            // Leave dates as is when flattening
            result[newKey] = value;
        } else if (value !== null && typeof value === 'object') {
            flatten(value, newKey, result);
        } else {
            result[newKey] = value;
        }
    }

    return result;
};

export const unflatten = (obj: Record<string, unknown>) => {
    const result: Record<string, any> = {};

    for (const [key, value] of Object.entries(obj)) {
        // Skip empty keys
        if (key === '') {
            continue;
        }

        if (key.indexOf('.') === -1) {
            // Already flattened
            result[key] = value;
            continue;
        }

        const keys = key.split('.');

        let prev = result;

        // Iteratively create all the nested objects
        for (const innerKey of keys.slice(0, -1)) {
            if (typeof prev[innerKey] !== 'object') {
                prev[innerKey] = {};
            }

            prev = prev[innerKey] as typeof prev;
        }

        prev[keys[keys.length - 1]] = value;
    }

    return result;
};

export const formFlatten = (obj: Record<string, unknown>) => {
    const flatObject = flatten(obj);
    const resultObject: Record<string, any> = {};

    for (const [key, value] of Object.entries(flatObject)) {
        const newKey = key
            .split('.')
            .map((part, index) => (index > 0 ? `[${part}]` : part))
            .join('');

        resultObject[newKey] = value;
    }

    return resultObject;
};

const convertDatesInPlace = (obj: any) => {
    if (typeof obj !== 'object') {
        return;
    }

    if (obj === undefined || obj === null) {
        return;
    }

    if (Array.isArray(obj)) {
        obj.forEach(convertDatesInPlace);
        return;
    }

    for (const [key, value] of Object.entries(obj)) {
        if (
            (key === 'createdAt' ||
                key === 'updatedAt' ||
                key === 'sendDate' ||
                key === 'startDate' ||
                key === 'endDate') &&
            typeof value === 'string'
        ) {
            obj[key] = new Date(value);
            continue;
        }

        convertDatesInPlace(value);
    }
};

// TODO Maybe we can use decorators on type T to validate
export async function fetchAPI<T>(
    path: string,
    init?: APIRequestInit
): Promise<T> {
    init = init || {};

    init.mode = 'cors';

    if (init.body && !(init.body instanceof FormData)) {
        if (typeof init.body !== 'string') {
            init.body = JSON.stringify(init.body);
        }

        const headers: Record<string, string> = Array.isArray(init.headers)
            ? Object.fromEntries(init.headers)
            : init.headers;

        init.headers = {
            ...headers,
            'Content-Type': headers?.['Content-Type'] || 'application/json',
        };
    }

    const resp = await fetch(`${BASE_URL}${path}`, init as RequestInit);
    const value = await resp.json();

    if (resp.status >= 400) {
        throw new APIRequestError(
            resp.status,
            value.error.type,
            value.error.message
        );
    }

    // Convert 'createdAt' and 'updatedAt' to dates
    convertDatesInPlace(value);

    return value;
}

// Will ensure that the returned value only changes once per 'timeout'
// across renders.
export const useDebouncedValue = (value: any, timeout: number) => {
    const [state, setState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setState(value), timeout);

        return () => clearTimeout(handler);
    }, [value, timeout]);

    return state;
};

// Taken from
// https://stackoverflow.com/questions/1688657/how-do-i-extract-google-analytics-campaign-data-from-their-cookie-with-javascrip
export const getGoogleAnalyticsData = () => {
    let data: {
        ga_source?: string;
        ga_campaign?: string;
        ga_medium?: string;
        ga_content?: string;
        ga_term?: string;
    } = {};

    let gc = '';
    let c_name = '__utmz';

    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + '=');

        if (c_start !== -1) {
            c_start = c_start + c_name.length + 1;

            let c_end = document.cookie.indexOf(';', c_start);

            if (c_end === -1) c_end = document.cookie.length;

            gc = unescape(document.cookie.substring(c_start, c_end));
        }
    }

    if (gc !== '') {
        let y = gc.split('|');

        for (let i = 0; i < y.length; i++) {
            if (y[i].indexOf('utmcsr=') >= 0)
                data.ga_source = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmccn=') >= 0)
                data.ga_campaign = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmcmd=') >= 0)
                data.ga_medium = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmcct=') >= 0)
                data.ga_content = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmctr=') >= 0)
                data.ga_term = y[i].substring(y[i].indexOf('=') + 1);
        }
    }

    return data;
};

// Factor into some 'events' file
export const handleSignup = async (data: {
    name: string;
    email: string;
    organizationName: string;
    phoneNumber: string;
    countryCode?: string;
}) => {
    if (!SIGNUP_WEBHOOK_URL) {
        console.error('Missing webhook URL for signup.');
        return;
    }

    // TODO Refactor into some 'getIP' function
    const ip = await (async (): Promise<string> => {
        try {
            const res = await fetch('https://api.ipify.org/?format=json');
            return (await res.json()).ip;
        } catch (err) {
            return '(unknown)';
        }
    })();

    await fetch(SIGNUP_WEBHOOK_URL, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
            ...data,
            ...getGoogleAnalyticsData(),
            ip,
        }),
    });
};

export const handleNotApprovedLiveMode = async (data: {
    name: string;
    email: string;
    organizationName: string;
}) => {
    if (!LIVE_APPROVAL_WEBHOOK_URL) {
        console.error('Missing webhook URL for live approval.');
        return;
    }

    await fetch(LIVE_APPROVAL_WEBHOOK_URL, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
            ...data,
            ...getGoogleAnalyticsData(),
        }),
    });
};

export const createAnalyticsEvent = (params: {
    event: string;
    eventProps: {
        category: string;
        action: string;
        label?: string;
        value?: string;
    };
}) => {
    (window as any).dataLayer.push(params);
};

export async function asyncMapChunks<T, R>(
    array: T[],
    chunkSize: number,
    fn: (value: T[]) => Promise<R>
) {
    // FIXME Use something akin to Awaited
    const res: R[] = [];

    for (let i = 0; i < Math.ceil(array.length / chunkSize); ++i) {
        res.push(await fn(array.slice(i * chunkSize, (i + 1) * chunkSize)));
    }

    return res;
}

export const downloadData = (
    payload: string,
    fileName: string,
    mimeType = 'text/plain'
) => {
    const tempTag = document.createElement('a');
    tempTag.download = fileName;
    tempTag.href = `data:${mimeType},${encodeURIComponent(payload)}`;
    tempTag.style.display = 'none';
    document.body.appendChild(tempTag);
    tempTag.click();
    document.body.removeChild(tempTag);
};

export const formatTableDate = (date: Date) => {
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    })}`;
};

/**
 * Adds data to a setState set
 * @param data Data to add
 * @param setState Set State function for the set
 */
export const addToSet = <T>(
    data: T,
    setState: React.Dispatch<React.SetStateAction<Set<T>>>
) => {
    setState((prev) => new Set([...Array.from(prev), data]));
};

/**
 * Removes data from a setState set
 * @param data Data to be removed
 * @param setState Set State function for the set
 */
export const removeFromSet = <T>(
    data: T,
    setState: React.Dispatch<React.SetStateAction<Set<T>>>
) => {
    setState(
        (prev) => new Set([...Array.from(prev)].filter((x) => x !== data))
    );
};

export const createURLParams = (params: ListParams) => {
    return new URLSearchParams(
        Object.entries(params).map(([key, value]) => {
            return [key, value.toString()];
        })
    );
};

export const createOrderStartDateQuery = (startDate: Date, endDate: Date) => {
    return JSON.stringify({ sendDate: { $gte: startDate, $lte: endDate } });
};

export const formatStartEndDates = (
    startDate: Date,
    endDate: Date,
    format: string
) => {
    return `${dayjs(startDate).format(format)} - ${dayjs(endDate).format(
        format
    )}`;
};

export const baseSearchQuery = (search: string) => {
    return { $text: { $search: `"${search.trim()}"` } };
};
