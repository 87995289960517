import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { OrderMailingClass } from '../services/Base';
import TooltipWrapper from './TooltipWrapper';
import StrictEnumSelect from './StrictEnumSelect';
import MailingClassExpressTooltip from './MailingClassExpressTooltip';

type MailingClassSelectorProps = Omit<GridProps, 'onChange'> & {
    mailingClass: OrderMailingClass;
    onChange: (service: OrderMailingClass) => void;
    disabled?: boolean;
};

const MailingClassSelector: React.FC<MailingClassSelectorProps> = ({
    mailingClass,
    onChange,
    disabled,
    ...props
}) => {
    return (
        <TooltipWrapper
            title={<MailingClassExpressTooltip />}
            disabled={disabled}
        >
            <Grid item {...props}>
                <StrictEnumSelect
                    title="Mailing Class"
                    valueLabels={[
                        [OrderMailingClass.FIRST_CLASS, 'First Class'],
                        [OrderMailingClass.STANDARD_CLASS, 'Standard Class'],
                    ]}
                    onChange={onChange}
                    value={mailingClass}
                    disabled={disabled}
                />
            </Grid>
        </TooltipWrapper>
    );
};

export default MailingClassSelector;
