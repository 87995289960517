import { useMemo } from 'react';
import { Order, Service as OrdersService } from './Orders';

import { formFlatten } from './util';
import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
    OrderExtraService,
} from './Base';

import { Contact } from '../services/Contacts';

export enum CurrencyCode {
    CAD = 'CAD',
    USD = 'USD',
}

export interface Cheque extends Order {
    object: 'cheque';

    // is required in cheques and not in others
    from: Contact;

    bankAccount: string;

    amount: number;

    currencyCode: CurrencyCode;

    // Cheque number
    number: number;

    memo?: string;
    message?: string;

    mergeVariables?: Record<string, any>;

    letterTemplate?: string;
    letterPDF?: File;

    extraService?: OrderExtraService;
}

export type CreateParams = Omit<
    Cheque,
    | 'sendDate'
    | 'to'
    | 'from'
    | 'status'
    | 'number'
    | 'currencyCode'
    | keyof Resource
> &
    ResourceCreateParams & {
        to: string;
        from: string;
        sendDate?: Date;
        number?: number;
    };

export class Service extends OrdersService<Cheque> {
    async create(data: CreateParams) {
        if (data.letterPDF) {
            const formData = new FormData();

            for (const [key, value] of Object.entries(formFlatten(data))) {
                if (value === undefined) {
                    continue;
                }

                formData.set(key, value as any);
            }

            formData.set('letterPDF', data.letterPDF);

            return await this.base.fetchAPI<Cheque>(`/${this.route}`, {
                method: 'POST',
                body: formData,
            });
        }

        return await this.base.fetchAPI<Cheque>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async cancel(id: string) {
        return await this.base.fetchAPI<Cheque>(`/${this.route}/${id}`, {
            method: 'DELETE',
        });
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'cheques'), [base]);
};
