import React, { useEffect, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';

import { APIErrorResponse, downloadData } from '../services/util';
import { Contact, label } from '../services/Contacts';

import { Order } from '../services/Orders';

import Button from './Button';

export enum BatchSendStatus {
    READY = 'ready',
    IN_PROGRESS = 'in_progress',
    ERROR = 'error',
    COMPLETED = 'completed',
}

export interface BatchSendState<T extends Order> {
    status: BatchSendStatus;
    processedCount: number;
    results: (T | APIErrorResponse)[];
}

export interface BatchSendProps<T extends Order> {
    createBatch(params: {
        toContacts: Contact[];
        handleProgress: (count: number) => void;
    }): Promise<(T | APIErrorResponse)[]>;

    toContacts: Contact[];

    state: BatchSendState<T>;
    setState: (state: SetStateAction<BatchSendState<T>>) => void;
}

function BatchSend<T extends Order>(props: BatchSendProps<T>) {
    const { state, toContacts, setState, createBatch } = props;

    useEffect(() => {
        if (state.status !== BatchSendStatus.IN_PROGRESS) {
            return;
        }

        (async () => {
            try {
                const res = await createBatch({
                    toContacts: toContacts,
                    handleProgress: (count: number) =>
                        setState((state) => ({
                            ...state,
                            processedCount: state.processedCount + count,
                        })),
                });

                setState((state) => ({
                    ...state,
                    status: BatchSendStatus.COMPLETED,
                    results: res,
                }));
            } catch (err: any) {
                setState((state) => ({
                    ...state,
                    status: BatchSendStatus.ERROR,
                }));

                console.error(err);
            }
        })();
    }, [state.status, toContacts, setState, createBatch]);

    if (state.status === BatchSendStatus.READY) {
        return null;
    }

    const downloadReport = () => {
        const headers = `ID,To,Status`;
        const rows = state.results.map((o, i) => {
            const name = label(toContacts[i]);
            const csvSafeName = `"${(
                name ?? 'Contact at Row: ' + (i + 1).toString()
            ).replace(/"/g, '\\"')}"`;

            if (o.object === 'error') {
                return `,${csvSafeName},Error: ${o.error.message}`;
            }

            return `${o.id},${csvSafeName},Success`;
        });

        const payload = headers + '\n' + rows.join('\n');

        downloadData(payload, 'batch_send.csv', 'text/csv');
    };

    return (
        <Paper variant="outlined">
            <Box margin={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={9}>
                        <LinearProgress
                            color={
                                state.status === BatchSendStatus.ERROR
                                    ? 'secondary'
                                    : 'primary'
                            }
                            variant="determinate"
                            value={
                                (state.processedCount / toContacts.length) * 100
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    state.status !== BatchSendStatus.COMPLETED
                                }
                                onClick={downloadReport}
                            >
                                Download Report
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

export default BatchSend;
