import React, { useEffect, useContext } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { useService as useUsersService } from '../services/Users';

const VerifyEmail = () => {
    const history = useHistory();
    const service = useUsersService();

    const { token } = useParams<{ token: string }>();
    const { dispatch } = useContext(NotificationContext);

    useEffect(() => {
        (async () => {
            try {
                await service.verifyEmail(token);

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Verified email successfully. Please log in.',
                });

                history.push('/login');
            } catch (err) {
                console.error(err);
            }
        })();
        // Don't re-render when history or dispatch changes
        // Not including them in dependency array
        // eslint-disable-next-line
    }, [service, token]);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100vw"
            height="100vh"
        >
            <p>Please wait...</p>
        </Box>
    );
};

export default VerifyEmail;
