import React from 'react';
import { useHistory } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

interface TableRowLinkProps {
    route: string;
}

const useStyles = makeStyles(() => ({
    root: {
        '& :hover': {
            cursor: 'pointer',
        },
    },
}));

const TableRowLink: React.FC<TableRowLinkProps> = ({ route, children }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => history.push(route);

    return (
        <TableRow onClick={handleClick} hover className={classes.root}>
            {children}
        </TableRow>
    );
};

export default TableRowLink;
