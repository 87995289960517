import React, { useCallback } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';
import { useModal } from '../hooks/useModal';

import { CreateOrderOriginProvider } from '../context/CreateOrderOrigin';
import { ContactRoutes } from '../routes';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import {
    fullName,
    useService as useContactsService,
} from '../services/Contacts';

import { formatTableDate, ListParams } from '../services/util';

import CreateContact from './CreateContact';
import ViewContact from './ViewContact';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import UploadContactsDialog from '../components/UploadContactsDialog';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import { CreateContactProvider } from '../context/CreateContact';

const Contacts = () => {
    const history = useHistory();

    const service = useContactsService();

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: contacts,
        loading,
        searchQuery,
        searchText,
        getData: getContacts,
        pagination,
    } = usePagination(paginationFunc);

    const {
        isModalOpen: uploadContactsOpen,
        toggleModal: toggleUploadContactsModal,
    } = useModal();

    const handleUploadComplete = async () => {
        await getContacts();
    };

    return (
        <>
            <UploadContactsDialog
                open={uploadContactsOpen}
                onClose={toggleUploadContactsModal}
                onCompleted={handleUploadComplete}
            />

            <CreateContactProvider>
                <Switch>
                    <Route exact path={ContactRoutes.HOME}>
                        <TopNav
                            showSearch
                            searchText={searchText}
                            searchQuery={searchQuery}
                        />
                        <GridPaper
                            direction="column"
                            spacing={2}
                            data-testid="contacts-page"
                        >
                            <PageHeader title="Contacts">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            history.push(ContactRoutes.CREATE);
                                        }}
                                    >
                                        Create Contact
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={toggleUploadContactsModal}
                                    >
                                        Upload Contacts
                                    </Button>
                                </Grid>
                            </PageHeader>

                            <Grid item>
                                <TableDisplay
                                    columns={[
                                        'Name',
                                        'Company',
                                        'Email',
                                        'Address',
                                        'Created At',
                                    ]}
                                    show={loading}
                                    pagination={pagination}
                                    showEmptyTable
                                >
                                    {contacts.map((contact) => {
                                        return (
                                            <TableRow
                                                key={contact.id}
                                                hover
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    history.push(
                                                        `${ContactRoutes.HOME}/${contact.id}`
                                                    );
                                                }}
                                            >
                                                <TableCell>
                                                    {fullName(contact)}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.companyName || ''}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.email || ''}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.addressLine1}
                                                </TableCell>
                                                <TableCell>
                                                    {formatTableDate(
                                                        contact.createdAt
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableDisplay>
                            </Grid>
                        </GridPaper>
                    </Route>
                    <Route
                        path={ContactRoutes.CREATE}
                        render={() => (
                            <CreateOrderOriginProvider>
                                <CreateContact />
                            </CreateOrderOriginProvider>
                        )}
                    />
                    <Route path={ContactRoutes.VIEW} component={ViewContact} />
                </Switch>
            </CreateContactProvider>
        </>
    );
};

export default Contacts;
