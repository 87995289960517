import React, { FormEvent, useContext, useState } from 'react';
import { CreateOrderBody, ReturnEnvelope } from '../services/ReturnEnvelopes';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Field from './Field';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { Context } from '../context/Mode';
import { useReFetchContext } from '../context/ReFetchContext';

interface CreateReturnEnvelopeOrdersProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: CreateOrderBody, id: string) => Promise<void>;
    envelope: ReturnEnvelope;
    loading: boolean;
}

const CreateReturnEnvelopeOrders: React.FC<CreateReturnEnvelopeOrdersProps> = ({
    onClose,
    open,
    onSubmit,
    loading,
    envelope,
}) => {
    const { live } = useContext(Context);
    const { toggleReFetch } = useReFetchContext();
    const [quantity, setQuantity] = useState<number>(0);
    const [description, setDescription] = useState('');

    const handleQuantityChange = (e: string) => {
        // html input type=number should ensure we only receive a number
        setQuantity(Number(e));
    };

    const handleClose = () => {
        setDescription('');
        setQuantity(0);
        onClose();
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // JSDom (for testing) doesn't support HTML5 Validation
        if (quantity < 5000) return;
        const data = {
            description: description === '' ? undefined : description,
            // Form validation should ensure this is set to a number value
            quantityOrdered: quantity,
        };
        await onSubmit(data, envelope.id);
        toggleReFetch();
        handleClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            data-testid="envelope-orders-modal"
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    How many return envelopes would you like to order?
                </DialogTitle>
                {!live && (
                    <DialogContent>
                        <Alert variant="outlined" color="info">
                            You are in test mode. This will not order any real
                            return envelopes.
                        </Alert>
                    </DialogContent>
                )}
                <DialogContent>
                    <Field
                        label="Description"
                        value={description}
                        type="text"
                        id="description"
                        name="description"
                        InputProps={{
                            inputProps: {
                                'data-testid': 'envelope-order-description',
                            },
                        }}
                        setValue={setDescription}
                    />
                </DialogContent>
                <DialogContent>
                    <Field
                        label="Quantity"
                        value={quantity > 0 ? quantity : ''}
                        type="number"
                        name="quantity"
                        id="quantity"
                        setValue={handleQuantityChange}
                        required
                        InputProps={{
                            endAdornment: (
                                <Tooltip
                                    title="A minimum quantity of 5000 is required"
                                    data-testid="envelope-order-tooltip"
                                >
                                    <HelpIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </Tooltip>
                            ),
                            inputProps: {
                                min: 5000,
                                'data-testid': 'envelope-order-quantity',
                            },
                        }}
                    />
                </DialogContent>

                <DialogContent>
                    <Grid container justify="center" spacing={1}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                                size="large"
                                fullWidth
                                disabled={false}
                                data-testid="cancel-envelope-order"
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                disabled={loading}
                                type="submit"
                                data-testid="place-envelope-order"
                            >
                                Place Order
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default CreateReturnEnvelopeOrders;
