import React, { useState, useContext, FormEvent } from 'react';

import { useHistory } from '../hooks/useHistory';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useService as useTemplatesService } from '../services/Templates';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import HTMLEditor from '../components/HTMLEditor';
import Field from '../components/Field';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import CreateResourceActions from '../components/CreateResourceActions';
import { TemplateRoutes } from '../routes';

export const CreateTemplate = () => {
    const history = useHistory();

    const service = useTemplatesService();

    const { dispatch } = useContext(NotificationContext);

    const [description, setDescription] = useState('');
    const [useTemplateEditor, setUseTemplateEditor] = useState(true);
    const [html, setHTML] = useState('');

    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            const template = await service.create({
                description,
                html: html.length === 0 ? '<html></html>' : html,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Created template.',
            });

            if (useTemplateEditor) {
                history.push(`${TemplateRoutes.HOME}/${template.id}/edit`);
            } else {
                history.push(TemplateRoutes.HOME);
            }
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    };

    return (
        <>
            <TopNav />
            <GridPaper container={false}>
                <form onSubmit={onSubmit}>
                    <Box mb={4}>
                        <Typography variant="h5" gutterBottom>
                            Create a Template
                        </Typography>
                    </Box>

                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <Field
                                required
                                label="Description"
                                value={description}
                                setValue={setDescription}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!useTemplateEditor}
                                        onChange={(e) => {
                                            setUseTemplateEditor(
                                                !e.target.checked
                                            );
                                        }}
                                    />
                                }
                                label="Create with raw HTML instead of using our Template Editor"
                            />
                        </Grid>
                        {!useTemplateEditor && (
                            <Grid item>
                                <HTMLEditor
                                    variant="outlined"
                                    label="HTML"
                                    value={html}
                                    onChange={(e) => setHTML(e.target.value)}
                                />
                            </Grid>
                        )}
                        <Grid item xs={10}>
                            <CreateResourceActions disabled={loading} />
                        </Grid>
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default CreateTemplate;
