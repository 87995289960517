import React, { useState, useContext, useCallback } from 'react';

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';
import { useModal } from '../hooks/useModal';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import DeleteIcon from '@material-ui/icons/Delete';

import { User } from '../context/Auth';
import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { useService as useUsersService } from '../services/Users';
import { useService as useInvitesService } from '../services/Invites';
import { useOrganization } from '../services/Organization';

import ButtonDisabledTooltip from '../components/ButtonDisabledTooltip';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';

import InviteUser from './InviteUser';
import TableDisplay from '../components/TableDisplay';
import { ListParams } from '../services/util';

const Users = () => {
    const match = useRouteMatch();
    const history = useHistory();

    const service = useUsersService();
    const invService = useInvitesService();

    const { dispatch } = useContext(NotificationContext);

    const org = useOrganization([history.location]);

    const paginationFunc = useCallback(
        (params: ListParams) => {
            return service.list(params);
        },
        [service]
    );

    const { data: users, loading, pagination } = usePagination(paginationFunc);

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteOpen } =
        useModal();
    const [userToDelete, setUserToDelete] = useState<User>();
    const [deleting, setDeleting] = useState(false);

    const showDelete = (user: User) => {
        setUserToDelete(user);
        toggleDeleteOpen();
    };

    const confirmDelete = async () => {
        if (!userToDelete) {
            return;
        }

        try {
            setDeleting(true);

            await invService.delete(userToDelete.id);

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Deleted user.',
            });
        } catch (err) {
            console.error(err);
        }

        setDeleting(false);
    };

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <ConfirmDeleteDialog
                    open={deleteOpen}
                    onClose={toggleDeleteOpen}
                    confirm={confirmDelete}
                    title="Delete User"
                    text={
                        <span>
                            Are you sure you want to delete the user{' '}
                            <strong>{userToDelete?.email}</strong>?
                        </span>
                    }
                />
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <Grid item>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="h5">Users</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <ButtonDisabledTooltip
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                history.push(
                                                    `${match.path}/create`
                                                );
                                            }}
                                            disabled={
                                                !org ||
                                                (!org.stripeSubscription &&
                                                    !org.allowInvitingUsersWithoutSubscribing)
                                            }
                                            TooltipProps={{
                                                title: 'Subscribe to invite users',
                                            }}
                                        >
                                            Invite User
                                        </ButtonDisabledTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableDisplay
                            columns={[
                                'Name',
                                'Email',
                                'Phone Number',
                                'Status',
                                'Created At',
                                'Actions',
                            ]}
                            show={loading || deleting}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {users.map((user) => {
                                return (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            {user.phoneNumber || ''}
                                        </TableCell>
                                        <TableCell>
                                            {user.pendingInvite ? (
                                                <Box color="primary.main">
                                                    Invited
                                                </Box>
                                            ) : (
                                                <Box color="success.main">
                                                    Registered
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {user.createdAt.toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    showDelete(user);
                                                }}
                                                disabled={!user.pendingInvite}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={`${match.path}/create`} component={InviteUser} />
        </Switch>
    );
};

export default Users;
