import React, {
    useState,
    useContext,
    useMemo,
    FormEvent,
    useCallback,
} from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import { Context as ModeContext } from '../context/Mode';
import { useCreateLetterContext } from '../context/CreateLetter';
import { useHistory } from '../hooks/useHistory';

import {
    AddressPlacement,
    CreateParams,
    EnvelopeType,
    Letter,
    useService as useLettersService,
} from '../services/Letters';
import { Contact } from '../services/Contacts';
import { findVariables } from '../services/Templates';
import { flatten, unflatten } from '../services/util';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import SelectTemplate from '../components/SelectTemplate';
import GoBackButton from '../components/GoBackButton';
import FileUpload from '../components/FileUpload';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ContactOrCSV from '../components/ContactOrCSV';
import ContactInput from '../components/ContactInput';
import SelectReturnEnvelope from '../components/SelectReturnEnvelope';
import MergeVariablesInput from '../components/MergeVariablesInput';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import ExtraServiceSelector from '../components/ExtraServiceSelector';
import BatchSend, {
    BatchSendProps,
    BatchSendState,
    BatchSendStatus,
} from '../components/BatchSend';
import MailingClassSelector from '../components/MailingClassSelector';
import StrictEnumSelect from '../components/StrictEnumSelect';
import { LetterRoutes } from '../routes';
import { useResetCreateOrderState } from '../hooks/useResetCreateOrderState';

const emptyKeysRemoved = (o: Record<string, unknown>) => {
    const filteredEntries = Object.entries(o).filter((v) => v[0] !== '');

    for (let i = 0; i < filteredEntries.length; ++i) {
        const [_, value] = filteredEntries[i];

        if (typeof value === 'object' && value !== null) {
            filteredEntries[i][1] = emptyKeysRemoved(
                value as Record<string, unknown>
            );
        }
    }

    return Object.fromEntries(filteredEntries);
};

const CreateLetter = () => {
    const history = useHistory();

    const service = useLettersService();

    const { live } = useContext(ModeContext);
    const { dispatch } = useContext(NotificationContext);

    const {
        color,
        description,
        doubleSided,
        envelopeType,
        express,
        file,
        fromContact,
        insertBlankPage,
        loading,
        mailingClass,
        mergeVars,
        returnEnvelope,
        template,
        toContacts,
        uploadedCSV,
        extraService,
        extraServiceForm,
        perforateFirstPage,
        setPerforateFirstPage,
        setExtraService,
        resetState,
        setColor,
        setDescription,
        setDoubleSided,
        setEnvelopeType,
        setExpress,
        setFile,
        setFromContact,
        setInsertBlankPage,
        setLoading,
        setMailingClass,
        setMergeVars,
        setReturnEnvelope,
        setTemplate,
        setToContacts,
        setUploadedCSV,
    } = useCreateLetterContext();

    useResetCreateOrderState(resetState);

    const templateVars = useMemo<string[]>(
        () => (template ? findVariables(template.html) : []),
        [template]
    );

    // TODO: Fix this overwriting already entered values for merge vars
    const defaultVars = useMemo(
        () =>
            flatten({
                to: toContacts.length > 0 ? toContacts[0] : {},
                from: fromContact,
            }),
        [toContacts, fromContact]
    );

    const [batchSendState, setBatchSendState] = useState<
        BatchSendState<Letter>
    >({
        processedCount: 0,
        status: BatchSendStatus.READY,
        results: [],
    });

    const createBatch = useCallback(
        async (
            params: Parameters<BatchSendProps<Letter>['createBatch']>[0]
        ) => {
            const mergeVariables = mergeVars && unflatten(mergeVars);
            const addressPlacement = insertBlankPage
                ? AddressPlacement.INSERT_BLANK_PAGE
                : AddressPlacement.TOP_FIRST_PAGE;

            return await service.createBatch({
                template: template ? template.id : undefined,
                pdf: file ?? undefined,
                express,
                extraService,
                envelopeType,
                returnEnvelope: returnEnvelope?.id,
                data: params.toContacts.map((c) => ({
                    description,
                    addressPlacement,
                    doubleSided,
                    color,
                    perforatedPage: perforateFirstPage ? 1 : undefined,
                    from: fromContact!.id,
                    to: c.id,
                    mergeVariables: emptyKeysRemoved({
                        ...mergeVariables,
                        ...c.metadata,
                        to: {
                            ...mergeVariables?.to,
                            ...c,
                        },
                    }),
                    mailingClass,
                })),
                handleProgress: params.handleProgress,
            });
        },
        [
            mergeVars,
            service,
            template,
            file,
            express,
            description,
            fromContact,
            color,
            doubleSided,
            perforateFirstPage,
            insertBlankPage,
            extraService,
            returnEnvelope,
            envelopeType,
            mailingClass,
        ]
    );

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            (async () => {
                if (
                    toContacts.length === 0 ||
                    !fromContact ||
                    (!template && !file)
                ) {
                    // This should be impossible given that both fields are required
                    return;
                }

                try {
                    setLoading(true);

                    const addressPlacement: AddressPlacement = insertBlankPage
                        ? AddressPlacement.INSERT_BLANK_PAGE
                        : AddressPlacement.TOP_FIRST_PAGE;

                    const send = async (to: Contact) => {
                        let mergeVariables = mergeVars && unflatten(mergeVars);
                        const letter: CreateParams = {
                            description,
                            to: to.id,
                            from: fromContact.id,
                            color,
                            doubleSided,
                            addressPlacement,
                            envelopeType,
                            returnEnvelope: returnEnvelope?.id,
                            extraService,
                            express,
                            mailingClass: express ? undefined : mailingClass,
                            perforatedPage: perforateFirstPage ? 1 : undefined,
                        };

                        if (to.metadata) {
                            mergeVariables = {
                                ...mergeVariables,
                                ...to.metadata,
                            };
                        }

                        if (uploadedCSV) {
                            // Delete the 'to' object from the mergeVariables so
                            // that its sourced from our contact data.
                            delete mergeVariables?.to;
                        }

                        if (template) {
                            return await service.create({
                                ...letter,
                                template: template.id,
                                mergeVariables:
                                    emptyKeysRemoved(mergeVariables),
                            });
                        }

                        return await service.createUploadPDF({
                            ...letter,
                            pdf: file!,
                        });
                    };

                    if (toContacts.length === 1) {
                        await send(toContacts[0]);

                        dispatch({
                            type: MessageType.SUCCESS,
                            message: 'Created letter.',
                        });

                        history.push(LetterRoutes.HOME);
                    } else {
                        setBatchSendState((state) => ({
                            ...state,
                            status: BatchSendStatus.IN_PROGRESS,
                        }));
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            })();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC">
                        <Typography variant="h5" gutterBottom>
                            Create a Letter
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item direction="column">
                    <Grid item>
                        <Box my={2}>
                            {live ? (
                                <Alert variant="outlined" color="warning">
                                    You are in live mode so this letter will be
                                    printed and delivered.
                                </Alert>
                            ) : (
                                <Alert variant="outlined" color="info">
                                    You are in test mode so this letter will not
                                    actually get sent out.
                                </Alert>
                            )}
                        </Box>
                    </Grid>
                    <Grid item>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Description"
                                        fullWidth
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ContactOrCSV
                                        label="To Contact"
                                        contacts={toContacts}
                                        setContacts={setToContacts}
                                        setUploadedCSV={setUploadedCSV}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ContactInput
                                        label="From Contact"
                                        contact={fromContact}
                                        setContact={setFromContact}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectTemplate
                                        label="Select a Template"
                                        template={template}
                                        setTemplate={setTemplate}
                                        required={!file}
                                        disabled={file ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FileUpload
                                        accept="application/pdf"
                                        label="Upload a PDF"
                                        file={file}
                                        setFile={setFile}
                                        required={!template}
                                        disabled={template ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectReturnEnvelope
                                        label="Select a Return Envelope"
                                        returnEnvelope={returnEnvelope}
                                        setReturnEnvelope={setReturnEnvelope}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <StrictEnumSelect
                                        title="Envelope Type"
                                        valueLabels={[
                                            [
                                                EnvelopeType.STANDARD_DOUBLE_WINDOW,
                                                'Standard Double Window',
                                            ],
                                            /* HACK Removed temporarily because of shortage
                                            [EnvelopeType.FLAT, 'Flat'],
                                            */
                                        ]}
                                        onChange={setEnvelopeType}
                                        value={envelopeType}
                                    />
                                </Grid>
                                <ExtraServiceSelector
                                    xs={6}
                                    extraService={extraServiceForm}
                                    onChange={setExtraService}
                                    disabled={!!express}
                                />
                                <MailingClassSelector
                                    xs={6}
                                    mailingClass={mailingClass}
                                    onChange={setMailingClass}
                                    disabled={!!express}
                                />
                                <Grid item xs={12}>
                                    <Collapse in={templateVars.length > 0}>
                                        <MergeVariablesInput
                                            templateVars={templateVars}
                                            mergeVars={mergeVars}
                                            setMergeVars={setMergeVars}
                                            defaultVars={defaultVars}
                                        />
                                    </Collapse>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={color}
                                                    onChange={(e) => {
                                                        setColor(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Color"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={doubleSided}
                                                    onChange={(e) => {
                                                        setDoubleSided(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Double Sided"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={insertBlankPage}
                                                    onChange={(e) => {
                                                        setInsertBlankPage(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Insert Blank Page for Address"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={perforateFirstPage}
                                                    onChange={(e) => {
                                                        setPerforateFirstPage(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Perforate First Page"
                                        />
                                    </Grid>
                                    <ExpressDeliveryCheckbox
                                        checked={express}
                                        setChecked={setExpress}
                                        disabled={!!extraService}
                                    />
                                </Grid>
                                {batchSendState.status !==
                                    BatchSendStatus.READY && (
                                    <Grid item xs={12}>
                                        <BatchSend
                                            state={batchSendState}
                                            setState={setBatchSendState}
                                            toContacts={toContacts}
                                            createBatch={createBatch}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {batchSendState.status ===
                                            BatchSendStatus.READY && (
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={loading}
                                                    fullWidth
                                                    size="large"
                                                >
                                                    Create
                                                </Button>
                                            </Grid>
                                        )}

                                        <Grid item xs={2}>
                                            <GoBackButton
                                                color="primary"
                                                variant="outlined"
                                                disabled={
                                                    loading ||
                                                    batchSendState.status ===
                                                        BatchSendStatus.IN_PROGRESS
                                                }
                                                fullWidth
                                                size="large"
                                            >
                                                {batchSendState.status ===
                                                    BatchSendStatus.COMPLETED ||
                                                batchSendState.status ===
                                                    BatchSendStatus.ERROR
                                                    ? 'Back'
                                                    : 'Cancel'}
                                            </GoBackButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateLetter;
