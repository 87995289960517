import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { Context as BackgroundSendContext } from '../context/BackgroundSend';

import { fullName } from '../services/Contacts';

import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

// HACK
import { PREFIX_TO_OBJECT } from '../components/RawData';
import TableDisplay from '../components/TableDisplay';

const BackgroundSends = () => {
    const history = useHistory();

    const { state } = useContext(BackgroundSendContext);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Uploaded Orders</Typography>
                </Grid>
                <Grid item>
                    <TableDisplay
                        columns={['Recipient', 'Status', 'Order']}
                        show={false}
                        pagination={{
                            rowsPerPage,
                            setRowsPerPage,
                            page,
                            setPage,
                            count: state.completed.length,
                        }}
                        showEmptyTable
                    >
                        {state.completed
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((c, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (!c.order) {
                                                return;
                                            }

                                            // Navigate to the order page if it exists
                                            for (const [
                                                prefix,
                                                object,
                                            ] of Object.entries(
                                                PREFIX_TO_OBJECT
                                            )) {
                                                if (
                                                    c.order.id.startsWith(
                                                        prefix
                                                    )
                                                ) {
                                                    history.push(
                                                        `/dashboard/${object}s/${c.order.id}`
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        <TableCell>{fullName(c.to)}</TableCell>
                                        <TableCell>{c.status || ''}</TableCell>
                                        <TableCell>
                                            <Box fontFamily="monospace">
                                                {c.order?.id || ''}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableDisplay>
                </Grid>
            </GridPaper>
        </>
    );
};

export default BackgroundSends;
