import React, { useCallback } from 'react';

import { usePagination } from '../hooks/usePagination';

import { Route, Switch, useHistory } from 'react-router-dom';

import { Webhook, useService as useWebhookService } from '../services/Webhooks';

import { formatTableDate, ListParams } from '../services/util';

import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';

import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ViewWebhook from './ViewWebhook';
import CreateWebhook from './CreateWebhook';
import LinkButton from '../components/LinkButton';
import { WebhookRoutes } from '../routes';

const Webhooks: React.FC = () => {
    const history = useHistory();

    const service = useWebhookService();

    const paginationFunc = useCallback(
        (listParams: ListParams) => service.list(listParams),
        [service]
    );

    const {
        data: webhooks,
        loading,
        searchText,
        searchQuery,
        pagination,
    } = usePagination(paginationFunc);

    const handleRowClick = (webhook: Webhook) => {
        history.push(`${WebhookRoutes.HOME}/${webhook.id}`);
    };

    return (
        <Switch>
            <Route exact path={WebhookRoutes.HOME}>
                <TopNav
                    showSearch
                    searchText={searchText}
                    searchQuery={searchQuery}
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader title="Webhooks">
                        <Grid item>
                            <LinkButton
                                variant="contained"
                                color="primary"
                                to={WebhookRoutes.CREATE}
                            >
                                Create Webhook
                            </LinkButton>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            columns={['URL', 'Events', 'Enabled', 'Created At']}
                            show={loading}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {webhooks.map((hook) => {
                                return (
                                    <TableRow
                                        key={hook.id}
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            handleRowClick(hook);
                                        }}
                                    >
                                        <TableCell>{hook.url}</TableCell>
                                        <TableCell>
                                            {hook.enabledEvents.map(
                                                (event, index) => {
                                                    return `${event.toString()}${
                                                        index <
                                                        hook.enabledEvents
                                                            .length -
                                                            1
                                                            ? ', '
                                                            : ''
                                                    }`;
                                                }
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {hook.enabled.toString()}
                                        </TableCell>
                                        <TableCell>
                                            {formatTableDate(hook.createdAt)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={WebhookRoutes.CREATE} component={CreateWebhook} />
            <Route path={WebhookRoutes.VIEW} component={ViewWebhook} />
        </Switch>
    );
};

export default Webhooks;
