import React, { useContext, createContext, useState, useEffect } from 'react';
import { useHistory } from '../hooks/useHistory';

interface State {
    createOrderOrigin: string | undefined;
}

const Context = createContext<State | undefined>(undefined);

export const CreateOrderOriginProvider: React.FC = ({ children }) => {
    const history = useHistory();
    const [createOrderOrigin, setCreateOrderOrigin] = useState<string>();

    useEffect(() => {
        if (history.location.state?.from) {
            setCreateOrderOrigin(history.location.state.from);
        }
    }, [history.location]);

    const value = { createOrderOrigin };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCreateOrderOrigin = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'useCreateOrderOrigin can only be used within a CreateOrderOrigin provider'
        );
    }
    return context;
};
