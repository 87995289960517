import { useMemo } from 'react';

import { User } from '../context/Auth';

import { createURLParams, ListParams, ListResponse } from './util';

import { Service as BaseService, useService as useBaseService } from './Base';

import { Organization } from './Organization';

interface CreateWithOrganizationParams {
    name: string;
    email: string;
    password: string;
    countryCode: string;
    phoneNumber?: string;
    organizationName: string;
    recaptchaToken: string;
}

interface CreatePasswordResetParams {
    email: string;
}

interface ResetPasswordParams {
    password: string;
}

interface ResetPasswordResponse {
    email: string;
}

type VerifyEmailResponse = ResetPasswordResponse;

interface UserOrganization {
    user: User;
    organization: Organization;
}

interface UpdateParams {
    name?: string;
    oldPassword?: string;
    password?: string;
    phoneNumber?: string;
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async createWithOrganization(params: CreateWithOrganizationParams) {
        return await this.base.fetchAPI<UserOrganization>('/users/signup', {
            method: 'POST',
            body: params,
        });
    }

    async createPasswordReset(params: CreatePasswordResetParams) {
        // Ignore the response, it just echoes the params
        await this.base.fetchAPI('/users/password_resets', {
            method: 'POST',
            body: params,
        });
    }

    async verifyEmail(token: string) {
        return await this.base.fetchAPI<VerifyEmailResponse>(
            `/users/email_verifications/${token}`,
            {
                method: 'POST',
            }
        );
    }

    async resetPassword(token: string, params: ResetPasswordParams) {
        return await this.base.fetchAPI<ResetPasswordResponse>(
            `/users/password_resets/${token}`,
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async list(params: ListParams) {
        const urlParams = createURLParams(params);

        return await this.base.fetchAPI<ListResponse<User>>(
            `/users?${urlParams.toString()}`
        );
    }

    async get(id: string) {
        return await this.base.fetchAPI<User>(`/users/${id}`);
    }

    async update(id: string, params: UpdateParams) {
        return await this.base.fetchAPI<User>(`/users/${id}`, {
            method: 'POST',
            body: params,
        });
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
