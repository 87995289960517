import { useMemo, useState, useEffect } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';

export enum AddressStrictness {
    ALLOW_VERIFIED = 'allow_verified',
    ALLOW_CORRECTED = 'allow_corrected',
    ALLOW_FAILED = 'allow_failed',
}

export interface Organization {
    name: string;
    usage: number;
    spend: number;
    limit: number;

    stripeCustomer: string;
    stripeMailingsPaymentMethod?: string;
    stripeSubscription?: string;
    stripeSubscriptionPrice?: string;
    stripeSubscriptionPaymentMethod?: string;

    addressStrictness: AddressStrictness;

    liveApproved: boolean;

    allowInvitingUsersWithoutSubscribing?: boolean;

    createdAt: Date;

    externalPaymentMethod?: string;
    countryCode?: string;
}

export type UpdateParams = Partial<
    Pick<
        Organization,
        | 'stripeMailingsPaymentMethod'
        | 'stripeSubscriptionPaymentMethod'
        | 'addressStrictness'
    >
>;

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async get() {
        return await this.base.fetchAPI<Organization>('/organization');
    }

    async update(data: UpdateParams) {
        return await this.base.fetchAPI<Organization>('/organization', {
            method: 'POST',
            body: data,
        });
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};

// Helper which reloads an organization whenever the given dependencies change
export const useOrganization = (dependencies: any[]) => {
    const service = useService();

    const [org, setOrg] = useState<Organization>();

    useEffect(() => {
        (async () => {
            try {
                setOrg(await service.get());
            } catch (err) {
                console.error(err);
            }
        })();
        // As this is a helper function the dependencies variable is not always the same
        // and can take 'any' shape/form.
        // Remove destructure eslint error
        // eslint-disable-next-line
    }, [service, ...dependencies]);

    return org;
};
