import React, { useContext, useState, useEffect } from 'react';

import initHelpHero from 'helphero';

import { useHistory } from 'react-router-dom';

import { ChartOptions } from 'chart.js';
import { Line } from '@reactchartjs/react-chart.js';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useStyles as useProgressStyles } from '../components/CircularProgressWithLabel';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { POSTCARDS_ENABLED } from '../config';

import { Context as ModeContext } from '../context/Mode';
import { Context as AuthContext } from '../context/Auth';

import { useOrganization } from '../services/Organization';

import { useDebouncedValue } from '../services/util';

import {
    Analytics,
    useService as useAnalyticsService,
} from '../services/Analytics';

import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import LiveInfoCards from '../components/LiveInfoCards';

const INFO_DIALOG_SHOWN_VAR = 'infoDialogShown';

const hlp = initHelpHero('yRquy0IVSvC');

const useButtonStyle = makeStyles((theme) => ({
    root: {
        margin: '0 5px',
        border: '1px solid transparent',
        borderRadius: '4px !important',
        textTransform: 'capitalize',
    },
    selected: {
        backgroundColor: 'transparent !important',
        borderColor: '#6B6B6B',
        borderLeft: '1px solid !important',
    },
}));

const Overview = () => {
    const theme = useTheme();

    const history = useHistory();

    const service = useAnalyticsService();

    const buttonClass = useButtonStyle();

    const linearClass = useProgressStyles();

    const org = useOrganization([history.location]);

    const { live } = useContext(ModeContext);
    const { state: authState } = useContext(AuthContext);

    const [period, setPeriod] = useState('week');

    const [analytics, setAnalytics] = useState<Analytics>();
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    const search = useDebouncedValue(searchText, 200);

    useEffect(() => {
        if (!authState.user) {
            return;
        }

        // Pass user name and email to LuckyOrange (https://help.luckyorange.com/article/41-passing-in-custom-user-data)
        const loq = (window as any)._loq || [];

        loq.push([
            'custom',
            {
                name: authState.user.name,
                email: authState.user.email,
            },
        ]);

        hlp.identify(authState.user.id, {
            name: authState.user.name,
            email: authState.user.email,
            createdAt: authState.user.createdAt.toString(),
        });
    }, [authState]);

    useEffect(() => {
        let cancel = false;
        (async () => {
            try {
                const startDate = new Date();
                const endDate = new Date();

                if (period === 'week') {
                    startDate.setDate(startDate.getDate() - 7);
                } else {
                    startDate.setMonth(startDate.getMonth() - 1);
                }

                setLoading(true);
                const analytics = await service.get({
                    startDate,
                    endDate,
                    search,
                    padZeros: true,
                });
                if (cancel) return;
                setAnalytics(analytics);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
        return () => {
            cancel = true;
        };
    }, [history.location, service, period, search]);

    useEffect(() => {
        setInfoDialogOpen(
            localStorage.getItem(INFO_DIALOG_SHOWN_VAR) !== 'true'
        );
    }, []);

    const data = analytics && {
        labels: analytics.lettersByDate.map((v) => v.date),
        datasets: [
            {
                label: 'Letters',
                data: analytics.lettersByDate.map((v) => v.count),
                fill: false,
                borderColor: theme.palette.primary.main,
            },
            {
                label: 'Postcards',
                data: analytics.postcardsByDate.map((v) => v.count),
                fill: false,
                borderColor: theme.palette.primary.light,
            },
            {
                label: 'Cheques',
                data: analytics.chequesByDate.map((v) => v.count),
                fill: false,
                borderColor: theme.palette.primary.dark,
            },
        ],
    };

    if (!POSTCARDS_ENABLED && data) {
        // Remove postcards if they're disabled
        data.datasets.splice(1, 1);
    }

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        elements: {
            line: {
                tension: 0,
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value: number) =>
                            value % 1 === 0 ? value : undefined,
                    },
                },
            ],
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 14,
                    },
                },
            ],
        },
    };

    // TODO Make the CircularProgress dynamic with a gray background
    return (
        <>
            <TopNav
                showSearch
                searchQuery={setSearchText}
                searchText={searchText}
            />
            <ConfirmActionDialog
                // HACK We directly access localStorage but we should probably have a util function for this
                open={infoDialogOpen}
                title="Welcome to PostGrid"
                text={`
                    If this is your first time using PostGrid Print & Mail, we highly recommend taking a look at our guides.
                    You can always access them from the top-right corner of the dashboard.
                `}
                cancelLabel="Close"
                actionLabel="View Guides"
                confirm={() =>
                    window.open(
                        'https://www.postgrid.com/guides/print-mail/',
                        '_blank'
                    )
                }
                onClose={() => {
                    localStorage.setItem(INFO_DIALOG_SHOWN_VAR, 'true');
                    setInfoDialogOpen(false);
                }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <LiveInfoCards org={org} />
                </Grid>
                <Grid item xs={12}>
                    <GridPaper direction="row">
                        {!live && (
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Alert variant="outlined" color="info">
                                        This graph is showing your test mode
                                        orders since you are in test mode.
                                    </Alert>
                                </Box>
                            </Grid>
                        )}
                        <Grid
                            item
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="h5">
                                    Latest Reports
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <LinearProgress
                                            value={100}
                                            variant="determinate"
                                            style={{
                                                width: '6px',
                                                height: '6px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box fontWeight="600" fontSize={13}>
                                            Letters
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <LinearProgress
                                            value={100}
                                            variant="determinate"
                                            style={{
                                                width: '6px',
                                                height: '6px',
                                                borderRadius: '5px',
                                            }}
                                            className={linearClass.postcard}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box fontWeight="600" fontSize={13}>
                                            Postcards
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <LinearProgress
                                            value={100}
                                            variant="determinate"
                                            style={{
                                                width: '6px',
                                                height: '6px',
                                                borderRadius: '5px',
                                            }}
                                            className={linearClass.cheques}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box fontWeight="600" fontSize={13}>
                                            Cheques
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <ToggleButtonGroup
                                            value={period}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setPeriod(value);
                                                }
                                            }}
                                            exclusive
                                        >
                                            <ToggleButton
                                                size="small"
                                                color="primary"
                                                value="week"
                                                classes={{
                                                    root: buttonClass.root,
                                                    selected:
                                                        buttonClass.selected,
                                                }}
                                                data-testid="overview-toggle-week"
                                            >
                                                Past Week
                                            </ToggleButton>
                                            <ToggleButton
                                                size="small"
                                                value="month"
                                                classes={{
                                                    root: buttonClass.root,
                                                    selected:
                                                        buttonClass.selected,
                                                }}
                                                data-testid="overview-toggle-month"
                                            >
                                                Past Month
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Box style={{ marginTop: 20 }}>
                                {loading || !data ? (
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        style={{ minHeight: '50vh' }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <Line
                                        type="line"
                                        data={data}
                                        options={options}
                                        height={63}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </GridPaper>
                </Grid>
            </Grid>
        </>
    );
};

export default Overview;
