import React from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';

// TODO Create proper editor component
const HTMLEditor = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            fullWidth
            multiline
            rows={props.rows || 30}
            InputProps={{
                style: {
                    fontFamily: 'monospace',
                },
            }}
        />
    );
};

export default HTMLEditor;
