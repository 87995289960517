import { useMemo } from 'react';

import { Service as ResourceService } from './DeletableResource';

import { asyncMapChunks } from './util';
import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
} from './Base';

export interface Contact extends Resource {
    addressLine1: string;
    addressLine2?: string;
    city?: string;
    postalOrZip?: string;
    provinceOrState?: string;
    country?: string;
    countryCode?: string;

    skipVerification?: boolean;
    addressStatus: 'verified' | 'corrected' | 'failed';

    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    companyName?: string;
    jobTitle?: string;
}

export type CreateParams = Omit<
    Contact,
    'addressStatus' | 'country' | keyof Resource
> &
    ResourceCreateParams;

interface CreateBatchParams {
    data: CreateParams[];
    handleProgress: (count: number) => void;
}

export interface CreateBatchResponse {
    object: 'batch';
    data: Contact[];
}

export class Service extends ResourceService<Contact> {
    async create(data: CreateParams) {
        // Make sure countryCode is uppercase
        if (data.countryCode) {
            data.countryCode = data.countryCode.toUpperCase();
        }

        return await this.base.fetchAPI<Contact>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async createBatch(params: CreateBatchParams) {
        const batchRequest = async (batch: CreateParams[]) => {
            // Make sure countryCode is uppercase
            for (const c of batch) {
                c.countryCode = c.countryCode?.toUpperCase();
            }

            const res = await this.base.fetchAPI<CreateBatchResponse>(
                `/${this.route}/batch`,
                {
                    method: 'POST',
                    body: {
                        data: batch,
                    },
                }
            );

            params.handleProgress(batch.length);

            return res;
        };

        // Breaks array into chunks and then makes a request for each, awaiting it before proceeding.
        // The flatMap will take the individual data arrays and combine them into one big array.
        return (await asyncMapChunks(params.data, 250, batchRequest)).flatMap(
            (r) => r.data
        );
    }
}

export const fullName = (contact: Contact) => {
    if (contact.firstName) {
        return `${contact.firstName}${
            contact.lastName ? ' ' + contact.lastName : ''
        }`;
    }

    return null;
};

export const label = (contact: Contact) => {
    // If firstName is null, then companyName must be present, and vice versa
    return fullName(contact) || (contact.companyName as string);
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'contacts'), [base]);
};
