import React, {
    PropsWithoutRef,
    useState,
    useEffect,
    useCallback,
    useContext,
    MouseEvent,
} from 'react';

import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

import {
    Organization,
    useService as useOrganizationService,
} from '../services/Organization';

import {
    useService as useBillingService,
    PaymentMethod,
} from '../services/Billing';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import CreatePaymentMethod from './CreatePaymentMethod';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import cardBg from '../img/card-bg.png';

const UseForSubscriptionButton = (
    props: PropsWithoutRef<{
        method: PaymentMethod;
        organization?: Organization;
        onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    }>
) => {
    const hasSub = props.organization?.stripeSubscription !== undefined;
    const isSubMethod =
        props.method.id === props.organization?.stripeSubscriptionPaymentMethod;

    if (isSubMethod) {
        return (
            <Tooltip title="Already in use">
                <span>
                    <Button color="primary" disabled>
                        Use for subscription
                    </Button>
                </span>
            </Tooltip>
        );
    }

    if (!hasSub) {
        return (
            <Tooltip title="Not subscribed">
                <span>
                    <Button variant="contained" color="primary" disabled>
                        Use for subscription
                    </Button>
                </span>
            </Tooltip>
        );
    }

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={props.onClick}
            disabled={!props.organization}
        >
            Use for subscription
        </Button>
    );
};

const UseForMailingsButton = (
    props: PropsWithoutRef<{
        method: PaymentMethod;
        organization?: Organization;
        onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    }>
) => {
    const isMailingsMethod =
        props.method.id === props.organization?.stripeMailingsPaymentMethod;

    if (isMailingsMethod) {
        return (
            <Tooltip title="Already in use">
                <span>
                    <Button variant="outlined" color="primary" disabled>
                        Use for mailings
                    </Button>
                </span>
            </Tooltip>
        );
    }

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={props.onClick}
            disabled={!props.organization}
        >
            Use for mailings
        </Button>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: cardBg,
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px',
        borderColor: theme.palette.primary.main,
        padding: '18px 18px 25px',
        '&>.MuiCardContent-root': {
            padding: '0 16px',
        },
    },
}));

const PaymentMethodPreview = (
    props: PropsWithoutRef<{
        method: PaymentMethod;
        org?: Organization;
        useForSubscription: (method: PaymentMethod) => void;
        useForMailings: (method: PaymentMethod) => void;
    }>
) => {
    const usedForMailings =
        props.method.id === props.org?.stripeMailingsPaymentMethod;
    const usedForSubs =
        props.method.id === props.org?.stripeSubscriptionPaymentMethod;

    const Info = () => {
        if (usedForSubs && usedForMailings) {
            return (
                <Typography>
                    Charged for your <em>subscription</em> and <em>mailings</em>
                    .
                </Typography>
            );
        }

        if (usedForSubs) {
            return (
                <Typography>
                    Charged for your <em>subscription</em>.
                </Typography>
            );
        }

        if (usedForMailings) {
            return (
                <Typography>
                    Charged for your <em>mailings</em>.
                </Typography>
            );
        }

        return <Typography>Not charged.</Typography>;
    };

    const classes = useStyles();

    return (
        <Card variant="outlined" classes={classes}>
            <CardContent>
                <Typography variant="subtitle1" style={{ fontSize: '24px' }}>
                    Credit Card
                </Typography>
                <Box my={1}>
                    <Divider />
                </Box>
                <Typography>
                    Card ending in {props.method.card.last4}.
                </Typography>
                <Info />
            </CardContent>
            <CardActions>
                <Box mt={1}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <UseForSubscriptionButton
                                method={props.method}
                                organization={props.org}
                                onClick={(e) => {
                                    props.useForSubscription(props.method);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <UseForMailingsButton
                                method={props.method}
                                organization={props.org}
                                onClick={(e) => {
                                    props.useForMailings(props.method);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </CardActions>
        </Card>
    );
};

const Payment = (props: PropsWithoutRef<{}>) => {
    const history = useHistory();
    const match = useRouteMatch();

    const orgService = useOrganizationService();
    const billingService = useBillingService();

    const { dispatch } = useContext(NotificationContext);

    const [org, setOrg] = useState<Organization>();

    useEffect(() => {
        (async () => {
            try {
                setOrg(await orgService.get());
            } catch (err) {
                console.error(err);
            }
        })();
    }, [orgService, history.location]);

    const useForMailings = useCallback(
        async (method: PaymentMethod) => {
            try {
                setOrg(undefined);

                setOrg(
                    await orgService.update({
                        stripeMailingsPaymentMethod: method.id,
                    })
                );

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Updated mailing payment method.',
                });
            } catch (err) {
                console.error(err);
            }
        },
        [orgService, dispatch]
    );

    const useForSubscription = useCallback(
        async (method: PaymentMethod) => {
            try {
                setOrg(undefined);

                setOrg(
                    await orgService.update({
                        stripeSubscriptionPaymentMethod: method.id,
                    })
                );

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Updated subscription payment method.',
                });
            } catch (err) {
                console.error(err);
            }
        },
        [orgService, dispatch]
    );

    const [methods, setMethods] = useState<PaymentMethod[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const methods = await billingService.listPaymentMethods();

                setMethods(methods.data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [billingService, history.location]);

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <TopNav />
                <GridPaper container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="h5">
                                    Payment Methods
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        history.push(`${match.path}/create`);
                                    }}
                                >
                                    Add Payment
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Alert color="info" variant="outlined">
                            These are all the payment methods associated with
                            your organization. You can select one for your
                            mailing and another for your subscription payments.
                        </Alert>
                    </Grid>
                    <Grid item>
                        {methods.length === 0 ? (
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{ minHeight: 564 }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        You have no payment methods added.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2}>
                                {methods.map((method) => (
                                    <Grid item xs={4} key={method.id}>
                                        <PaymentMethodPreview
                                            org={org}
                                            method={method}
                                            useForSubscription={
                                                useForSubscription
                                            }
                                            useForMailings={useForMailings}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </GridPaper>
            </Route>
            <Route
                path={`${match.path}/create`}
                component={CreatePaymentMethod}
            />
        </Switch>
    );
};

export default Payment;
