import { useMemo } from 'react';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
    OrderExtraService,
} from './Base';

import { Order, OrderStatus, Service as OrdersService } from './Orders';
import { APIErrorResponse, asyncMapChunks } from './util';

export enum AddressPlacement {
    TOP_FIRST_PAGE = 'top_first_page',
    INSERT_BLANK_PAGE = 'insert_blank_page',
}

export enum EnvelopeType {
    STANDARD_DOUBLE_WINDOW = 'standard_double_window',
    FLAT = 'flat',
}

interface Thumbnail {
    small: string;
    medium: string;
    large: string;
}

export interface Letter extends Order {
    object: 'letter';

    html?: string;
    template?: string;

    color: boolean;
    doubleSided: boolean;

    thumbnail?: Thumbnail;

    mergeVariables?: Record<string, any>;

    addressPlacement?: AddressPlacement;

    returnEnvelope?: string;
    envelopeType?: EnvelopeType;

    extraService?: OrderExtraService;
    perforatedPage?: number;
}

export type CreateParams = Omit<
    Letter,
    'sendDate' | 'to' | 'from' | 'status' | 'thumbnail' | 'url' | keyof Resource
> &
    ResourceCreateParams & {
        to: string;
        from: string;
        sendDate?: Date;
    };

// Omitting metadata until we properly set up FormData with nested fields
type CreateUploadPDFParams = Omit<
    CreateParams,
    'template' | 'html' | 'mergeVariables' | 'to' | 'from' | 'metadata'
> & {
    pdf: File;
};

type CreateBatchDatum = Omit<
    CreateParams,
    'template' | 'html' | 'pdf' | 'from' | 'to' | 'extraService' | 'express'
> & { to: string; from: string };

type CreateBatchParams = Pick<
    CreateParams,
    'template' | 'express' | 'extraService' | 'returnEnvelope' | 'envelopeType'
> & {
    pdf?: File | string;
    data: CreateBatchDatum[];
    handleProgress: (count: number) => void;
};

// TODO: Factor into Batch stuff -> copied from Postcard
interface CreateBatchResponse {
    object: 'batch';
    data: (Letter | APIErrorResponse)[];
}

export class Service extends OrdersService<Letter> {
    async create(data: CreateParams) {
        return await this.base.fetchAPI<Letter>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async createUploadPDF(params: CreateUploadPDFParams) {
        const data = new FormData();

        for (let [key, value] of Object.entries(params)) {
            if (value === undefined) {
                continue;
            }

            data.set(key, value as any);
        }

        return await this.base.fetchAPI<Letter>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async createBatch(params: CreateBatchParams) {
        const batchRequest = async (batch: CreateBatchDatum[]) => {
            const res = await this.base.fetchAPI<CreateBatchResponse>(
                `/${this.route}/batch`,
                {
                    method: 'POST',
                    body: {
                        ...params,
                        pdf: await (async () => {
                            if (!params.pdf || typeof params.pdf === 'string') {
                                return params.pdf;
                            }

                            // Convert to base64 data URI: https://stackoverflow.com/a/18650249
                            return await new Promise((resolve) => {
                                const reader = new FileReader();
                                reader.onloadend = () => resolve(reader.result);
                                reader.readAsDataURL(params.pdf as File);
                            });
                        })(),
                        data: batch,
                        handleProgress: undefined,
                    },
                }
            );

            params.handleProgress(batch.length);

            return res;
        };

        return (await asyncMapChunks(params.data, 250, batchRequest)).flatMap(
            (d) => d.data
        );
    }
}

export const statusLabel = (status: OrderStatus) => {
    return status.split('_').join(' ');
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'letters'), [base]);
};
