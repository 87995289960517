import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    unstable_registerShapeComponent,
    unstable_registerShapeModel,
} from 'polotno/config';
import { StoreType } from 'polotno/model/store';
import { Image as KonvaImage, Text } from 'react-konva';

export const MERGE_VARIABLE_IMAGE = 'merge_variable_image';
export const MERGE_VAR_BASE_DIMENSIONS = { width: 200, height: 250 };

unstable_registerShapeModel({
    type: MERGE_VARIABLE_IMAGE,
    numPoints: 4,
    fill: 'white',
    src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/image_merge_variable_placeholder.png',
    height: MERGE_VAR_BASE_DIMENSIONS.width,
    width: MERGE_VAR_BASE_DIMENSIONS.height,
    mergeVar: 'customImage',
    maintainAspectRatio: false,
});

// HACK: Taken from polonto files
type ElementProps = {
    onClick: Function;
    // FIXME: Types
    element: any;
    store: StoreType;
};

const MergeVariableImageElement = observer(
    ({ element, store }: ElementProps) => {
        const image = new Image();
        image.src = element.src;

        // FIXME: Type: KonvaEventObject<DragEvent>
        const handleChange = (e: any) => {
            const node = e.currentTarget;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            node.scaleX(1);
            node.scaleY(1);
            element.set({
                x: node.x(),
                y: node.y(),
                rotation: e.target.rotation(),
                width: element.width * scaleX,
                height: element.height * scaleY,
            });
        };

        const handleDragStart = () => {
            const isSelected = store.selectedElements.find(
                (e) => e === element
            );
            if (!isSelected) {
                store.selectElements([element.id]);
            }
        };

        const selectElement = () => {
            store.selectElements([element.id]);
        };

        return (
            <>
                <KonvaImage
                    name="element"
                    id={element.id}
                    x={element.x}
                    image={image}
                    y={element.y}
                    offsetX={-element.width}
                    offsetY={-element.height}
                    width={element.width}
                    height={element.height}
                    rotation={element.rotation}
                    opacity={element.opacity}
                    draggable={!element.locked}
                    cornerRadius={element.cornerRadius}
                    onDragStart={handleDragStart}
                    onDragMove={handleChange}
                    onTransformStart={handleChange}
                    onTransformEnd={handleChange}
                    onTransform={handleChange}
                />
                <Text
                    id={element.id}
                    x={element.x}
                    y={element.y}
                    width={element.width}
                    height={element.height}
                    offsetX={-element.width}
                    offsetY={-element.height}
                    rotation={element.rotation}
                    opacity={element.opacity}
                    onDragStart={handleDragStart}
                    onDragMove={handleChange}
                    onTransformStart={handleChange}
                    onTransformEnd={handleChange}
                    onTransform={handleChange}
                    onClick={selectElement}
                    draggable={!element.locked}
                    align="center"
                    verticalAlign="middle"
                    text={`{{${element.mergeVar}}}`}
                    fontSize={50}
                    fill={element.fill}
                    stroke={'black'}
                    strokeWidth={1}
                />
            </>
        );
    }
);

unstable_registerShapeComponent(
    MERGE_VARIABLE_IMAGE,
    // FIXME: it doesn't like the `observer` version of the
    // component because of the `displayName` property it adds
    // @ts-ignore
    MergeVariableImageElement
);

export default MergeVariableImageElement;
