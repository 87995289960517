import React from 'react';

import { Contact } from '../services/Contacts';

import Grid from '@material-ui/core/Grid';

import AddressInfo from './AddressInfo';
import PersonalInfo from './PersonalInfo';

interface ContactInfoProps {
    contact: Contact;
    addressTitle?: string;
    personalTitle?: string;
}

const ContactInfo: React.FC<ContactInfoProps> = ({
    contact,
    addressTitle,
    personalTitle,
}) => {
    return (
        <Grid item container spacing={2} data-testid="contact-info">
            <Grid item xs={5}>
                <PersonalInfo contact={contact} title={personalTitle} />
            </Grid>
            <Grid item xs>
                <AddressInfo contact={contact} title={addressTitle} />
            </Grid>
        </Grid>
    );
};

export default ContactInfo;
