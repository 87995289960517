import React, { useState, useContext, FormEvent } from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { loadStripe, StripeCardNumberElement } from '@stripe/stripe-js';

import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import { useService as useBillingService } from '../services/Billing';

import { Organization, useOrganization } from '../services/Organization';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import GoBackButton from '../components/GoBackButton';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import StripeInput, { StripeInputProps } from '../components/StripeInput';

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUB_KEY as string
);

const MailingMethodAlert = (props: { org?: Organization }) => {
    if (!props.org || props.org.stripeMailingsPaymentMethod) {
        return (
            <Alert variant="outlined" color="info">
                Once you add this payment method, you can configure it to be
                your primary mailing payment method on the payment page.
            </Alert>
        );
    }

    return (
        <Alert variant="outlined" color="info">
            Since this is the first payment method you're adding, it will be
            used for all of your mailings.
        </Alert>
    );
};

const CardField = (props: {
    label: string;
    component: StripeInputProps['component'];
}) => {
    return (
        <TextField
            label={props.label}
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                    component: props.component,
                },
            }}
        />
    );
};

const Form = () => {
    const history = useHistory();

    const org = useOrganization([history.location]);

    const stripe = useStripe();
    const elements = useElements();

    const service = useBillingService();

    const { dispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(
                CardNumberElement
            ) as StripeCardNumberElement,
        });

        if (error) {
            dispatch({
                type: MessageType.ERROR,
                message: error.message as string,
            });

            setLoading(false);
            return;
        }

        if (!paymentMethod) {
            setLoading(false);
            return;
        }

        try {
            await service.addPaymentMethod(paymentMethod.id);

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Added payment method.',
            });

            history.push('/dashboard/payment');
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <MailingMethodAlert org={org} />
                </Grid>
                <Grid container item justify="center">
                    <Grid container item xs={6} spacing={2}>
                        <Grid item xs={12}>
                            <CardField
                                label="Card Number"
                                component={CardNumberElement}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CardField
                                label="Expiry Date"
                                component={CardExpiryElement}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CardField
                                label="Security Code"
                                component={CardCvcElement}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box my={2}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                    size="large"
                                    fullWidth
                                >
                                    Add Payment Method
                                </Button>
                            </Grid>
                            <Grid item>
                                <GoBackButton
                                    color="primary"
                                    variant="outlined"
                                    disabled={loading}
                                    size="large"
                                    fullWidth
                                >
                                    Cancel
                                </GoBackButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

const CreatePaymentMethod = (props: {}) => {
    return (
        <>
            <TopNav />
            <GridPaper>
                <Elements stripe={stripePromise}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box
                                borderBottom="1px solid #ECECEC"
                                pb={1.5}
                                width="100%"
                            >
                                <Typography variant="h5" gutterBottom>
                                    Add a Payment Method
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Form />
                        </Grid>
                    </Grid>
                </Elements>
            </GridPaper>
        </>
    );
};

export default CreatePaymentMethod;
