import React, { ElementType } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import HeaderIcon from './HeaderIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { TextFieldProps } from '@material-ui/core/TextField';
import Paper from './PaperWrapper';
import { makeStyles } from '@material-ui/core/styles';

type EditFieldProps = TextFieldProps & {
    title: string;
    icon?: ElementType;
    component: ElementType;
    item?: boolean;
};

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: '0px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
    },
}));

/**
 * @param title The title of the field `required`
 * @param icon An icon to display next to the header `optional`
 * @param item Specify if you want to have this component wrapped in a Mui Grid item component `optional`
 * @param TextFieldProps Any Mui TextFieldProps `optional`
 */
const EditField: React.FC<EditFieldProps> = ({
    title,
    icon,
    item,
    component,
    ...props
}) => {
    const classes = useStyles();
    const Component = component;

    const element = (
        <Paper>
            <Box p={2} display="flex">
                {icon && <HeaderIcon icon={icon} />}
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Divider />
            <Component
                {...props}
                className={`${classes.root} ${props.className}`}
            />
        </Paper>
    );

    if (item) return <Grid item>{element}</Grid>;
    return element;
};

export default EditField;
