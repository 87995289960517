import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';

type GridFormProps = {
    grid?: GridProps;
    form?: React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    >;
};

const GridForm: React.FC<GridFormProps> = ({ form, grid, children }) => {
    return (
        <Grid item>
            <form {...form}>
                <Grid {...grid}>{children}</Grid>
            </form>
        </Grid>
    );
};

export default GridForm;
