import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';

const GridContainerLoader = (
    props: GridProps & {
        items: number;
        show: boolean;
        GridItemProps?: GridProps;
        SkeletonProps?: SkeletonProps;
    }
) => {
    const { items, show, GridItemProps, SkeletonProps, ...otherProps } = props;

    const values = [];

    if (show) {
        for (let i = 0; i < items; ++i) {
            values.push(
                <Grid item {...GridItemProps} key={i}>
                    <Skeleton {...SkeletonProps} />
                </Grid>
            );
        }
    }

    if (values.length > 0) {
        // Don't show any children
        delete otherProps.children;

        return (
            <Grid container {...otherProps}>
                {values}
            </Grid>
        );
    }

    return <Grid container {...otherProps} />;
};

export default GridContainerLoader;
