import { useMemo } from 'react';

import { createURLParams, ListParams, ListResponse } from './util';
import { Service as BaseService, useService as useBaseService } from './Base';

// TODO Once we have Stripe.js installed, we can just export those interfaces
export interface Price {
    id: string;
    nickname: string;
    recurring: {
        interval: 'month' | 'year';
    };
    unit_amount: number;
    currency: string;
    metadata: {
        monthly_limit: string;
        pdn_type: 'standard' | 'premium' | 'priority';
        sla?: string;
    };
}

export interface Invoice {
    id: string;
    created: number;
    account_name: string;
    customer_name: string;
    amount_paid: number;
    status: string;
    invoice_pdf: string;
    currency: string;
}

// TODO Make card optional once we actually allow other method types
export interface PaymentMethod {
    id: string;
    card: {
        exp_month: string;
        exp_year: string;
        last4: string;
    };
}

export interface Subscription {
    id: string;
    created: number;
    items: {
        data: {
            price: Price;
        }[];
    }[];
    // Equivalent to price id
    plan: {
        id: string;
    };
}

interface CreateOrUpdateSubscriptionParams {
    stripeSubscriptionPaymentMethod: string;
    stripePrice: string;
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async listPrices() {
        return await this.base.fetchAPI<ListResponse<Price>>('/billing/prices');
    }

    async list(params: ListParams) {
        const urlParams = createURLParams(params);

        return await this.base.fetchAPI<ListResponse<Invoice>>(
            `/billing/invoices?${urlParams.toString()}`
        );
    }

    async listPaymentMethods() {
        return await this.base.fetchAPI<ListResponse<PaymentMethod>>(
            '/billing/payment_methods'
        );
    }

    async addPaymentMethod(stripePaymentMethodID: string) {
        return await this.base.fetchAPI<PaymentMethod>(
            '/billing/payment_methods',
            {
                method: 'POST',
                body: {
                    stripePaymentMethod: stripePaymentMethodID,
                },
            }
        );
    }

    async getSubscription() {
        return await this.base.fetchAPI<Subscription>('/billing/subscription');
    }

    async tryGetSubscription() {
        return await this.base.fetchAPIAllow404<Subscription>(
            '/billing/subscription'
        );
    }

    async createOrUpdateSubscription(data: CreateOrUpdateSubscriptionParams) {
        return await this.base.fetchAPI<Subscription>('/billing/subscription', {
            method: 'POST',
            body: data,
        });
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};
