import { useMemo } from 'react';

import { createURLParams, ListParams, ListResponse } from './util';
import {
    Resource,
    ResourceCreateParams,
    useService as useBaseService,
} from './Base';

import { Service as ResourceService } from './DeletableResource';

export enum WebhookEventType {
    LETTER_CREATED = 'letter.created',
    LETTER_UPDATED = 'letter.updated',

    POSTCARD_CREATED = 'postcard.created',
    POSTCARD_UPDATED = 'postcard.updated',

    CHEQUE_CREATED = 'cheque.created',
    CHEQUE_UPDATED = 'cheque.updated',

    RETURN_ENVELOPE_ORDER_CREATED = 'return_envelope_order.created',
    RETURN_ENVELOPE_ORDER_UPDATED = 'return_envelope_order.updated',
}

export interface Webhook extends Resource {
    enabledEvents: WebhookEventType[];
    secret: string;
    enabled: boolean;
    url: string;
    deleted?: boolean;
}

export interface WebhookInvocation {
    id: string;
    webhook: string;
    type: WebhookEventType;
    statusCode?: number;
    orderID: string;
    createdAt: Date;
    updatedAt: Date;
}

type UpdateParams = Partial<
    Omit<Webhook, 'secret' | 'deleted' | keyof Resource>
> &
    ResourceCreateParams;

type CreateParams = Partial<
    Omit<Webhook, 'secret' | 'deleted' | 'enabled' | keyof Resource>
> &
    ResourceCreateParams;

export class Service extends ResourceService<Webhook> {
    async create(data: CreateParams) {
        return await this.base.fetchAPI<Webhook>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async update(id: string, data: UpdateParams) {
        return await this.base.fetchAPI<Webhook>(`/${this.route}/${id}`, {
            method: 'POST',
            body: data,
        });
    }

    async getInvocations(id: string, params: ListParams) {
        const urlParams = createURLParams(params);

        return await this.base.fetchAPI<ListResponse<WebhookInvocation>>(
            `/${this.route}/${id}/invocations?${urlParams.toString()}`
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'webhooks'), [base]);
};
