import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Sidenav from '../components/Sidenav';

import Overview from './Overview';
import Analytics from './Analytics';
import Contacts from './Contacts';
import Templates from './Templates';
import BankAccounts from './BankAccounts';
import Letters from './Letters';
import Postcards from './Postcards';
import Cheques from './Cheques';
import Invoices from './Invoices';
import Payment from './Payment';
import Upgrade from './Upgrade';
import Users from './Users';
import Settings from './Settings';
import BackgroundSends from './BackgroundSends';
import Webhooks from './Webhooks';
import ReturnEnvelopes from './ReturnEnvelopes';
import APILogs from './APILogs';
import {
    APILogRoutes,
    BankAccountRoutes,
    ChequeRoutes,
    ContactRoutes,
    LetterRoutes,
    PostcardRoutes,
    ReturnEnvelopeRoutes,
    TemplateRoutes,
    WebhookRoutes,
} from '../routes';

const useGridWrapperStyle = makeStyles((theme) => ({
    root: {
        margin: '90px auto 0',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
    },
}));

const Dashboard = () => {
    const match = useRouteMatch();

    const gridWrapper = useGridWrapperStyle();

    return (
        <>
            <Sidenav />
            <Box display="flex">
                <Box flexShrink={0} width="255px" />
                <Box flexGrow={1}>
                    <Container classes={{ root: gridWrapper.root }}>
                        <Box m={4}>
                            <Switch>
                                <Route
                                    exact
                                    path={`${match.path}`}
                                    component={Overview}
                                />
                                <Route
                                    exact
                                    path={`${match.path}/analytics`}
                                    component={Analytics}
                                />
                                <Route
                                    path={WebhookRoutes.HOME}
                                    component={Webhooks}
                                />
                                <Route
                                    path={`${match.path}/invoices`}
                                    component={Invoices}
                                />
                                <Route
                                    path={`${match.path}/payment`}
                                    component={Payment}
                                />
                                <Route
                                    path={`${match.path}/upgrade`}
                                    component={Upgrade}
                                />
                                <Route
                                    path={`${match.path}/users`}
                                    component={Users}
                                />
                                <Route
                                    path={`${match.path}/settings`}
                                    component={Settings}
                                />
                                <Route
                                    path={APILogRoutes.HOME}
                                    component={APILogs}
                                />
                                <Route
                                    path={`${match.path}/background_sends`}
                                    component={BackgroundSends}
                                />
                                <Route
                                    path={ContactRoutes.HOME}
                                    component={Contacts}
                                />
                                <Route
                                    path={TemplateRoutes.HOME}
                                    component={Templates}
                                />
                                <Route
                                    path={BankAccountRoutes.HOME}
                                    component={BankAccounts}
                                />
                                <Route
                                    path={ReturnEnvelopeRoutes.HOME}
                                    component={ReturnEnvelopes}
                                />
                                <Route
                                    path={LetterRoutes.HOME}
                                    component={Letters}
                                />
                                <Route
                                    path={PostcardRoutes.HOME}
                                    component={Postcards}
                                />
                                <Route
                                    path={ChequeRoutes.HOME}
                                    component={Cheques}
                                />
                            </Switch>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;
