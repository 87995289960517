import React, { useEffect, useContext } from 'react';

import TableCell from '@material-ui/core/TableCell';

import { Context as LiveApprovedContext } from '../context/LiveApproved';

import { useService as useChequesService } from '../services/Cheques';

import { label as contactLabel } from '../services/Contacts';
import { statusLabel } from '../services/Letters';

import OrdersPage from '../components/OrdersPage';

import CreateCheque from './CreateCheque';
import ViewCheque from './ViewCheque';
import { formatTableDate } from '../services/util';

const Cheques = () => {
    const service = useChequesService();
    const { checkLiveApproved } = useContext(LiveApprovedContext);

    useEffect(() => {
        checkLiveApproved(
            'Fill out our KYC form to get access to cheques in live mode.'
        );
    }, []);

    return (
        <OrdersPage
            service={service}
            object="cheque"
            headings={[
                'Description',
                'Recipient',
                'Amount',
                'Status',
                'Created At',
            ]}
            getCells={(value) => {
                return (
                    <>
                        <TableCell>{value.description}</TableCell>
                        <TableCell>{contactLabel(value.to)}</TableCell>
                        <TableCell>
                            {(value.amount / 100).toLocaleString(undefined, {
                                style: 'currency',
                                currency: value.currencyCode,
                            })}
                        </TableCell>
                        <TableCell style={{ textTransform: 'capitalize' }}>
                            {statusLabel(value.status)}
                        </TableCell>
                        <TableCell>
                            {formatTableDate(value.createdAt)}
                        </TableCell>
                    </>
                );
            }}
            Create={CreateCheque}
            View={ViewCheque}
        />
    );
};

export default Cheques;
