import React, { ReactNode, useContext } from 'react';

import { Context as ModeContext } from '../context/Mode';

import Grid, { GridProps } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';

export type PageHeaderProps = Omit<
    GridProps,
    'spacing' | 'container' | 'direction' | 'item'
> & {
    /** Title of the header.  `required` */
    title: string;
    /** Display the alert in warning the color.  `optional` */
    liveWarning?: boolean;
    /** Custom text for the alert box.  `optional` */
    alertText?: string | ReactNode;
    /** Does not display the test mode display.  `optional` */
    hideAlert?: boolean;
};

/**
 *
 * @param title The title of the header
 * @param liveWarningText Indicates if the alert should be in warning style
 * @param alertText An optional specified message to display in the alert.
 * @param hideAlert Hides the test mode alert.
 * @param children A list of children to be displayed to the right of the header.
 * Each child should be wrapped in a MUI Grid item.
 * @param GridProps Any prop you could pass to a MUI Grid component except for `container`, `spacing`, `item` and `direction`
 */
const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    liveWarning,
    alertText,
    hideAlert,
    children,
    ...props
}) => {
    const { live } = useContext(ModeContext);
    return (
        <Grid
            item
            container
            direction="column"
            spacing={2}
            {...props}
            data-testid="page-header"
        >
            <Grid item container justify="space-between">
                <Grid item>
                    <Typography
                        variant="h5"
                        style={{ textTransform: 'capitalize' }}
                    >
                        {title}
                    </Typography>
                </Grid>
                {children && (
                    <Grid item>
                        <Grid container justify="flex-end" spacing={2}>
                            {children}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {!live && !hideAlert ? (
                <Grid item>
                    <Alert
                        variant="outlined"
                        color={liveWarning ? 'warning' : 'info'}
                    >
                        {alertText ? (
                            alertText
                        ) : (
                            <span>
                                You are in test mode, so you will only see test
                                mode {title.toLocaleLowerCase()}.
                            </span>
                        )}
                    </Alert>
                </Grid>
            ) : (
                live && <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            )}
        </Grid>
    );
};

export default PageHeader;
