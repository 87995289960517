import React from 'react';

import { PaperProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 10,
        height: '100%',
        width: '100%',
    },
}));

const PaperWrapper: React.FC<PaperProps> = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <Paper
            variant="outlined"
            data-testid="paper-wrapper"
            className={`${classes.root} ${props.className}`}
            {...props}
        >
            {children}
        </Paper>
    );
};

export default PaperWrapper;
