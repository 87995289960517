import Box from '@material-ui/core/Box';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Row } from './UploadContactsDialog';

interface RowErrorsProps {
    file: File | null;
    rows: Row[];
    invalidRowCount: number;
}

const RowErrors: React.FC<RowErrorsProps> = ({
    file,
    rows,
    invalidRowCount,
}) => {
    if (!file) {
        return null;
    }

    if (rows.length > 0) {
        return (
            <DialogContentText
                data-testid="upload-contacts-row-info"
                component={'div'}
            >
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography component="span">
                        Found {rows.length} contact
                        {rows.length === 1 ? '' : 's'} in uploaded file.
                    </Typography>
                    {invalidRowCount > 0 && (
                        <Typography color="secondary" component="span">
                            There{' '}
                            {invalidRowCount === 1
                                ? `is ${invalidRowCount} row`
                                : `are ${invalidRowCount} rows`}{' '}
                            missing an address, country code, a name/company
                            name. {invalidRowCount === 1 ? 'This' : 'These'}{' '}
                            will be skipped.
                        </Typography>
                    )}
                </Box>
            </DialogContentText>
        );
    }

    if (rows.length === 0 && invalidRowCount > 0) {
        return (
            <DialogContentText data-testid="upload-contacts-no-valid-contacts">
                <Typography color="secondary" component="span">
                    Your file contains no valid contacts. Please ensure that
                    each contact has an address, country code, and a name or
                    company name.
                </Typography>
            </DialogContentText>
        );
    }

    return (
        <DialogContentText data-testid="upload-contacts-empty-file">
            <Typography color="secondary" component="span">
                The file you have uploaded is empty.
            </Typography>
        </DialogContentText>
    );
};

export default RowErrors;
