import React from 'react';
import { Link } from 'react-router-dom';
import { OrderObjects } from '../services/Orders';
import grey from '@material-ui/core/colors/grey';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';

interface InvocationOrderIDCellProps {
    orderID?: string;
}

export const orderLink = (id?: string) => {
    if (!id) {
        return;
    }
    const object = id.split('_')[0];
    switch (object) {
        // TODO: Add routes for orders
        case OrderObjects.LETTER:
            return `/dashboard/letters/${id}`;
        case OrderObjects.POSTCARD:
            return `/dashboard/postcards/${id}`;
        case OrderObjects.CHEQUE:
            return `/dashboard/cheques/${id}`;
    }
};

const InvocationOrderIDCell: React.FC<InvocationOrderIDCellProps> = ({
    orderID,
}) => {
    const link = orderLink(orderID);
    if (link) {
        return (
            <TableCell>
                <Link to={link}>{orderID}</Link>
            </TableCell>
        );
    }
    if (orderID) {
        return <TableCell>{orderID}</TableCell>;
    }
    return (
        <TableCell>
            <Box fontStyle="italic" color={grey[500]}>
                Missing ID value
            </Box>
        </TableCell>
    );
};

export default InvocationOrderIDCell;
