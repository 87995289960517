import React, { useCallback } from 'react';

import TopNav from '../components/TopNav';
import RawData from '../components/RawData';
import GridPaper from '../components/GridPaper';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditWebhook from '../components/EditWebhook';
import Grid from '@material-ui/core/Grid';
import WebhookInvocationsTable from '../components/WebhookInvocationsTable';
import { useService } from '../services/Webhooks';
import { useFetchResource } from '../hooks/useFetchResource';
import { WebhookRoutes } from '../routes';

const useStyles = makeStyles(() => ({
    // HACK:
    // this will prevent overflowing in the x direction
    root: {
        // 255px for sidenav
        // 64px -> Box with margin of 4 surrounding in `Dasboard.tsx`
        //      -> 4 -> 8px * 4 = 64
        // 64px again because magin affects left and right side
        width: 'calc(100vw - 255px - 64px - 64px)',
    },
}));

const ViewWebhook: React.FC = () => {
    const classes = useStyles();
    const service = useService();

    const fetchWebhook = useCallback(
        (id: string) => service.get(id),
        [service]
    );

    const {
        data: webhook,
        fetching,
        setData: setWebhook,
    } = useFetchResource(WebhookRoutes.HOME, fetchWebhook);

    return (
        <Box className={classes.root}>
            <TopNav />
            {webhook && !fetching && (
                <GridPaper direction="column" spacing={2}>
                    <EditWebhook
                        service={service}
                        setWebhook={setWebhook}
                        webhook={webhook}
                    />
                    <Grid item container direction="column" spacing={2}>
                        <WebhookInvocationsTable service={service} />
                        <Grid item>
                            <RawData obj={webhook} />
                        </Grid>
                    </Grid>
                </GridPaper>
            )}
        </Box>
    );
};

export default ViewWebhook;
