import React, { createContext, useContext, useState, useCallback } from 'react';
import { useExtraService } from '../hooks/useExtraService';
import { BankAccount } from '../services/BankAccounts';
import { OrderExtraService, OrderMailingClass } from '../services/Base';
import { Contact } from '../services/Contacts';
import { Template } from '../services/Templates';

interface State {
    description: string;
    setDescription: (s: string) => void;
    toContacts: Contact[];
    setToContacts: (t: Contact[]) => void;
    fromContact: Contact | null;
    setFromContact: (f: Contact | null) => void;
    account: BankAccount | null;
    setAccount: (a: BankAccount | null) => void;
    number: number | undefined;
    setNumber: (n: number) => void;
    amount: number;
    setAmount: (n: number) => void;
    memo: string;
    setMemo: (m: string) => void;
    message: string;
    setMessage: (m: string) => void;
    mergeVars: Record<string, string>;
    setMergeVars: (o: Record<string, string>) => void;
    uploadedCSV: boolean;
    setUploadedCSV: (b: boolean) => void;
    template: Template | null;
    setTemplate: (t: Template | null) => void;
    file: File | null;
    setFile: (f: File | null) => void;
    loading: boolean;
    setLoading: (b: boolean) => void;
    express: boolean;
    setExpress: (b: boolean) => void;
    attachLetter: boolean;
    setAttachLetter: (f: boolean) => void;
    extraService?: OrderExtraService;
    extraServiceForm: '' | OrderExtraService;
    setExtraService: (x: '' | OrderExtraService) => void;
    mailingClass: OrderMailingClass;
    setMailingClass: (c: OrderMailingClass) => void;
    resetState: () => void;
}

const Context = createContext<State | undefined>(undefined);

// TODO: Fix `undefined`/`null` defaults for form data
export const CreateChequeProvider: React.FC = ({ children }) => {
    const { extraService, extraServiceForm, setExtraService } =
        useExtraService();

    const [description, setDescription] = useState('');
    const [toContacts, setToContacts] = useState<Contact[]>([]);
    const [fromContact, setFromContact] = useState<Contact | null>(null);
    const [account, setAccount] = useState<BankAccount | null>(null);
    const [number, setNumber] = useState<number | undefined>(undefined);
    const [amount, setAmount] = useState(0);
    const [memo, setMemo] = useState('');
    const [message, setMessage] = useState('');
    const [mergeVars, setMergeVars] = useState<Record<string, string>>({});
    const [uploadedCSV, setUploadedCSV] = useState(false);
    const [template, setTemplate] = useState<Template | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [mailingClass, setMailingClass] = useState<OrderMailingClass>(
        OrderMailingClass.FIRST_CLASS
    );
    const [express, setExpress] = useState(false);
    const [attachLetter, setAttachLetter] = useState(false);

    const resetState = useCallback(() => {
        setDescription('');
        setToContacts([]);
        setFromContact(null);
        setAccount(null);
        setNumber(undefined);
        setAmount(0);
        setMemo('');
        setMessage('');
        setExtraService('');
        setMailingClass(OrderMailingClass.FIRST_CLASS);
        setMergeVars({});
        setUploadedCSV(false);
        setTemplate(null);
        setFile(null);
        setLoading(false);
        setMergeVars({});
        setExpress(false);
        setAttachLetter(false);

        // don't need to re-do function
        // eslint-disable-next-line
    }, []);

    const value: State = {
        account,
        amount,
        description,
        file,
        fromContact,
        loading,
        memo,
        message,
        number,
        mergeVars,
        mailingClass,
        extraServiceForm,
        extraService,
        setExtraService,
        setMailingClass,
        setMergeVars,
        resetState,
        setAccount,
        setAmount,
        setDescription,
        setFile,
        setFromContact,
        setLoading,
        setMemo,
        setMessage,
        setNumber,
        setTemplate,
        setToContacts,
        setUploadedCSV,
        express,
        setExpress,
        template,
        toContacts,
        uploadedCSV,
        attachLetter,
        setAttachLetter,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCreateCheque = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'useCreateCheque can only be used within a CreateChequeProvider'
        );
    }
    return context;
};
