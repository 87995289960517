import React, { ReactNode, useCallback, useContext, useMemo } from 'react';

import {
    Switch,
    Route,
    RouteProps,
    useRouteMatch,
    useHistory,
} from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';

import { Order, Service as OrdersService } from '../services/Orders';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import { formatTableDate, ListParams } from '../services/util';
import { statusLabel } from '../services/Letters';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';
import {
    Context as DownloadOrdersContext,
    ActionType as BackgroundDownloadActionType,
} from '../context/BackgroundDownload';

import TopNav from '../components/TopNav';
import GridPaper from './GridPaper';

import Button from './Button';
import { CircularProgress } from '@material-ui/core';
import PageHeader from './PageHeader';
import TableDisplay from './TableDisplay';
import BulkCancelOrdersDialog from './BulkCancelOrdersDialog';
import { useModal } from '../hooks/useModal';
import { BulkCancelOrdersProvider } from '../context/BulkCancelOrders';
import { ReFetchProvider, useReFetchContext } from '../context/ReFetchContext';

type OrdersPageProps<T extends Order> = {
    object: 'letter' | 'postcard' | 'cheque';
    service: OrdersService<T>;
    headings?: string[];
    getCells?: (value: T) => ReactNode;
    Create: RouteProps['component'];
    View: RouteProps['component'];
};

function getDefaultTableCells<T extends Order>(order: T) {
    return (
        <>
            <TableCell>{order.description}</TableCell>
            <TableCell>
                {order.to.firstName
                    ? `${order.to.firstName} ${order.to.lastName || ''}`
                    : order.to.companyName || ''}
            </TableCell>
            <TableCell>{order.to.addressLine1}</TableCell>
            <TableCell
                style={{
                    textTransform: 'capitalize',
                }}
            >
                {statusLabel(order.status)}
            </TableCell>
            <TableCell>{formatTableDate(order.createdAt)}</TableCell>
        </>
    );
}

function OrdersPage<T extends Order>(props: OrdersPageProps<T>) {
    const headings = props.headings || [
        'Description',
        'Recipient',
        'Address',
        'Status',
        'Created At',
    ];

    const match = useRouteMatch();
    const history = useHistory();
    const { isModalOpen, closeModal, openModal } = useModal();
    const { reFetch } = useReFetchContext();

    const {
        state: backgroundDownloadState,
        dispatch: backgroundDownloadDispatch,
    } = useContext(DownloadOrdersContext);

    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const paginationFunc = useCallback(
        (params: ListParams) => {
            return props.service.list(params);
        },
        [props.service]
    );

    const dependencies = useMemo(() => [reFetch], [reFetch]);

    const {
        data: orders,
        loading,
        searchQuery,
        searchText,
        search,
        pagination,
    } = usePagination<T>(paginationFunc, dependencies);

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <BulkCancelOrdersProvider
                    service={props.service}
                    object={props.object}
                >
                    <BulkCancelOrdersDialog
                        open={isModalOpen}
                        onClose={closeModal}
                    />
                </BulkCancelOrdersProvider>
                <TopNav
                    showSearch
                    searchText={searchText}
                    searchQuery={searchQuery}
                    showDateRange
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader title={`${props.object}s`}>
                        <Grid item>
                            {backgroundDownloadState.active ? (
                                <CircularProgress
                                    variant={
                                        Number.isFinite(
                                            backgroundDownloadState.total
                                        ) && backgroundDownloadState.total > 0
                                            ? 'indeterminate'
                                            : 'indeterminate'
                                    }
                                    value={
                                        Number.isFinite(
                                            backgroundDownloadState.total
                                        ) && backgroundDownloadState.total > 0
                                            ? backgroundDownloadState.downloadCount /
                                              backgroundDownloadState.total
                                            : 0
                                    }
                                />
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={backgroundDownloadState.active}
                                    onClick={() => {
                                        try {
                                            backgroundDownloadDispatch({
                                                type: BackgroundDownloadActionType.INITIATE,
                                                fileName: `${props.object}s.csv`,
                                                list: async ({
                                                    skip,
                                                    limit,
                                                }: {
                                                    skip: number;
                                                    limit: number;
                                                }) => {
                                                    return await props.service.list(
                                                        {
                                                            skip,
                                                            limit,
                                                            search,
                                                        }
                                                    );
                                                },
                                            });
                                        } catch (err: any) {
                                            // Handle case of download already occurring
                                            notificationDispatch({
                                                type: MessageType.ERROR,
                                                message: err.message,
                                            });
                                        }
                                    }}
                                >
                                    Download {props.object}s
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={openModal}
                            >
                                Cancel {props.object}s
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    history.push(`${match.path}/create`);
                                }}
                            >
                                Create {props.object}
                            </Button>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            columns={headings}
                            show={loading}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {orders.map((order) => {
                                return (
                                    <TableRow
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            history.push(
                                                `${match.path}/${order.id}`
                                            );
                                        }}
                                        key={order.id}
                                    >
                                        {props.getCells
                                            ? props.getCells(order as T)
                                            : getDefaultTableCells(order as T)}
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={`${match.path}/create`} component={props.Create} />
            <Route path={`${match.path}/:orderID`} component={props.View} />
        </Switch>
    );
}

const OrdersPageWithRefetch = <T extends Order>(props: OrdersPageProps<T>) => {
    return (
        <ReFetchProvider>
            <OrdersPage {...props} />
        </ReFetchProvider>
    );
};

export default OrdersPageWithRefetch;
