import React from 'react';

import Grid from '@material-ui/core/Grid';

import FileUpload from './FileUpload';
import SelectTemplate from './SelectTemplate';
import { useCreateCheque } from '../context/CreateCheque';

const AttachChequeLetter = () => {
    const {
        file,
        setFile,
        setTemplate,
        template,
        attachLetter,
    } = useCreateCheque();

    if (!attachLetter) {
        return null;
    }

    return (
        <>
            <Grid item xs={6}>
                <SelectTemplate
                    label="Select a Template"
                    template={template}
                    setTemplate={setTemplate}
                    disabled={file ? true : false}
                />
            </Grid>
            <Grid item xs={6}>
                <Grid container justify="center" alignItems="center">
                    <FileUpload
                        accept="application/pdf"
                        label="Upload a PDF"
                        file={file}
                        setFile={setFile}
                        disabled={template ? true : false}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default AttachChequeLetter;
