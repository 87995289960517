import React from 'react';
import ConfirmActionDialog, {
    ConfirmActionDialogProps,
} from './ConfirmActionDialog';
import deleteGraphicLogo from '../img/icons/delete-graphic.png';

type ConfirmDeleteDialogProps = Omit<
    ConfirmActionDialogProps,
    'image' | 'actionLabel'
> & {
    actionLabel?: string;
};

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
    actionLabel,
    ...props
}) => {
    return (
        <ConfirmActionDialog
            image={deleteGraphicLogo}
            actionLabel={actionLabel ? actionLabel : 'Delete'}
            {...props}
        />
    );
};

export default ConfirmDeleteDialog;
