import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RawDataField from './RawDataField';

interface ObjectTableProps {
    obj: Record<string, any>;
}

const ObjectTable: React.FC<ObjectTableProps> = ({ obj }) => {
    const entries = Object.entries(obj);

    // HACK For the metadata
    return (
        <Table size="small">
            <TableBody>
                {entries.map(([key, value]) => (
                    <TableRow key={key}>
                        <TableCell component="th">
                            <Typography variant="body1" data-testid={`${key}`}>
                                {key}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Grid container spacing={1}>
                                <RawDataField objKey={key} value={value} />
                            </Grid>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ObjectTable;
