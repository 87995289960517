import React, { useState, useContext, FormEvent } from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
    BankAccountCountryCode,
    useService as useBankAccountsService,
} from '../services/BankAccounts';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import Field from '../components/Field';
import FileUpload from '../components/FileUpload';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import CreateResourceActions from '../components/CreateResourceActions';

const WHITE_THRESHOLD = 600;

const BANK_PLACEHOLDERS: Record<
    BankAccountCountryCode,
    {
        primaryLine: string;
        secondaryLine: string;
    }
> = {
    [BankAccountCountryCode.CA]: {
        primaryLine: '77 Bloor St W',
        secondaryLine: 'Toronto, ON M5S 1M2',
    },
    [BankAccountCountryCode.US]: {
        primaryLine: '270 Park Ave',
        secondaryLine: 'New York, NY 10172',
    },
};

// See https://stackoverflow.com/questions/6775767/how-can-i-draw-an-image-from-the-html5-file-api-on-canvas
// for info on how/why the Image is being loaded as such.
const removeWhiteBackground = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return reject(new Error('Failed to get canvas context.'));
        }

        const img = new Image();

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            ctx.drawImage(img, 0, 0);

            // Required not to leak the data apparently
            URL.revokeObjectURL(img.src);

            const imageData = ctx.getImageData(0, 0, img.width, img.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                if (data[i] + data[i + 1] + data[i + 2] >= WHITE_THRESHOLD) {
                    data[i + 3] = 0;
                }
            }

            ctx.putImageData(imageData, 0, 0);

            canvas.toBlob((blob) => {
                if (!blob) {
                    return reject(
                        new Error('Failed to generate Blob from canvas.')
                    );
                }

                resolve(blob);
            });
        };

        img.src = URL.createObjectURL(file);
    });
};

const CreateBankAccount = (props: {}) => {
    const history = useHistory();

    const service = useBankAccountsService();

    const { dispatch } = useContext(NotificationContext);

    const [description, setDescription] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankPrimaryLine, setBankPrimaryLine] = useState('');
    const [bankSecondaryLine, setBankSecondaryLine] = useState('');

    const [bankCountryCode, setBankCountryCode] =
        useState<BankAccountCountryCode>(BankAccountCountryCode.CA);

    const [transitNumber, setTransitNumber] = useState('');
    const [routeNumber, setRouteNumber] = useState('');

    const [routingNumber, setRoutingNumber] = useState('');

    const [accountNumber, setAccountNumber] = useState('');

    const [signatureImage, setSignatureImage] = useState<File | null>(null);
    const [signatureText, setSignatureText] = useState('');

    const [shouldRemoveWhiteBackground, setShouldRemoveWhiteBackground] =
        useState(false);

    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!signatureImage && !signatureText) {
            // Should be impossible, it is required
            return;
        }

        try {
            setLoading(true);

            let image: Blob | null = signatureImage;

            if (shouldRemoveWhiteBackground && signatureImage) {
                image = await removeWhiteBackground(signatureImage);
            }

            await service.create({
                description,

                bankName,
                bankPrimaryLine,
                bankSecondaryLine,
                bankCountryCode,

                transitNumber,
                routeNumber,

                routingNumber,

                accountNumber,

                signatureImage: image || undefined,
                signatureText,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Created bank account.',
            });

            history.push('/dashboard/bank_accounts');
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Create a Bank Account
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <form onSubmit={onSubmit}>
                        <Box py={3}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12}>
                                    <Alert color="warning" variant="outlined">
                                        Please make sure all the information
                                        here is correct. If you do not have a
                                        signature image, you can enter some
                                        signature text and we will generate one.
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        value={description}
                                        setValue={setDescription}
                                        fullWidth
                                        label="Description"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={bankName}
                                        setValue={setBankName}
                                        required
                                        fullWidth
                                        label="Bank Name"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">
                                                    Bank Country
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    value={bankCountryCode}
                                                    onChange={(e, v) =>
                                                        setBankCountryCode(
                                                            v as BankAccountCountryCode
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value={
                                                            BankAccountCountryCode.CA
                                                        }
                                                        control={
                                                            <Radio color="primary" />
                                                        }
                                                        label="Canada"
                                                    />
                                                    <FormControlLabel
                                                        value={
                                                            BankAccountCountryCode.US
                                                        }
                                                        control={
                                                            <Radio color="primary" />
                                                        }
                                                        label="United States"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        placeholder={
                                            BANK_PLACEHOLDERS[bankCountryCode]
                                                .primaryLine
                                        }
                                        value={bankPrimaryLine}
                                        setValue={setBankPrimaryLine}
                                        fullWidth
                                        label="Bank Address Line 1"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        placeholder={
                                            BANK_PLACEHOLDERS[bankCountryCode]
                                                .secondaryLine
                                        }
                                        value={bankSecondaryLine}
                                        setValue={setBankSecondaryLine}
                                        fullWidth
                                        label="Bank Address Line 2"
                                    />
                                </Grid>
                                {bankCountryCode ===
                                BankAccountCountryCode.CA ? (
                                    <>
                                        <Grid item xs={6}>
                                            <Field
                                                value={transitNumber}
                                                setValue={setTransitNumber}
                                                inputProps={{
                                                    minLength: 5,
                                                    maxLength: 5,
                                                }}
                                                required
                                                fullWidth
                                                label="Transit Number"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                value={routeNumber}
                                                setValue={setRouteNumber}
                                                inputProps={{
                                                    minLength: 3,
                                                    maxLength: 3,
                                                }}
                                                required
                                                fullWidth
                                                label="Institution Number"
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={6}>
                                            <Field
                                                value={routingNumber}
                                                setValue={setRoutingNumber}
                                                inputProps={{
                                                    minLength: 9,
                                                    maxLength: 9,
                                                }}
                                                required
                                                fullWidth
                                                label="Routing Number"
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={6}>
                                    <Field
                                        value={accountNumber}
                                        setValue={setAccountNumber}
                                        inputProps={{
                                            minLength: 7,
                                        }}
                                        required
                                        fullWidth
                                        label="Account Number"
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <FileUpload
                                        label="Upload a Signature Image"
                                        accept="image/jpeg,image/png"
                                        file={signatureImage}
                                        setFile={setSignatureImage}
                                        required={signatureText.length === 0}
                                        disabled={signatureText.length > 0}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Field
                                        value={signatureText}
                                        setValue={setSignatureText}
                                        required={!signatureImage}
                                        disabled={!!signatureImage}
                                        label="Signature Text (Instead of an Image)"
                                    />
                                </Grid>

                                {signatureImage && (
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        shouldRemoveWhiteBackground
                                                    }
                                                    onChange={(e) => {
                                                        setShouldRemoveWhiteBackground(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Remove White Background from Signature"
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <CreateResourceActions disabled={loading} />
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateBankAccount;
