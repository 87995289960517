import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { unsplashList } from 'polotno/utils/api';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { SectionTab } from 'polotno/side-panel';

import { Icon, InputGroup } from '@blueprintjs/core';

const EditorImagesPanel = observer(({ store }: { store: any }) => {
    const [images, setImages] = useState([]);
    const [query, setQuery] = useState('canvas');
    const [page, setPage] = useState(1);

    const loadImages = () => {
        fetch(unsplashList({ page, query }))
            .then((res) => res.json())
            .then((data) => {
                setImages((images) =>
                    images.concat(
                        data.results.map((image: any) => ({
                            ...image,
                            key: image.id,
                        }))
                    )
                );
            });
    };

    useEffect(loadImages, [page, query]);

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <InputGroup
                leftIcon="search"
                placeholder="Search..."
                onChange={(e) => {
                    setImages([]);
                    setQuery(e.target.value);
                }}
                style={{
                    marginBottom: '20px',
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p>
                    Photos by{' '}
                    <a href="https://unsplash.com" target="_blank">
                        Unsplash
                    </a>
                </p>
            </div>
            <ImagesGrid
                images={images}
                getPreview={(image) => image.urls.thumb}
                onSelect={(image, pos) => {
                    // We resize all images to
                    const width = 400;
                    const height = image.height * (400 / image.width);

                    const x = (pos?.x ?? store.width / 2) - width / 2;
                    const y = (pos?.y ?? store.height / 2) - height / 2;

                    store.activePage?.addElement({
                        type: 'image',
                        src: image.urls.full,
                        width,
                        height,
                        x,
                        y,
                    });
                }}
                rowsNumber={2}
                isLoading={images.length === 0}
                loadMore={() => {
                    setPage(page + 1);
                }}
                shadowEnabled
            />
        </div>
    );
});

export const EditorImagesSection = {
    name: 'photos',
    // HACK This seems to expect a function object returning a JSX.Element with a "displayName" property as well?
    Tab: (() => {
        const fn = (props: any) => (
            <SectionTab name="Photos" {...props}>
                <Icon icon="media" />
            </SectionTab>
        );

        fn.displayName = 'Photos';
        return fn;
    })(),
    Panel: EditorImagesPanel,
};

export default EditorImagesPanel;
