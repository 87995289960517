import React, { FormEvent, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useModal } from '../hooks/useModal';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';

import ErrorIcon from '@material-ui/icons/Error';
import changePassGraphic from '../img/change-pass-graphic.png';

import {
    ActionType,
    Context as AuthContext,
    State as AuthState,
} from '../context/Auth';
import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import {
    Service as UsersService,
    useService as useUsersService,
} from '../services/Users';

import {
    AddressStrictness,
    Organization,
    useService as useOrganizationService,
} from '../services/Organization';

import GridContainerLoader from '../components/GridContainerLoader';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import APIKeys from '../components/APIKeys';

export const API_DOCS_URL = 'https://docs.postgrid.com';

const useDialogStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        borderRadius: '6px',
        '&>.MuiDialog-scrollPaper': {
            '&>.MuiDialog-paper': {
                borderRadius: '8px',
                padding: '20px',
                paddingBottom: '40px',
                minWidth: '500px',
            },
        },
    },
}));

const ChangePasswordDialog = (props: {
    service: UsersService;
    userID: string;
    open: boolean;
    onClose: () => void;
}) => {
    const { dispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPssword] = useState('');
    const [formError, setFormError] = useState('');

    const classes = useDialogStyles();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPassword)
            setFormError("New Password and Confirm Password don't match");
        else {
            setFormError('');
            try {
                setLoading(true);

                await props.service.update(props.userID, {
                    oldPassword,
                    password: newPassword,
                });

                setOldPassword('');
                setNewPassword('');
                setConfirmPssword('');

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Updated password.',
                });

                props.onClose();
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        }
    };

    return (
        <Dialog {...props} classes={classes}>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <img
                        src={changePassGraphic}
                        alt="Change password graphic"
                        style={{
                            margin: '10px auto',
                            display: 'block',
                            transform: 'translate(10%, 0)',
                        }}
                    />
                    <DialogTitle>Change Password</DialogTitle>
                    <Box height={10}></Box>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                type="password"
                                variant="outlined"
                                label="Old Password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                                fullWidth
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="password"
                                variant="outlined"
                                label="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                fullWidth
                                inputProps={{
                                    minLength: 8,
                                }}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="password"
                                variant="outlined"
                                label="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                    setConfirmPssword(e.target.value)
                                }
                                required
                                fullWidth
                                inputProps={{
                                    minLength: 8,
                                }}
                                disabled={loading}
                            />
                        </Grid>
                        {formError.length ? (
                            <Grid item>
                                <Alert variant="outlined" color="warning">
                                    {formError}
                                </Alert>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                <Box height={15}></Box>
                <DialogActions>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={5}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                    props.onClose();
                                }}
                                disabled={loading}
                                size="large"
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                                size="large"
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const Profile = (props: {
    loading: boolean;
    state: AuthState;

    name?: string;
    setName: (v: string) => void;

    phoneNumber?: string;
    setPhoneNumber: (v: string) => void;
}) => {
    return (
        <Grid container>
            <Grid item>
                <Typography variant="h6">Profile</Typography>
                <Box my={2}>
                    <GridContainerLoader
                        show={props.loading}
                        items={3}
                        spacing={2}
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Name"
                                fullWidth
                                value={props.name}
                                onChange={(e) => {
                                    props.setName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                inputProps={{
                                    readOnly: true,
                                    spellCheck: false,
                                }}
                                fullWidth
                                value={props.state.user?.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Phone Number"
                                fullWidth
                                value={props.phoneNumber}
                                onChange={(e) => {
                                    props.setPhoneNumber(e.target.value);
                                }}
                            />
                        </Grid>
                    </GridContainerLoader>
                </Box>
            </Grid>
        </Grid>
    );
};

const useBoxStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #E3E3E3',
        padding: '15px 30px',
        borderRadius: '4px',
        margin: '5px 0',
    },
}));

const useTooltipStyle = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        border: '1px solid #E3E3E3',
        padding: '8px',
        borderRadius: '8px',
        color: '#707070',
        fontSize: '14px',
    },
    arrow: {
        color: theme.palette.common.white,
    },
}));

const AddressStrictnessSetting = (props: {
    loading: boolean;
    addressStrictness?: AddressStrictness;
    setAddressStrictness: (v: AddressStrictness) => void;
}) => {
    const classes = useBoxStyle();
    const tooltipClass = useTooltipStyle();

    return (
        <Box my={2}>
            <GridContainerLoader
                direction="column"
                items={2}
                show={props.loading}
                spacing={1}
            >
                <Grid item>
                    <Typography variant="h6">Address Strictness</Typography>
                </Grid>
                <Box className={classes.root}>
                    <Grid container item>
                        <Grid item>
                            <Tooltip
                                title={
                                    <>
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{ marginBottom: '-5px' }}
                                        />{' '}
                                        Only allow sending to verified
                                        addresses.
                                    </>
                                }
                                arrow
                                classes={tooltipClass}
                                placement="bottom-end"
                            >
                                <FormControlLabel
                                    label="Verified"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                props.addressStrictness ===
                                                AddressStrictness.ALLOW_VERIFIED
                                            }
                                            onClick={() =>
                                                props.setAddressStrictness(
                                                    AddressStrictness.ALLOW_VERIFIED
                                                )
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={
                                    <>
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{ marginBottom: '-5px' }}
                                        />{' '}
                                        Allow sending to addresses that are
                                        automatically corrected.
                                    </>
                                }
                                arrow
                                classes={tooltipClass}
                                placement="bottom-end"
                            >
                                <FormControlLabel
                                    label="Corrected"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                props.addressStrictness ===
                                                AddressStrictness.ALLOW_CORRECTED
                                            }
                                            onClick={() =>
                                                props.setAddressStrictness(
                                                    AddressStrictness.ALLOW_CORRECTED
                                                )
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={
                                    <>
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{ marginBottom: '-5px' }}
                                        />{' '}
                                        Allow sending to addresses that fail to
                                        verify.
                                    </>
                                }
                                arrow
                                classes={tooltipClass}
                                placement="bottom-end"
                            >
                                <FormControlLabel
                                    label="Failed"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                props.addressStrictness ===
                                                AddressStrictness.ALLOW_FAILED
                                            }
                                            onClick={() =>
                                                props.setAddressStrictness(
                                                    AddressStrictness.ALLOW_FAILED
                                                )
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            </GridContainerLoader>
        </Box>
    );
};

const Settings = (props: {}) => {
    const history = useHistory();

    const orgService = useOrganizationService();

    const { state, dispatch } = useContext(AuthContext);
    const { dispatch: notifDispatch } = useContext(NotificationContext);

    const [org, setOrg] = useState<Organization>();

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');

    const [addressStrictness, setAddressStrictness] =
        useState<AddressStrictness>();

    const service = useUsersService();

    const [loading, setLoading] = useState(true);
    const {
        isModalOpen: changePasswordOpen,
        toggleModal: toggleChangePasswordModal,
    } = useModal();

    // TODO This is a little hacky, refactor?
    const userChanged =
        name !== state.user?.name || phoneNumber !== state.user?.phoneNumber;
    const orgChanged = addressStrictness !== org?.addressStrictness;

    const changed = userChanged || orgChanged;

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const [user, org] = await Promise.all([
                    service.get(state.user!.id),
                    orgService.get(),
                ]);

                setName(user.name);
                setPhoneNumber(user.phoneNumber);

                setOrg(org);
                setAddressStrictness(org.addressStrictness);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [history.location, dispatch, state.user, service, orgService]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            const [user, org] = await Promise.all([
                userChanged
                    ? service.update(state.user!.id, {
                          name,
                          phoneNumber,
                      })
                    : Promise.resolve(null),
                orgChanged
                    ? orgService.update({ addressStrictness })
                    : Promise.resolve(null),
            ]);

            if (user) {
                dispatch({
                    type: ActionType.UPDATE_USER,
                    user: user,
                });
            }

            if (org) {
                setOrg(org);
            }

            notifDispatch({
                type: MessageType.SUCCESS,
                message: 'Saved settings.',
            });
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    // NOTE It is very important we put dialogs outside the forms, otherwise the
    // events seem to trickle up to the form itself.
    return (
        <>
            <TopNav />

            <ChangePasswordDialog
                userID={state.user!.id}
                open={changePasswordOpen}
                onClose={toggleChangePasswordModal}
                service={service}
            />
            <GridPaper direction="column" spacing={2}>
                <form onSubmit={onSubmit}>
                    <Grid item>
                        <Box mb={3}>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography variant="h5">
                                        Settings
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={
                                                    toggleChangePasswordModal
                                                }
                                            >
                                                Change Password
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading || !changed}
                                            >
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item>
                            <Box mb={3}>
                                <Alert
                                    variant="outlined"
                                    color="info"
                                    action={
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                window.open(
                                                    API_DOCS_URL,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            Docs
                                        </Button>
                                    }
                                >
                                    You can use the API keys to create orders
                                    with code or integrate with other software.
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="stretch">
                                <Grid item xs={8}>
                                    <Profile
                                        loading={loading}
                                        state={state}
                                        name={name}
                                        setName={setName}
                                        phoneNumber={phoneNumber}
                                        setPhoneNumber={setPhoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <APIKeys loading={loading} />
                                </Grid>
                                <Grid item xs={8}>
                                    <AddressStrictnessSetting
                                        loading={loading}
                                        addressStrictness={addressStrictness}
                                        setAddressStrictness={
                                            setAddressStrictness
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default Settings;
