import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import JSONDisplay from '../components/JSONDisplay';
import DetailCell from '../components/DetailCell';
import DetailWrapper from '../components/DetailWrapper';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import RawData from '../components/RawData';
import StatusCodeCell from '../components/StatusCodeCell';
import TopNav from '../components/TopNav';
import { useFetchResource } from '../hooks/useFetchResource';
import { APILogRoutes } from '../routes';
import { useService } from '../services/APILogs';
import { formatTableDate } from '../services/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import PaperWrapper from '../components/PaperWrapper';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'flex-start',
    },
    child: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
    },
    json: {
        overflow: 'auto',
    },
}));

const ViewAPILog: React.FC = () => {
    const classes = useStyles();
    const service = useService();
    const fetch = useCallback((id: string) => service.get(id), [service]);
    const showOnExtraLarge = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('xl')
    );
    const showOnLarge = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );
    const { data, fetching } = useFetchResource(APILogRoutes.HOME, fetch);

    if (!data || fetching)
        return (
            <>
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <CircularProgress style={{ alignSelf: 'center' }} />
                </GridPaper>
            </>
        );

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2} style={{ flexGrow: 1 }}>
                <PageHeader
                    title="API Log Details"
                    alertText="You are in test mode.  This log was for a call made in test mode."
                />
                <Grid item>
                    <Box
                        className={classes.container}
                        flexDirection={showOnExtraLarge ? 'row' : 'column'}
                        data-testid={showOnExtraLarge ? 'xl' : 'lg'}
                    >
                        <Box className={classes.child} flex={1}>
                            <DetailWrapper
                                title={data.httpMethod + ' ' + data.path}
                                direction="column"
                                spacing={2}
                            >
                                <DetailCell right="ID" left={data.id} />
                                <DetailCell
                                    right="Status Code"
                                    left={
                                        <StatusCodeCell
                                            code={data.responseStatusCode}
                                            div
                                        />
                                    }
                                />
                                <DetailCell
                                    right="HTTP Method"
                                    left={data.httpMethod}
                                />

                                <DetailCell right="Path" left={data.path} />
                                <DetailCell
                                    right="Response Time (MS)"
                                    left={data.responseTimeMS}
                                />
                                <DetailCell
                                    right="Time"
                                    left={formatTableDate(data.createdAt)}
                                />
                            </DetailWrapper>
                            {showOnExtraLarge && <RawData obj={data} />}
                        </Box>
                        <PaperWrapper style={{ padding: 16 }}>
                            <Box
                                className={`${classes.child} ${classes.json}`}
                                flex={3}
                            >
                                <JSONDisplay
                                    title="Response Body"
                                    code={data.responseBody}
                                />
                                {data.httpMethod !== 'DELETE' && (
                                    <>
                                        <Divider style={{ marginTop: 16 }} />
                                        <JSONDisplay
                                            title="Request Body"
                                            code={data.requestBody}
                                        />
                                    </>
                                )}
                            </Box>
                        </PaperWrapper>
                    </Box>
                </Grid>
                {showOnLarge && (
                    <Grid item>
                        <RawData obj={data} />
                    </Grid>
                )}
            </GridPaper>
        </>
    );
};

export default ViewAPILog;
