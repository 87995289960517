import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TooltipWrapper from './TooltipWrapper';
import { TooltipProps } from '@material-ui/core';

type TooltipCheckboxProps = {
    checked?: boolean;
    setChecked: (val: boolean) => void;
    label: string;
    disabled?: boolean;
    tooltip: Omit<TooltipProps, 'children'>;
};

const TooltipCheckbox: React.FC<TooltipCheckboxProps> = ({
    setChecked,
    checked,
    disabled,
    label,
    tooltip,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
    };

    return (
        <TooltipWrapper disabled={disabled} {...tooltip}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                }
                label={label}
            />
        </TooltipWrapper>
    );
};

export default TooltipCheckbox;
