import React, {
    FormEvent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Service, Webhook, WebhookEventType } from '../services/Webhooks';
import { Context } from '../context/Notification';
import { useModal } from '../hooks/useModal';
import { removeFromSet, addToSet } from '../services/util';
import { WebhookRoutes } from '../routes';

import HttpsIcon from '@material-ui/icons/Https';
import DescriptionIcon from '@material-ui/icons/Description';
import TextField from '@material-ui/core/TextField';
import GridForm from './GridForm';
import PageHeader from './PageHeader';
import Grid from '@material-ui/core/Grid';
import Button from './Button';
import EditField from './EditField';
import WebhookEvents from './WebhookEvents';
import DetailWrapper from './DetailWrapper';
import DetailCell from './DetailCell';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import WebhookSecret from './WebhookSecret';

interface EditWebhookProps {
    setWebhook: (hook: Webhook) => void;
    webhook: Webhook;
    service: Service;
}

const EditWebhook: React.FC<EditWebhookProps> = ({
    service,
    setWebhook,
    webhook,
}) => {
    const [enabled, setEnabled] = useState(webhook.enabled);
    const [description, setDescription] = useState(webhook.description);
    const [url, setUrl] = useState(webhook.url);
    const [events, setEvents] = useState(new Set<WebhookEventType>());
    const [updating, setUpdating] = useState(false);
    const { isModalOpen, toggleModal } = useModal();
    const history = useHistory();
    const { dispatchError, dispatchSuccess } = useContext(Context);

    useEffect(() => {
        const eventSet = new Set<WebhookEventType>();
        webhook.enabledEvents.forEach(eventSet.add, eventSet);
        setEvents(eventSet);
        // Only want this to run initially to get all event types in state
        // if the webhook changes, we will already have the updated events
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        await update();
    };

    const toggleEnabled = async () => {
        setEnabled((prev) => !prev);
    };

    // Checks if the webhook details have been altered
    const hasUpdates = useMemo(() => {
        if (!webhook) return false;
        if (url !== webhook.url) return true;
        if (description !== webhook.description) return true;
        if (enabled !== webhook.enabled) return true;
        const eventSet = new Set<WebhookEventType>();
        webhook.enabledEvents.forEach(eventSet.add, eventSet);
        const areSetsSame =
            eventSet.size === events.size &&
            [...Array.from(eventSet)].every((value) => events.has(value));
        if (areSetsSame) return false;
        return true;
    }, [description, enabled, webhook, events, url]);

    const update = async () => {
        setUpdating(true);

        try {
            const eventTypes = Array.from(events);

            if (eventTypes.length <= 0) {
                dispatchError('You must select at least one event type.');
                return;
            }

            const updatedWebhook = await service.update(webhook.id, {
                enabled,
                url,
                description,
                enabledEvents: eventTypes,
            });

            if (updatedWebhook) {
                setWebhook(updatedWebhook);
                dispatchSuccess('Successfully saved webhook.');
            } else {
                dispatchError('There was an error while updating the webhook.');
            }
        } catch (err) {
            console.error('Webhook toggle enabled error:', err);
        } finally {
            setUpdating(false);
        }
    };

    const handleEvents = (event: WebhookEventType) => {
        if (events.has(event)) {
            removeFromSet(event, setEvents);
        } else {
            addToSet(event, setEvents);
        }
    };

    const handleDelete = async () => {
        await service.delete(webhook.id);

        dispatchSuccess('Deleted webhook.');

        history.push(WebhookRoutes.HOME);
    };

    return (
        <>
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={toggleModal}
                confirm={handleDelete}
                title="Delete Webhook"
                text="Are you sure you want to delete this webhook?"
            />
            <GridForm
                form={{
                    onSubmit: handleSubmit,
                }}
                grid={{
                    container: true,
                    direction: 'column',
                    spacing: 2,
                }}
            >
                <PageHeader
                    title="Webhook Details"
                    alertText="This is a test mode webhook, so it will only receive events for test orders."
                    liveWarning
                >
                    <Grid item container spacing={2}>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={toggleModal}
                            >
                                Delete Webhook
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color={enabled ? 'secondary' : 'primary'}
                                onClick={toggleEnabled}
                            >
                                {enabled ? 'Disable Webhook' : 'Enable Webhook'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={updating || !hasUpdates}
                            >
                                Save Webhook
                            </Button>
                        </Grid>
                    </Grid>
                </PageHeader>
                <Grid item container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <EditField
                                item
                                title="URL"
                                icon={HttpsIcon}
                                component={TextField}
                                fullWidth
                                variant="outlined"
                                placeholder="URL"
                                value={url}
                                onChange={(e) => {
                                    setUrl(e.target.value);
                                }}
                            />
                            <EditField
                                item
                                title="Description"
                                icon={DescriptionIcon}
                                component={TextField}
                                fullWidth
                                variant="outlined"
                                placeholder="Description"
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                            <Grid item>
                                <WebhookEvents
                                    onChange={handleEvents}
                                    events={events}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <DetailWrapper
                            direction="column"
                            spacing={2}
                            title="Additional Information"
                        >
                            <DetailCell
                                right="Enabled"
                                left={enabled ? 'Yes' : 'No'}
                            />
                            <DetailCell
                                right="Secret"
                                left={<WebhookSecret secret={webhook.secret} />}
                            />
                        </DetailWrapper>
                    </Grid>
                </Grid>
            </GridForm>
        </>
    );
};

export default EditWebhook;
