import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import { useModal } from '../hooks/useModal';
import { Order, Service } from '../services/Orders';
import {
    addToSet,
    createOrderStartDateQuery,
    removeFromSet,
    useDebouncedValue,
} from '../services/util';
import { Context } from './Notification';
import { useReFetchContext } from './ReFetchContext';

interface BulkCancelOrdersStateProps {
    search: string;
    searchText: string;
    count: number;
    fetching: boolean;
    orders: Order[];
    setFetching: (bool: boolean) => void;
    setCount: (num: number) => void;
    handleSearchText: (
        val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    setOrders: (orders: Order[]) => void;
    page: number;
    setPage: (num: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (num: number) => void;
    preserveOrder: Set<string>;
    handlePreserveOrder: (id: string) => void;
    cancelling: boolean;
    cancellingProgress: number;
    cancelOrders: () => void;
    cancelledCount: number;
    didCancel: boolean;
    resetState: () => void;
    object: string;
    cancellable: boolean;
    openModal: () => void;
    handleDateChange: (startDate?: Date, endDate?: Date) => void;
    dateQuery: string;
    expandAccordion: boolean;
    handleExpandAccordion: () => void;
}

export const BulkCancelOrdersContext =
    createContext<BulkCancelOrdersStateProps>({} as BulkCancelOrdersStateProps);

export const useBulkCancelOrdersContext = () => {
    return useContext(BulkCancelOrdersContext);
};

type BulkCancelOrdersProviderProps<T extends Order> = PropsWithChildren<{
    service: Service<T>;
    object: string;
}>;

export const baseSearch = (search: string = '') => {
    if (search.trim() === '') {
        return { status: 'ready' };
    }
    return { status: 'ready', $text: { $search: `"${search.trim() ?? ''}"` } };
};

const structureSearchQuery = (s: string) => {
    const search = s.trim();
    if (search.startsWith('{') && search.endsWith('}')) {
        try {
            const parsed = JSON.parse(search);
            if (parsed.status) {
                // don't allow them to try to cancel ones that are printing etc
                if (parsed.status !== 'ready') {
                    delete parsed.status;
                    return { status: 'ready', ...parsed };
                }
                return parsed;
            }
            return { status: 'ready', ...parsed };
        } catch (err) {
            return baseSearch(search);
        }
    }
    return baseSearch(search);
};

const fullQuery = (search: string, date: string) => {
    const dateObj = date === '' ? undefined : JSON.parse(date);
    const searchObj = structureSearchQuery(search);
    return JSON.stringify({
        ...searchObj,
        ...dateObj,
    });
};

export const BulkCancelOrdersProvider = <T extends Order>({
    service,
    object,
    children,
}: BulkCancelOrdersProviderProps<T>) => {
    const { dispatchSuccess } = useContext(Context);
    const { isModalOpen, openModal, closeModal } = useModal();
    const [expandAccordion, setExpandAccordion] = useState(false);
    const { toggleReFetch } = useReFetchContext();

    const [dateQuery, setDateQuery] = useState('');
    const [searchText, setSearchText] = useState('');
    const [fetching, setFetching] = useState(false);
    const [count, setCount] = useState(0);
    const [orders, setOrders] = useState<Order[]>([]);
    // cancel states
    const [cancelledCount, setCancelledCount] = useState(0);
    const [cancelling, setCancelling] = useState(false);
    const [didCancel, setDidCancel] = useState(false);
    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // a set to store ids of order's
    // allows users to keep certain orders from cancelling
    const [preserveOrder, setPreserveOrder] = useState(new Set<string>());
    // only get orders that have ready status
    const search = useDebouncedValue(fullQuery(searchText, dateQuery), 200);

    const resetPagination = () => {
        setPage(0);
        setRowsPerPage(10);
        setOrders([]);
        setCount(0);
    };

    const resetState = useCallback(() => {
        setSearchText('');
        setFetching(false);
        resetPagination();
        setCancelledCount(0);
        setCancelling(false);
        setDateQuery('');
        setExpandAccordion(false);
        setDidCancel(false);
        setPreserveOrder(new Set<string>());
    }, []);

    useEffect(() => {
        let cancel = false;
        (async () => {
            try {
                setFetching(true);
                const { data, totalCount } = await service.list({
                    limit: rowsPerPage,
                    skip: page * rowsPerPage,
                    search,
                });

                if (cancel) {
                    return;
                }
                setCount(totalCount);
                setOrders(data);
            } catch (err) {
                console.error(err);
            } finally {
                if (cancel) {
                    return;
                }
                setFetching(false);
            }
        })();
        return () => {
            cancel = true;
        };
    }, [search, service, rowsPerPage, page]);

    const cancelOrders = useCallback(async () => {
        const trueCount = count - preserveOrder.size;
        if (trueCount <= 0) {
            return;
        }
        try {
            setCancelling(true);
            setExpandAccordion(false);
            let cancelled = 0;
            while (true) {
                await new Promise((r) => setTimeout(r, 300));
                const { data } = await service.list({
                    search,
                    // The orders are being deleted so
                    // we don't need to skip any
                    skip: 0,
                    // Max limit per API docs
                    limit: 100,
                });
                // account for those that are selected to not be cancelled
                if (cancelled >= trueCount) {
                    break;
                }

                for (const order of data) {
                    if (preserveOrder.has(order.id)) {
                        continue;
                    }
                    await service.cancel(order.id);

                    ++cancelled;
                    // try to limit the amount of re-renders a lil
                    if (cancelled % 10 === 0) {
                        setCancelledCount(cancelled);
                    }
                }
            }
            setCancelledCount(cancelled);
            dispatchSuccess(
                `Successfully cancelled ${cancelled} of ${count} ${object}s`
            );
            setDidCancel(true);
            toggleReFetch();
        } catch (err) {
            console.error(err);
        } finally {
            setCancelling(false);
        }
    }, [
        search,
        service,
        count,
        preserveOrder,
        dispatchSuccess,
        object,
        toggleReFetch,
    ]);

    const prepQueryChange = () => {
        // allow them to cancel different orders by typing something new
        if (didCancel) {
            resetState();
        } else {
            if (preserveOrder.size > 0) {
                // clear out ids
                setPreserveOrder(new Set());
            }
            if (page > 0) {
                setPage(0);
            }
            if (expandAccordion) {
                setExpandAccordion(false);
            }
        }
    };

    const handleSearchText = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        if (cancelling) {
            return;
        }
        prepQueryChange();
        setSearchText(e.target.value);
    };

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (cancelling) {
            return;
        }
        prepQueryChange();
        if (!startDate || !endDate) {
            setDateQuery('');
        } else {
            const query = createOrderStartDateQuery(startDate, endDate);
            setDateQuery(query);
        }
    };

    const handlePreserveOrder = (id: string) => {
        // Remove from the set if it has it since
        // this set is about orders that we do NOT
        // want to cancel
        if (preserveOrder.has(id)) {
            removeFromSet(id, setPreserveOrder);
        } else {
            addToSet(id, setPreserveOrder);
        }
    };

    const handleExpandAccordion = () => {
        if (fetching) {
            return;
        }
        setExpandAccordion((prev) => !prev);
    };

    return (
        <BulkCancelOrdersContext.Provider
            value={{
                searchText,
                search,
                handleSearchText,
                fetching,
                setFetching,
                count,
                setCount,
                orders,
                setOrders,
                setPage,
                page,
                rowsPerPage,
                setRowsPerPage,
                preserveOrder,
                handlePreserveOrder,
                cancelling,
                cancellingProgress:
                    (cancelledCount / (count - preserveOrder.size)) * 100,
                cancelOrders,
                cancelledCount,
                didCancel,
                resetState,
                openModal,
                object,
                cancellable: !(count - preserveOrder.size > 0),
                handleDateChange,
                dateQuery,
                handleExpandAccordion,
                expandAccordion,
            }}
        >
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={closeModal}
                confirm={cancelOrders}
                text={`Are you sure you want to cancel ${
                    count - preserveOrder.size
                } ${object}${count - preserveOrder.size === 1 ? '' : 's'}?`}
                title={`Cancel ${object}s`}
                actionLabel="Confirm"
            />
            {children}
        </BulkCancelOrdersContext.Provider>
    );
};
