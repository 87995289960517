import { useContext, useMemo, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
    Context as AuthContext,
    State as AuthState,
    ActionType,
} from '../context/Auth';
import { Context as ModeContext } from '../context/Mode';
import {
    MessageType,
    Action as NotificationAction,
    Context as NotificationContext,
} from '../context/Notification';

import {
    APIRequestError,
    APIErrorType,
    APIRequestInit,
    fetchAPI,
} from './util';

export enum OrderExtraService {
    CERTIFIED = 'certified',
    CERTIFIED_RETURN_RECEIPT = 'certified_return_receipt',
    REGISTERED = 'registered',
}

export enum OrderMailingClass {
    FIRST_CLASS = 'first_class',
    STANDARD_CLASS = 'standard_class',
}

export interface Resource {
    object:
        | 'letter'
        | 'postcard'
        | 'cheque'
        | 'bank_account'
        | 'template'
        | 'return_envelope'
        | 'return_envelope_order'
        | 'contact'
        | 'api_log'
        | 'webhook'
        | 'webhook_invocation';

    id: string;
    description?: string;
    live: boolean;
    metadata?: Record<string, any>;

    createdAt: Date;
    updatedAt: Date;
}

export type ResourceCreateParams = Omit<
    Resource,
    'id' | 'live' | 'createdAt' | 'updatedAt' | 'object'
>;

export class Service {
    history: ReturnType<typeof useHistory>;
    notify: (action: NotificationAction) => void;
    live: boolean;
    logout: () => void;
    authState?: AuthState;

    constructor(
        history: Service['history'],
        notify: Service['notify'],
        live: boolean,
        logout: () => void,
        authState?: AuthState
    ) {
        this.history = history;
        this.notify = notify;
        this.live = live;
        this.logout = logout;
        this.authState = authState;
    }

    // TODO Make generic 'fetch' wrapper here which handles errors as well
    async internalFetchAPI(
        path: string,
        init?: APIRequestInit & {
            forceLive?: boolean;
            allow404?: boolean;
            silent?: boolean;
        }
    ): Promise<any> {
        if (this.authState && this.authState.tokens) {
            init = {
                ...init,
                headers: {
                    ...init?.headers,
                    Authorization: `Bearer ${
                        this.live || init?.forceLive
                            ? this.authState.tokens.live
                            : this.authState.tokens.test
                    }`,
                },
            };
        }

        try {
            return await fetchAPI(path, init);
        } catch (err: any) {
            if (err instanceof APIRequestError) {
                if (
                    err.status === 401 ||
                    err.type === APIErrorType.TOKEN_EXPIRED
                ) {
                    this.logout();
                    this.history.push('/login');
                }

                if (init && init.allow404 && err.status === 404) {
                    return null;
                }

                if (err.status === 503 && err.type === 'maintenance_error') {
                    this.history.push(`/maintenance?msg=${err.message}`);
                }
            }

            if (!init?.silent) {
                this.notify({
                    type: MessageType.ERROR,
                    message: err.message,
                });
            }

            throw err;
        }
    }

    async fetchAPI<T>(
        path: string,
        init?: APIRequestInit & { silent?: boolean }
    ): Promise<T> {
        return this.internalFetchAPI(path, init);
    }

    async fetchAPIAllow404<T>(
        path: string,
        init?: APIRequestInit
    ): Promise<T | null> {
        return this.internalFetchAPI(path, {
            ...init,
            allow404: true,
        });
    }
}

export const useService = () => {
    const history = useHistory();

    const { dispatch } = useContext(NotificationContext);
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const { live } = useContext(ModeContext);

    const logout = useCallback(() => {
        authDispatch({
            type: ActionType.LOGOUT,
        });
    }, [authDispatch]);

    return useMemo(
        () => new Service(history, dispatch, live, logout, state),
        [dispatch, history, live, state, logout]
    );
};
