import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { OrderStatus as Status } from '../services/Orders';

interface OrderStatusCellProps {
    status: Status;
}

const useStyles = makeStyles<Theme, OrderStatusCellProps>((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    bubble: {
        backgroundColor: ({ status }) => getCellBackgroundColor(status),
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        color: ({ status }) => getCellColor(theme, status),
        borderRadius: 5,
        textTransform: 'capitalize',
    },
}));

const getCellColor = (theme: Theme, status: Status) => {
    switch (status) {
        case Status.READY:
            return theme.palette.primary.main;
        case Status.PROCESSED_FOR_DELIVERY:
            return theme.palette.warning.main;
        case Status.COMPLETED:
            return theme.palette.success.main;
        case Status.PRINTING:
            return '#969696';
        case Status.CANCELLED:
            return theme.palette.error.main;
        default:
            return theme.palette.text.primary;
    }
};

const getCellBackgroundColor = (status: Status) => {
    switch (status) {
        case Status.READY:
            return '#d6e4ff';
        case Status.PROCESSED_FOR_DELIVERY:
            return '#ffecd1';
        case Status.COMPLETED:
            return '#e3ffe4';
        case Status.PRINTING:
            return '#f2f2f2';
        case Status.CANCELLED:
            return '#ffdedb';
        default:
            return 'transparent';
    }
};

export const OrderStatus: React.FC<OrderStatusCellProps> = ({ status }) => {
    const classes = useStyles({ status });
    return <span className={classes.bubble}>{status.replace(/_/g, ' ')}</span>;
};

const OrderStatusCell: React.FC<OrderStatusCellProps> = ({ status }) => {
    const classes = useStyles({ status });
    return (
        <TableCell className={classes.root}>
            <OrderStatus status={status} />
        </TableCell>
    );
};

export default OrderStatusCell;
